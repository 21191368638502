import React from 'react';
import Moment from 'moment';

const DuplicatePaymentWarning = ({ payment, onCancel, onConfirm }) => {
  // Format the timestamp to a readable date/time
  const formattedTime = payment?.timestamp ? 
    Moment(payment.timestamp).format('DD/MM/YYYY HH:mm:ss') : 
    '';
  
  // Format the amount as currency
  const formattedAmount = payment?.monto ? 
    new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD"
    }).format(payment.monto) : 
    '$0.00';

  return (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full z-50">
      <div className="relative top-20 mx-auto p-5 border w-96 shadow-lg rounded-md bg-white">
        <div className="mt-3">
          <div className="flex items-center justify-center flex-col">
            <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-yellow-100">
              <svg className="h-6 w-6 text-yellow-600" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path fillRule="evenodd" d="M8.485 2.495c.673-1.167 2.357-1.167 3.03 0l6.28 10.875c.673 1.167-.17 2.625-1.516 2.625H3.72c-1.347 0-2.189-1.458-1.515-2.625L8.485 2.495zM10 5a.75.75 0 01.75.75v3.5a.75.75 0 01-1.5 0v-3.5A.75.75 0 0110 5zm0 9a1 1 0 100-2 1 1 0 000 2z" clipRule="evenodd" />
              </svg>
            </div>
            <h3 className="text-lg leading-6 font-medium text-gray-900 mt-4">
              Posible Pago Duplicado
            </h3>
            <div className="mt-4 px-7 py-3 w-full">
              <div className="mb-4">
                <p className="text-sm text-gray-500 mb-4">
                  Ya se ha realizado un pago similar recientemente. Detalles del pago anterior:
                </p>
                <div className="bg-yellow-50 p-3 rounded-md border border-yellow-100">
                  <p className="text-sm text-gray-700"><strong>Fecha:</strong> {formattedTime}</p>
                  <p className="text-sm text-gray-700"><strong>Crédito:</strong> {payment?.creditoID}</p>
                  <p className="text-sm text-gray-700"><strong>Monto:</strong> {formattedAmount}</p>
                  <p className="text-sm text-gray-700"><strong>Tipo:</strong> {payment?.tipoPago}</p>
                </div>
              </div>
              <p className="text-sm text-gray-500 mt-4">
                ¿Está seguro que desea proceder con este pago?
              </p>
            </div>
            <div className="flex justify-center gap-4 mt-4">
              <button
                onClick={onCancel}
                className="px-4 py-2 bg-gray-300 text-gray-700 text-base font-medium rounded-md shadow-sm hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-gray-500"
              >
                Cancelar
              </button>
              <button
                onClick={onConfirm}
                className="px-4 py-2 bg-yellow-600 text-white text-base font-medium rounded-md shadow-sm hover:bg-yellow-700 focus:outline-none focus:ring-2 focus:ring-yellow-500"
              >
                Duplicar Pago
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DuplicatePaymentWarning; 