import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Bars } from 'react-loader-spinner';
import { formatDate, formatCurrency } from '../Helpers/Helpers';

const EdoCuentaTable = () => {
  const [amortizacionId, setAmortizacionId] = useState('');
  const [creditoId, setCreditoId] = useState('');
  const [fechaInicioMin, setFechaInicioMin] = useState('');
  const [fechaInicioMax, setFechaInicioMax] = useState('');
  const [fechaVencimientoMin, setFechaVencimientoMin] = useState('');
  const [fechaVencimientoMax, setFechaVencimientoMax] = useState('');
  const [fechaExigibleMin, setFechaExigibleMin] = useState('');
  const [fechaExigibleMax, setFechaExigibleMax] = useState('');
  const [estatus, setEstatus] = useState('');
  
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const [totalItems, setTotalItems] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [filterText, setFilterText] = useState('');

  // Load data when component mounts
  useEffect(() => {
    // Only load data when the page changes, not on initial mount
    if (currentPage > 0) {
      // Map display status to database status using the same logic as handleSearch
      let dbEstatus = estatus;
      if (estatus === 'PAGADO') {
        dbEstatus = 'P';
      } else if (estatus === 'VIGENTE') {
        dbEstatus = 'V';
      } else if (estatus === 'VENCIDO') {
        dbEstatus = 'B';
      } else if (estatus === 'CASTIGADO') {
        dbEstatus = 'K';
      } else if (estatus === 'ATRASADO') {
        dbEstatus = 'A';
      }
      
      // Use the mapped status for the API call
      loadData(dbEstatus);
    }
  }, [currentPage, amortizacionId, creditoId, fechaInicioMin, fechaInicioMax, 
      fechaVencimientoMin, fechaVencimientoMax, fechaExigibleMin, fechaExigibleMax, estatus]);

  const handleAmortizacionIdChange = (e) => {
    setAmortizacionId(e.target.value);
  };

  const handleCreditoIdChange = (e) => {
    setCreditoId(e.target.value);
  };

  const handleFechaInicioMinChange = (e) => {
    setFechaInicioMin(e.target.value);
  };

  const handleFechaInicioMaxChange = (e) => {
    setFechaInicioMax(e.target.value);
  };

  const handleFechaVencimientoMinChange = (e) => {
    setFechaVencimientoMin(e.target.value);
  };

  const handleFechaVencimientoMaxChange = (e) => {
    setFechaVencimientoMax(e.target.value);
  };

  const handleFechaExigibleMinChange = (e) => {
    setFechaExigibleMin(e.target.value);
  };

  const handleFechaExigibleMaxChange = (e) => {
    setFechaExigibleMax(e.target.value);
  };

  const handleEstatusChange = (e) => {
    setEstatus(e.target.value);
  };

  const handleReset = () => {
    // Reset all filter state variables
    setAmortizacionId('');
    setCreditoId('');
    setFechaInicioMin('');
    setFechaInicioMax('');
    setFechaVencimientoMin('');
    setFechaVencimientoMax('');
    setFechaExigibleMin('');
    setFechaExigibleMax('');
    setEstatus('');
    setFilterText('');
    setCurrentPage(1);
    
    // Call loadData with null to ensure we're not sending any filter parameters
    loadData(null);
  };

  const handleSearch = () => {
    setCurrentPage(1);
    
    // Map display status to database status
    let dbEstatus = estatus;
    if (estatus === 'PAGADO') {
      dbEstatus = 'P';
    } else if (estatus === 'VIGENTE') {
      dbEstatus = 'V';
    } else if (estatus === 'VENCIDO') {
      dbEstatus = 'B';
    } else if (estatus === 'CASTIGADO') {
      dbEstatus = 'K';
    } else if (estatus === 'ATRASADO') {
      dbEstatus = 'A';
    }
    
    // Use the mapped status for the API call
    loadData(dbEstatus);
  };

  const loadData = async (mappedEstatus = null) => {
    setLoading(true);
    setError(null);
    
    try {
      const response = await axios.get(`/api/v1/edocuenta/get_all_transactions.json`, {
        params: {
          amortizacion_id: amortizacionId || null,
          credito_id: creditoId || null,
          fecha_inicio_min: fechaInicioMin || null,
          fecha_inicio_max: fechaInicioMax || null,
          fecha_vencim_min: fechaVencimientoMin || null,
          fecha_vencim_max: fechaVencimientoMax || null,
          fecha_exigible_min: fechaExigibleMin || null,
          fecha_exigible_max: fechaExigibleMax || null,
          estatus: mappedEstatus || estatus || null,
          page: currentPage,
          per_page: itemsPerPage
        }
      });
      
      // Map database status back to display status for each item
      const mappedItems = (response.data.items || []).map(item => {
        // Create a copy of the item
        const mappedItem = {...item};
        
        // Map database status to display status
        if (item.Estatus === 'P') {
          mappedItem.Estatus = 'PAGADO';
        } else if (item.Estatus === 'V') {
          mappedItem.Estatus = 'VIGENTE';
        } else if (item.Estatus === 'B') {
          mappedItem.Estatus = 'VENCIDO';
        } else if (item.Estatus === 'K') {
          mappedItem.Estatus = 'CASTIGADO';
        } else if (item.Estatus === 'A') {
          mappedItem.Estatus = 'ATRASADO';
        }
        
        return mappedItem;
      });
      
      setData(mappedItems);
      setTotalItems(response.data.total_count || 0);
      setTotalPages(response.data.total_pages || 0);
      setLoading(false);
    } catch (error) {
      console.error('Error loading data:', error);
      setError(`Error: ${error.message || 'Failed to load data'}`);
      setLoading(false);
      
      // Set empty data to prevent UI issues
      setData([]);
      setTotalItems(0);
      setTotalPages(0);
    }
  };

  // Filter data based on search text
  const filteredData = data.filter(item => {
    if (!filterText) return true;
    
    const searchText = filterText.toLowerCase();
    return (
      (item.AmortizacionID && item.AmortizacionID.toString().toLowerCase().includes(searchText)) ||
      (item.CreditoID && item.CreditoID.toString().toLowerCase().includes(searchText)) ||
      (item.ClienteID && item.ClienteID.toString().toLowerCase().includes(searchText)) ||
      (item.FechaInicio && item.FechaInicio.toLowerCase().includes(searchText)) ||
      (item.FechaVencim && item.FechaVencim.toLowerCase().includes(searchText)) ||
      (item.FechaExigible && item.FechaExigible.toLowerCase().includes(searchText)) ||
      (item.Estatus && item.Estatus.toLowerCase().includes(searchText))
    );
  });

  // Add these functions to handle exports
  const handleExportExcel = () => {
    const queryParams = {
      format: 'xlsx',
      amortizacion_id: amortizacionId || null,
      credito_id: creditoId || null,
      fecha_inicio_min: fechaInicioMin || null,
      fecha_inicio_max: fechaInicioMax || null,
      fecha_vencim_min: fechaVencimientoMin || null,
      fecha_vencim_max: fechaVencimientoMax || null,
      fecha_exigible_min: fechaExigibleMin || null,
      fecha_exigible_max: fechaExigibleMax || null,
      estatus: estatus || null
    };
    
    // Map display status to database status for export
    if (queryParams.estatus === 'PAGADO') {
      queryParams.estatus = 'P';
    } else if (queryParams.estatus === 'VIGENTE') {
      queryParams.estatus = 'V';
    } else if (queryParams.estatus === 'VENCIDO') {
      queryParams.estatus = 'B';
    } else if (queryParams.estatus === 'CASTIGADO') {
      queryParams.estatus = 'K';
    } else if (queryParams.estatus === 'ATRASADO') {
      queryParams.estatus = 'A';
    }
    
    // Create the URL with query parameters
    const queryString = new URLSearchParams(queryParams).toString();
    const exportUrl = `/api/v1/edocuenta/export?${queryString}`;
    
    // Redirect to the export URL
    window.location.href = exportUrl;
  };

  const handleExportCSV = () => {
    const queryParams = {
      format: 'csv',
      amortizacion_id: amortizacionId || null,
      credito_id: creditoId || null,
      fecha_inicio_min: fechaInicioMin || null,
      fecha_inicio_max: fechaInicioMax || null,
      fecha_vencim_min: fechaVencimientoMin || null,
      fecha_vencim_max: fechaVencimientoMax || null,
      fecha_exigible_min: fechaExigibleMin || null,
      fecha_exigible_max: fechaExigibleMax || null,
      estatus: estatus || null
    };
    
    // Map display status to database status for export
    if (queryParams.estatus === 'PAGADO') {
      queryParams.estatus = 'P';
    } else if (queryParams.estatus === 'VIGENTE') {
      queryParams.estatus = 'V';
    } else if (queryParams.estatus === 'VENCIDO') {
      queryParams.estatus = 'B';
    } else if (queryParams.estatus === 'CASTIGADO') {
      queryParams.estatus = 'K';
    } else if (queryParams.estatus === 'ATRASADO') {
      queryParams.estatus = 'A';
    }
    
    // Create the URL with query parameters
    const queryString = new URLSearchParams(queryParams).toString();
    const exportUrl = `/api/v1/edocuenta/export?${queryString}`;
    
    // Redirect to the export URL
    window.location.href = exportUrl;
  };

  return (
    <div>
      {/* Filter Section */}
      <div className="bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6 mb-5">
        <div className="md:grid md:grid-cols-6 md:gap-6">
          <div className="md:col-span-6">
            <h3 className="text-lg font-medium leading-6 text-gray-900">Filtros</h3>
            <p className="mt-1 text-sm text-gray-500">Utilice los filtros para buscar amortizaciones específicas.</p>
          </div>
          
          <div className="mt-5 md:mt-0 md:col-span-6">
            <div className="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
              {/* AmortizacionID Filter */}
              <div className="sm:col-span-2">
                <label htmlFor="amortizacion-id" className="block text-sm font-medium text-gray-700">
                  Amortización ID
                </label>
                <div className="mt-1">
                  <input
                    type="text"
                    id="amortizacion-id"
                    value={amortizacionId}
                    onChange={handleAmortizacionIdChange}
                    className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                  />
                </div>
              </div>

              {/* CreditoID Filter */}
              <div className="sm:col-span-2">
                <label htmlFor="credito-id" className="block text-sm font-medium text-gray-700">
                  Crédito ID
                </label>
                <div className="mt-1">
                  <input
                    type="text"
                    id="credito-id"
                    value={creditoId}
                    onChange={handleCreditoIdChange}
                    className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                  />
                </div>
              </div>

              {/* Estatus Filter */}
              <div className="sm:col-span-2">
                <label htmlFor="estatus" className="block text-sm font-medium text-gray-700">
                  Estatus
                </label>
                <div className="mt-1">
                  <select
                    id="estatus"
                    value={estatus}
                    onChange={handleEstatusChange}
                    className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                  >
                    <option value="">Todos</option>
                    <option value="PAGADO">PAGADO</option>
                    <option value="VIGENTE">VIGENTE</option>
                    <option value="VENCIDO">VENCIDO</option>
                    <option value="ATRASADO">ATRASADO</option>
                    <option value="CASTIGADO">CASTIGADO</option>
                  </select>
                </div>
              </div>

              {/* FechaInicio Range Filter */}
              <div className="sm:col-span-2">
                <label className="block text-sm font-medium text-gray-700">
                  Fecha Inicio
                </label>
                <div className="grid grid-cols-2 gap-2">
                  <div>
                    <input
                      type="date"
                      value={fechaInicioMin}
                      onChange={handleFechaInicioMinChange}
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                    />
                  </div>
                  <div>
                    <input
                      type="date"
                      value={fechaInicioMax}
                      onChange={handleFechaInicioMaxChange}
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                    />
                  </div>
                </div>
              </div>

              {/* FechaVencimiento Range Filter */}
              <div className="sm:col-span-2">
                <label className="block text-sm font-medium text-gray-700">
                  Fecha Vencimiento
                </label>
                <div className="grid grid-cols-2 gap-2">
                  <div>
                    <input
                      type="date"
                      value={fechaVencimientoMin}
                      onChange={handleFechaVencimientoMinChange}
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                    />
                  </div>
                  <div>
                    <input
                      type="date"
                      value={fechaVencimientoMax}
                      onChange={handleFechaVencimientoMaxChange}
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                    />
                  </div>
                </div>
              </div>

              {/* FechaExigible Range Filter */}
              <div className="sm:col-span-2">
                <label className="block text-sm font-medium text-gray-700">
                  Fecha Exigible
                </label>
                <div className="grid grid-cols-2 gap-2">
                  <div>
                    <input
                      type="date"
                      value={fechaExigibleMin}
                      onChange={handleFechaExigibleMinChange}
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                    />
                  </div>
                  <div>
                    <input
                      type="date"
                      value={fechaExigibleMax}
                      onChange={handleFechaExigibleMaxChange}
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                    />
                  </div>
                </div>
              </div>

              {/* Action Buttons */}
              <div className="sm:col-span-6 flex justify-end space-x-3">
                <button
                  type="button"
                  onClick={handleReset}
                  className="inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  Limpiar
                </button>
                <button
                  type="button"
                  onClick={handleSearch}
                  className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  Buscar
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Table Section */}
      <div className="bg-white shadow overflow-hidden sm:rounded-lg">
        <div className="px-4 py-5 sm:px-6 flex justify-between items-center">
          <div>
            <h3 className="text-lg leading-6 font-medium text-gray-900">Amortizaciones</h3>
            <p className="mt-1 max-w-2xl text-sm text-gray-500">Listado de amortizaciones</p>
          </div>
          <div className="w-64">
            <input
              type="text"
              className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
              placeholder="Buscar..."
              value={filterText}
              onChange={(e) => setFilterText(e.target.value)}
            />
          </div>
        </div>
        
        {loading ? (
          <div className="flex justify-center items-center p-12">
            <Bars
              height="80"
              width="80"
              color="#4f46e5"
              ariaLabel="bars-loading"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
            />
          </div>
        ) : error ? (
          <div className="px-4 py-5 sm:p-6 text-center text-red-500">
            {error}
          </div>
        ) : (
          <>
            <div className="overflow-x-auto" style={{ maxWidth: '100%' }}>
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider sticky left-0 bg-gray-50">
                      Amortización ID
                    </th>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Crédito ID
                    </th>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Cliente ID
                    </th>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Fecha Inicio
                    </th>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Fecha Vencimiento
                    </th>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Fecha Exigible
                    </th>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Estatus
                    </th>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Fecha Liquida
                    </th>
                    <th scope="col" className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Capital
                    </th>
                    <th scope="col" className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Interés
                    </th>
                    <th scope="col" className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                      IVA Interés
                    </th>
                    <th scope="col" className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Monto FECI
                    </th>
                    <th scope="col" className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Saldo Cap. Vigente
                    </th>
                    <th scope="col" className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Saldo Cap. Atrasa
                    </th>
                    <th scope="col" className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Saldo Cap. Vencido
                    </th>
                    <th scope="col" className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Saldo Cap. Ven. No Exi.
                    </th>
                    <th scope="col" className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Saldo Interés Ord.
                    </th>
                    <th scope="col" className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Saldo Interés Atr.
                    </th>
                    <th scope="col" className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Saldo Interés Venc.
                    </th>
                    <th scope="col" className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Saldo Interés Prov.
                    </th>
                    <th scope="col" className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Saldo Interés No Cont.
                    </th>
                    <th scope="col" className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Saldo IVA Interés
                    </th>
                    <th scope="col" className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Saldo Moratorios
                    </th>
                    <th scope="col" className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Saldo IVA Comisión Falta Pago
                    </th>
                    <th scope="col" className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Saldo Otras Comisiones
                    </th>
                    <th scope="col" className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Saldo IVA Comisiones
                    </th>
                    <th scope="col" className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Provisiones Acumuladas
                    </th>
                    <th scope="col" className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Saldo Capital
                    </th>
                    <th scope="col" className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Num. Proy. Intereses
                    </th>
                    <th scope="col" className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Saldo Mora Vencido
                    </th>
                    <th scope="col" className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Saldo Mora Car. Ven.
                    </th>
                    <th scope="col" className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Monto Seguro Cuota
                    </th>
                    <th scope="col" className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                      IVA Seguro Cuota
                    </th>
                    <th scope="col" className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Saldo Seguro Cuota
                    </th>
                    <th scope="col" className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Saldo IVA Seguro Cuota
                    </th>
                    <th scope="col" className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Saldo Comisión Anual
                    </th>
                    <th scope="col" className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Saldo Comisión Anual IVA
                    </th>
                    <th scope="col" className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Saldo FECI
                    </th>
                    <th scope="col" className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                      FECI Acumulado
                    </th>
                    <th scope="col" className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                      FECI Adeudado
                    </th>
                    <th scope="col" className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                      FECI Adeudado R
                    </th>
                    <th scope="col" className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Genero Mora
                    </th>
                    <th scope="col" className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Interes Adeudado
                    </th>
                    <th scope="col" className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Saldo Interes Adeudado
                    </th>
           
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {filteredData.length > 0 ? (
                    filteredData.map((item, index) => (
                      <tr key={index} className={index % 2 === 0 ? 'bg-white' : 'bg-gray-50'}>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 sticky left-0 bg-inherit">
                          {item.AmortizacionID}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          {item.CreditoID}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          {item.ClienteID}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          {formatDate(item.FechaInicio)}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          {formatDate(item.FechaVencim)}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          {formatDate(item.FechaExigible)}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm">
                          <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${
                            item.Estatus === 'PAGADO' ? 'bg-green-100 text-green-800' : 
                            item.Estatus === 'VIGENTE' ? 'bg-blue-100 text-blue-800' : 
                            item.Estatus === 'VENCIDO' ? 'bg-red-100 text-red-800' : 
                            item.Estatus === 'ATRASADO' ? 'bg-yellow-100 text-yellow-800' : 
                            item.Estatus === 'CASTIGADO' ? 'bg-gray-100 text-gray-800' : 
                            'bg-gray-100 text-gray-800'
                          }`}>
                            {item.Estatus}
                          </span>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          {formatDate(item.FechaLiquida)}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-right">
                          {formatCurrency(item.Capital)}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-right">
                          {formatCurrency(item.Interes)}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-right">
                          {formatCurrency(item.IVAInteres)}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-right">
                          {formatCurrency(item.MontoFECI)}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-right">
                          {formatCurrency(item.SaldoCapVigente)}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-right">
                          {formatCurrency(item.SaldoCapAtrasa)}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-right">
                          {formatCurrency(item.SaldoCapVencido)}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-right">
                          {formatCurrency(item.SaldoCapVenNExi)}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-right">
                          {formatCurrency(item.SaldoInteresOrd)}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-right">
                          {formatCurrency(item.SaldoInteresAtr)}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-right">
                          {formatCurrency(item.SaldoInteresVen)}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-right">
                          {formatCurrency(item.SaldoInteresPro)}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-right">
                          {formatCurrency(item.SaldoIntNoConta)}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-right">
                          {formatCurrency(item.SaldoIVAInteres)}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-right">
                          {formatCurrency(item.SaldoMoratorios)}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-right">
                          {formatCurrency(item.SaldoIVAMorato)}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-right">
                          {formatCurrency(item.SaldoComFaltaPa)}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-right">
                          {formatCurrency(item.SaldoIVAComisi)}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-right">
                          {formatCurrency(item.ProvisionAcum)}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-right">
                          {formatCurrency(item.SaldoCapital)}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-right">
                          {formatCurrency(item.NumProyInteres)}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-right">
                          {formatCurrency(item.SaldoMoraVencido)}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-right">
                          {formatCurrency(item.SaldoMoraCarVen)}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-right">
                          {formatCurrency(item.MontoSeguroCuota)}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-right">
                          {formatCurrency(item.IVASeguroCuota)}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-right">
                          {formatCurrency(item.SaldoSeguroCuota)}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-right">
                          {formatCurrency(item.SaldoIVASeguroCuota)}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-right">
                          {formatCurrency(item.SaldoComisionAnual)}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-right">
                          {formatCurrency(item.SaldoComisionAnualIVA)}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-right">
                          {formatCurrency(item.SaldoFECI)}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-right">
                          {formatCurrency(item.FECIAcumulado)}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-right">
                          {formatCurrency(item.FECIAdeudado)}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-right">
                          {formatCurrency(item.FECIAdeudadoR)}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-right">
                          {item.GeneroMora}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-right">
                          {formatCurrency(item.InteresAdeudado)}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-right">
                          {formatCurrency(item.SaldoInteresAdeudado)}
                        </td>

   
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="18" className="px-6 py-4 text-center text-sm text-gray-500">
                        No hay datos disponibles
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            
            {filteredData.length > 0 && (
              <div className="bg-gray-100 px-4 py-3 flex items-center justify-end">
                <span className="text-sm text-gray-700">Exportar: </span>
                <button
                  onClick={handleExportCSV}
                  className="ml-2 inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  CSV
                </button>
                <button
                  onClick={handleExportExcel}
                  className="ml-2 inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  Excel
                </button>
              </div>
            )}
            
            {totalPages > 1 && (
              <div className="px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6">
                <div className="flex-1 flex justify-between sm:hidden">
                  <button
                    onClick={() => setCurrentPage(page => Math.max(page - 1, 1))}
                    disabled={currentPage === 1}
                    className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                  >
                    Anterior
                  </button>
                  <button
                    onClick={() => setCurrentPage(page => Math.min(page + 1, totalPages))}
                    disabled={currentPage === totalPages}
                    className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                  >
                    Siguiente
                  </button>
                </div>
                <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
                  <div>
                    <p className="text-sm text-gray-700">
                      Mostrando <span className="font-medium">{(currentPage - 1) * itemsPerPage + 1}</span> a{' '}
                      <span className="font-medium">
                        {Math.min(currentPage * itemsPerPage, totalItems)}
                      </span>{' '}
                      de <span className="font-medium">{totalItems}</span> resultados
                    </p>
                  </div>
                  <div>
                    <nav className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px" aria-label="Pagination">
                      <button
                        onClick={() => setCurrentPage(1)}
                        disabled={currentPage === 1}
                        className="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                      >
                        <span className="sr-only">Primera</span>
                        <span>Primera</span>
                      </button>
                      <button
                        onClick={() => setCurrentPage(page => Math.max(page - 1, 1))}
                        disabled={currentPage === 1}
                        className="relative inline-flex items-center px-2 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                      >
                        <span className="sr-only">Anterior</span>
                        <span>Anterior</span>
                      </button>
                      
                      {/* Page numbers */}
                      {[...Array(Math.min(5, totalPages))].map((_, i) => {
                        let pageNum;
                        if (totalPages <= 5) {
                          pageNum = i + 1;
                        } else if (currentPage <= 3) {
                          pageNum = i + 1;
                        } else if (currentPage >= totalPages - 2) {
                          pageNum = totalPages - 4 + i;
                        } else {
                          pageNum = currentPage - 2 + i;
                        }
                        
                        return (
                          <button
                            key={i}
                            onClick={() => setCurrentPage(pageNum)}
                            className={`relative inline-flex items-center px-4 py-2 border ${
                              currentPage === pageNum
                                ? 'z-10 bg-indigo-50 border-indigo-500 text-indigo-600'
                                : 'border-gray-300 bg-white text-gray-500 hover:bg-gray-50'
                            } text-sm font-medium`}
                          >
                            {pageNum}
                          </button>
                        );
                      })}
                      
                      <button
                        onClick={() => setCurrentPage(page => Math.min(page + 1, totalPages))}
                        disabled={currentPage === totalPages}
                        className="relative inline-flex items-center px-2 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                      >
                        <span className="sr-only">Siguiente</span>
                        <span>Siguiente</span>
                      </button>
                      <button
                        onClick={() => setCurrentPage(totalPages)}
                        disabled={currentPage === totalPages}
                        className="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                      >
                        <span className="sr-only">Última</span>
                        <span>Última</span>
                      </button>
                    </nav>
                  </div>
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default EdoCuentaTable; 