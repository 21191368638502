import React, {useEffect,useState,useRef} from "react";
import NumberFormat from 'react-number-format';
import { v4 as uuid_v4 } from "uuid";
import { useReactToPrint } from "react-to-print";

import {Bars} from 'react-loader-spinner';
import axios from 'axios';
import Moment from 'moment';

const UltimosPagos = (props) => {
  const [currentPages, setCurrentPages] = useState({});
  const [searchTerms, setSearchTerms] = useState({});
  const paymentsPerPage = 4;

  console.log(`PROPS COMING FROM ULTIMOS PAGOS`, props);
  const pagosData = props.pagosData.rows || props.pagosData;

  return(
    <div>
    {props.creditsData.map((credit, index) => {
      const creditId = credit.CreditoID;
      const currentPage = currentPages[creditId] || 1;
      const searchTerm = searchTerms[creditId] || '';

      const creditoPago = pagosData.map((pago) => {
        return (
          <tr
            key={uuid_v4()}
            className="bg-white border-b border-gray-200"
          >
            <td className="px-6 py-3 whitespace-no-wrap text-sm leading-5 text-gray-500">
              {pago.FechaPago}
            </td>

            <td className="px-6 py-3 whitespace-no-wrap text-sm leading-5 text-gray-500">
              {pago.RefPago}
            </td>

            <td className="px-6 py-3 whitespace-no-wrap text-sm leading-5 text-gray-500">
              <NumberFormat value={pago.Capital} displayType={'text'} thousandSeparator={true} prefix={'$'} />
            </td>

            <td className="px-6 py-3 whitespace-no-wrap text-sm leading-5 text-gray-500">
              <NumberFormat value={pago.Intereses} displayType={'text'} thousandSeparator={true} prefix={'$'} />
            </td>

            <td className="px-6 py-3 whitespace-no-wrap text-sm leading-5 text-gray-500">
              <NumberFormat value={pago.Moratorios} displayType={'text'} thousandSeparator={true} prefix={'$'} />
            </td>

            <td className="px-6 py-3 whitespace-no-wrap text-sm leading-5 text-gray-500">
              <NumberFormat value={pago.TotalPagado} displayType={'text'} thousandSeparator={true} prefix={'$'} />
            </td>

            <td className="px-6 py-3 whitespace-no-wrap text-sm leading-5 text-gray-500">
              {pago.FormaDePago === 'C' ? 'CARGO A CUENTA' : 'EFECTIVO'}
            </td>

            <td className="px-6 py-3 whitespace-no-wrap text-sm leading-5 text-gray-500">
              {pago.NombreProducto}
            </td>
          </tr>
        )
      });

      // Filter payments based on search term
      const filteredPayments = creditoPago.filter(row => {
        const searchStr = searchTerm.toLowerCase();
        const searchableText = [
          row.props.children[0].props.children, // FechaPago
          row.props.children[1].props.children, // RefPago
          row.props.children[2].props.children.props.value, // Capital
          row.props.children[3].props.children.props.value, // Intereses
          row.props.children[4].props.children.props.value, // Moratorios
          row.props.children[5].props.children.props.value, // TotalPagado
          row.props.children[6].props.children, // FormaDePago
          row.props.children[7].props.children  // NombreProducto
        ].map(value => value ? value.toString().toLowerCase() : '').join(' ');
        return searchableText.includes(searchStr);
      });

      // Get current payments
      const indexOfLastPayment = currentPage * paymentsPerPage;
      const indexOfFirstPayment = indexOfLastPayment - paymentsPerPage;
      const currentPayments = filteredPayments.slice(indexOfFirstPayment, indexOfLastPayment);

      // Calculate total pages
      const totalPages = Math.ceil(filteredPayments.length / paymentsPerPage);

      return(
        <div
          key={uuid_v4()}
          className="px-7 py-5 flex flex-wrap -mx-5 mb-2"
        >
          <div className="flex-grow mx-6 mt-1 bg-white shadow overflow-hidden sm:rounded-lg">
            <div className="px-4 py-5 border-b border-gray-200 sm:px-6">
              <div className="flex justify-between items-center">
                <h3 className="text-left text-xl leading-6 font-small text-gray-900">
                  Ultimos pagos recibidos SAFI (corte ayer):{" "}
                  <span className="text-orange-600">{credit.CreditoID}</span>
                </h3>
                <div className="relative">
                  <input
                    type="text"
                    placeholder="Buscar..."
                    value={searchTerm}
                    onChange={(e) => {
                      const value = e.target.value;
                      setSearchTerms(prev => ({...prev, [creditId]: value}));
                      setCurrentPages(prev => ({...prev, [creditId]: 1}));
                    }}
                    className="px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500"
                  />
                </div>
              </div>
            </div>
            <div className="pl-8 pr-8 pb-5 text-grey-darkest">
              <div className="flex flex-col">
                <div className="py-4 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
                  <div className="align-middle inline-block min-w-full shadow overflow-hidden sm:rounded-lg border-b border-gray-200">
                    <table className="min-w-full">
                      <thead>
                        <tr>
                          <th className="sticky top-0 px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                            Fecha Pago
                          </th>
                          <th className="sticky top-0 px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                            Referencia Pago
                          </th>
                          <th className="sticky top-0 px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                            Capital
                          </th>
                          <th className="sticky top-0 px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                            Interes
                          </th>
                          <th className="sticky top-0 px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                            Moratorios
                          </th>
                          <th className="sticky top-0 px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                            Total Pagado
                          </th>
                          <th className="sticky top-0 px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                            Forma de Pago
                          </th>
                          <th className="sticky top-0 px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                            Producto
                          </th>
                        </tr>
                      </thead>
                      <tbody>{currentPayments}</tbody>
                    </table>
                  </div>
                </div>
                {totalPages > 1 && (
                  <div className="flex justify-center mt-4">
                    <div className="flex-1 flex items-center justify-between">
                      <div>
                        <p className="text-sm text-gray-700">
                          Mostrando <span className="font-medium">{(currentPage - 1) * paymentsPerPage + 1}</span> a{' '}
                          <span className="font-medium">
                            {Math.min(currentPage * paymentsPerPage, filteredPayments.length)}
                          </span>{' '}
                          de <span className="font-medium">{filteredPayments.length}</span> resultados
                        </p>
                      </div>
                      <div>
                        <nav className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px" aria-label="Pagination">
                          <button
                            onClick={() => setCurrentPages(prev => ({
                              ...prev,
                              [creditId]: 1
                            }))}
                            disabled={currentPage === 1}
                            className="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                          >
                            <span className="sr-only">Primera</span>
                            <span>Primera</span>
                          </button>
                          <button
                            onClick={() => setCurrentPages(prev => ({
                              ...prev,
                              [creditId]: Math.max(currentPage - 1, 1)
                            }))}
                            disabled={currentPage === 1}
                            className="relative inline-flex items-center px-2 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                          >
                            <span className="sr-only">Anterior</span>
                            <span>Anterior</span>
                          </button>
                          
                          {[...Array(Math.min(5, totalPages))].map((_, i) => {
                            let pageNum;
                            
                            if (totalPages <= 5) {
                              pageNum = i + 1;
                            } else if (currentPage <= 3) {
                              pageNum = i + 1;
                            } else if (currentPage >= totalPages - 2) {
                              pageNum = totalPages - 4 + i;
                            } else {
                              pageNum = currentPage - 2 + i;
                            }
                            
                            return (
                              <button
                                key={i}
                                onClick={() => setCurrentPages(prev => ({
                                  ...prev,
                                  [creditId]: pageNum
                                }))}
                                className={`relative inline-flex items-center px-4 py-2 border ${
                                  currentPage === pageNum
                                    ? 'z-10 bg-indigo-50 border-indigo-500 text-indigo-600'
                                    : 'border-gray-300 bg-white text-gray-500 hover:bg-gray-50'
                                } text-sm font-medium`}
                              >
                                {pageNum}
                              </button>
                            );
                          })}
                          
                          <button
                            onClick={() => setCurrentPages(prev => ({
                              ...prev,
                              [creditId]: Math.min(currentPage + 1, totalPages)
                            }))}
                            disabled={currentPage === totalPages}
                            className="relative inline-flex items-center px-2 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                          >
                            <span className="sr-only">Siguiente</span>
                            <span>Siguiente</span>
                          </button>
                          <button
                            onClick={() => setCurrentPages(prev => ({
                              ...prev,
                              [creditId]: totalPages
                            }))}
                            disabled={currentPage === totalPages}
                            className="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                          >
                            <span className="sr-only">Última</span>
                            <span>Última</span>
                          </button>
                        </nav>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )
    })}
    </div>
  )
}

export default UltimosPagos;
