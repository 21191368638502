import React from 'react';
import EdoCuentaTable from './EdoCuentaTable';

const EdoCuenta = () => {
  return (
    <div className="container mx-auto px-4 sm:px-6 lg:px-8 py-8">
      <h1 className="text-2xl font-bold mb-6">Amortizaciones</h1>
      <EdoCuentaTable />
    </div>
  );
};

export default EdoCuenta; 