import React, {useEffect, useState, useRef} from "react";
import Template from "../Template";
import axios from "axios";
import {Bars} from 'react-loader-spinner';
import { orangeClasses, grayClasses } from "../Design/classes";

const WalletPagosRechazados = (props) => {
  const [walletData, setWalletData] = useState({pagos: []});
  const [loading, setLoading] = useState(true);
  const [filterText, setFilterText] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const {currentUser} = props;
  
  // New filter states
  const [selectedTipoPago, setSelectedTipoPago] = useState('');
  const [selectedBanco, setSelectedBanco] = useState('');
  const [selectedBancoEmisor, setSelectedBancoEmisor] = useState('');
  const [selectedMotivoRechazo, setSelectedMotivoRechazo] = useState('');

  // Lists for filter options (we'll populate these from the data)
  const [tiposPago, setTiposPago] = useState([]);
  const [bancos, setBancos] = useState([]);
  const [bancosEmisores, setBancosEmisores] = useState([]);
  const [motivosRechazo, setMotivosRechazo] = useState([]);

  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const [selectedPayment, setSelectedPayment] = useState(null);

  const includesFilterText = (text) => {
    return String(text || '').toLowerCase().includes(filterText.toLowerCase());
  };

  const isMounted = useRef(true);

  useEffect(() => {
    setLoading(true);
    loadConsulta();
  }, []);

  // Update useEffect to populate filter options when data loads
  useEffect(() => {
    if (walletData.pagos) {
      // Get unique values for each filter
      const uniqueTiposPago = [...new Set(walletData.pagos
        .filter(item => item.pago.tipoPago)
        .map(item => item.pago.tipoPago))];
      
      const uniqueBancos = [...new Set(walletData.pagos
        .filter(item => item.pago.bancoReceptor)
        .map(item => item.pago.bancoReceptor))];
      
      const uniqueBancosEmisores = [...new Set(walletData.pagos
        .filter(item => item.pago.bancoEmisor)
        .map(item => item.pago.bancoEmisor))];
      
      const uniqueMotivosRechazo = [...new Set(walletData.pagos
        .filter(item => item.pago.tipoRechazo)
        .map(item => item.pago.tipoRechazo))];

      setTiposPago(uniqueTiposPago.sort());
      setBancos(uniqueBancos.sort());
      setBancosEmisores(uniqueBancosEmisores.sort());
      setMotivosRechazo(uniqueMotivosRechazo.sort());
    }
  }, [walletData]);

  const loadConsulta = async () => {
    // For rejected payments, we need all payments without filtering by credit number
    await axios.get(`/api/v1/safi/get_all_pagos.json`).then((res) => {
      if(isMounted.current){
        setWalletData(res.data);
        setLoading(false);
      }
    });
  };

  const recuperarPago = (cotizaID) => {
    setLoading(true);
    const token = document.querySelector('[name=csrf-token]').content;
    axios.defaults.headers.common['X-CSRF-TOKEN'] = token;
    axios.post(`/api/v1/safi/update_pago`, {...{cotizaID}, ...{rechazado: 'false'}})
      .then(resp => {
        loadConsulta();
      })
      .catch(data => console.log('error', data));
  };

  // Update getFilteredData to include new filters
  const getFilteredData = () => {
    if (!walletData.pagos) return [];
    
    return walletData.pagos.filter(walletConcilia => {
      // Only include rejected payments
      if (walletConcilia.pago.conciliado !== 'false' || walletConcilia.pago.rechazado !== 'true') {
        return false;
      }
      
      // Apply text filter
      const numRef = walletConcilia.pago.referenciaTransferencia || '';
      const tipoPago = walletConcilia.pago.tipoPago || '';
      const credito = walletConcilia.pago.creditNumber || '';
      const nombreCliente = walletConcilia.pago.nombreCliente || '';
      const depositante = walletConcilia.pago.nombreDepositante || '';
      const bancoReceptor = walletConcilia.pago.bancoReceptor || '';
      const bancoEmisor = walletConcilia.pago.bancoEmisor || '';
      const libretaCobro = walletConcilia.pago.libretaCobro || '';
      const montoPagado = walletConcilia.pago.amount || '';
      const motivoRechazo = walletConcilia.pago.motivoRechazo || '';
      
      const textFilter = (
        includesFilterText(numRef) ||
        includesFilterText(tipoPago) ||
        includesFilterText(credito) ||
        includesFilterText(nombreCliente) ||
        includesFilterText(depositante) ||
        includesFilterText(bancoReceptor) ||
        includesFilterText(bancoEmisor) ||
        includesFilterText(libretaCobro) ||
        includesFilterText(montoPagado) ||
        includesFilterText(motivoRechazo)
      );

      // Apply dropdown filters
      const tipoFilter = !selectedTipoPago || walletConcilia.pago.tipoPago === selectedTipoPago;
      const bancoFilter = !selectedBanco || walletConcilia.pago.bancoReceptor === selectedBanco;
      const bancoEmisorFilter = !selectedBancoEmisor || walletConcilia.pago.bancoEmisor === selectedBancoEmisor;
      const motivoFilter = !selectedMotivoRechazo || walletConcilia.pago.tipoRechazo === selectedMotivoRechazo;

      return textFilter && tipoFilter && bancoFilter && bancoEmisorFilter && motivoFilter;
    })
    // Sort by updated_at in descending order (most recent first)
    .sort((a, b) => {
      const dateA = new Date(a.pago.updated_at);
      const dateB = new Date(b.pago.updated_at);
      return dateB - dateA;
    });
  };

  const filteredData = getFilteredData();
  
  // Pagination logic
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  const handlePageChange = (direction) => {
    if (direction === 'next' && currentPage < Math.ceil(filteredData.length / itemsPerPage)) {
      setCurrentPage(currentPage + 1);
    } else if (direction === 'previous' && currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  // Add reset filters function
  const handleReset = () => {
    setFilterText('');
    setSelectedTipoPago('');
    setSelectedBanco('');
    setSelectedBancoEmisor('');
    setSelectedMotivoRechazo('');
    setCurrentPage(1);
  };

  const handleShowDetails = (payment) => {
    setSelectedPayment(payment);
    setShowDetailsModal(true);
  };

  const handleCloseDetails = () => {
    setShowDetailsModal(false);
    setSelectedPayment(null);
  };

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  return (
    <>
      {loading ? (
        <div className="flex h-screen">
          <div className="m-auto">
            <Bars
              color="#00BFFF"
              height={100}
              width={100}
              timeout={20000} //3 secs
            />
          </div>
        </div>
      ) : (
        <div className="overflow-y-auto">
          {/* Rejection Details Modal */}
          {showDetailsModal && selectedPayment && (
            <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full z-50 flex items-center justify-center">
              <div className="relative mx-auto p-5 border w-[800px] shadow-lg rounded-md bg-white">
                <div className="mt-3">
                  <div className="flex items-center justify-center flex-col">
                    <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-red-100">
                      <svg className="h-6 w-6 text-red-600" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"/>
                      </svg>
                    </div>
                    <h3 className="text-lg leading-6 font-medium text-gray-900 mt-4">
                      Detalles del Rechazo
                    </h3>
                    <div className="mt-4 px-7 py-3 w-full">
                      <div className="mb-4">
                        <label className="block text-sm font-medium text-gray-700 mb-2">
                          Motivo del Rechazo
                        </label>
                        <div className="w-full px-3 py-2 border border-gray-300 rounded-md bg-gray-50">
                          {selectedPayment.pago.tipoRechazo || "No especificado"}
                        </div>
                      </div>
                      <div>
                        <label className="block text-sm font-medium text-gray-700 mb-2">
                          Nota Detallada
                        </label>
                        <div className="w-full px-3 py-2 border border-gray-300 rounded-md bg-gray-50 min-h-[75px]">
                          {selectedPayment.pago.motivoRechazo || "No especificado"}
                        </div>
                      </div>
                    </div>
                    <div className="flex justify-center gap-4 mt-4">
                      <button
                        onClick={handleCloseDetails}
                        className="px-4 py-2 bg-gray-300 text-gray-700 text-base font-medium rounded-md shadow-sm hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-gray-500"
                      >
                        Cerrar
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          <div className="mx-6 mt-6 bg-white shadow overflow-hidden sm:rounded-lg">
            <div className="px-4 py-5 border-b border-gray-200 sm:px-6">
              <h3 className="text-left text-lg leading-6 font-medium text-gray-900">
                Pagos Rechazados
              </h3>
            </div>

            {/* Filter Section */}
            <div className="p-5 flex flex-wrap gap-4 items-center">
              <label className="flex gap-x-2 items-baseline">
                <span className="text-gray-700">Buscar: </span>
                <input
                  type="text"
                  placeholder="Filtrar resultados"
                  value={filterText}
                  onChange={(e) => setFilterText(e.target.value)}
                  className="rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                />
              </label>

              <label className="flex gap-x-2 items-baseline">
                <span className="text-gray-700">Tipo de Pago: </span>
                <select
                  value={selectedTipoPago}
                  onChange={(e) => setSelectedTipoPago(e.target.value)}
                  className="rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                >
                  <option value="">Todos</option>
                  {tiposPago.map((tipo, index) => (
                    <option key={index} value={tipo}>{tipo}</option>
                  ))}
                </select>
              </label>

              <label className="flex gap-x-2 items-baseline">
                <span className="text-gray-700">Banco Emisor: </span>
                <select
                  value={selectedBancoEmisor}
                  onChange={(e) => setSelectedBancoEmisor(e.target.value)}
                  className="rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                >
                  <option value="">Todos</option>
                  {bancosEmisores.map((banco, index) => (
                    <option key={index} value={banco}>{banco}</option>
                  ))}
                </select>
              </label>

              <label className="flex gap-x-2 items-baseline">
                <span className="text-gray-700">Banco Receptor: </span>
                <select
                  value={selectedBanco}
                  onChange={(e) => setSelectedBanco(e.target.value)}
                  className="rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                >
                  <option value="">Todos</option>
                  {bancos.map((banco, index) => (
                    <option key={index} value={banco}>{banco}</option>
                  ))}
                </select>
              </label>

              <label className="flex gap-x-2 items-baseline">
                <span className="text-gray-700">Motivo de Rechazo: </span>
                <select
                  value={selectedMotivoRechazo}
                  onChange={(e) => setSelectedMotivoRechazo(e.target.value)}
                  className="rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                >
                  <option value="">Todos</option>
                  {motivosRechazo.map((motivo, index) => (
                    <option key={index} value={motivo}>{motivo}</option>
                  ))}
                </select>
              </label>

              <button
                onClick={handleReset}
                className="ml-auto px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                Limpiar Filtros
              </button>
            </div>

            <div className="px-7 py-5 flex flex-wrap -mx-5 mb-2">
              <div className="ml-2 mt-2 w-full mr-2 overflow-hidden">
                <div className="shadow sm:rounded-lg border-b border-gray-200 overflow-hidden">
                  <div className="overflow-x-auto" style={{ maxWidth: '100%', overflowY: 'visible' }}>
                    <table className="min-w-full table-fixed" style={{ width: 'max-content', minWidth: '100%' }}>
                      <thead>
                        <tr>
                          <th className="sticky top-0 px-6 py-3 border-b border-gray-200 bg-gray-50 text-center text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider w-40">
                            Número de Referencia
                          </th>
                          <th className="sticky top-0 px-6 py-3 border-b border-gray-200 bg-gray-50 text-center text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider w-32">
                            Tipo de Pago
                          </th>
                          <th className="sticky top-0 px-6 py-3 border-b border-gray-200 bg-gray-50 text-center text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider w-32">
                            No. de Crédito
                          </th>
                          <th className="sticky top-0 px-6 py-3 border-b border-gray-200 bg-gray-50 text-center text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider w-44">
                            Nombre de Cliente
                          </th>
                          <th className="sticky top-0 px-6 py-3 border-b border-gray-200 bg-gray-50 text-center text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider w-40">
                            Depositante
                          </th>
                          <th className="sticky top-0 px-6 py-3 border-b border-gray-200 bg-gray-50 text-center text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider w-36">
                            Banco Receptor
                          </th>
                          <th className="sticky top-0 px-6 py-3 border-b border-gray-200 bg-gray-50 text-center text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider w-36">
                            No. Libreta de Cobro
                          </th>
                          <th className="sticky top-0 px-6 py-3 border-b border-gray-200 bg-gray-50 text-center text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider w-32">
                            Monto Pagado
                          </th>
                          <th className="sticky top-0 px-6 py-3 border-b border-gray-200 bg-gray-50 text-center text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider w-36">
                            Fecha Rechazo
                          </th>
                          <th className="sticky top-0 px-6 py-3 border-b border-gray-200 bg-gray-50 text-center text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider w-40">
                            Motivo de Rechazo
                          </th>
                          <th className="sticky top-0 px-6 py-3 border-b border-gray-200 bg-gray-50 text-center text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider w-28">
                            Detalles
                          </th>
                          <th className="sticky top-0 px-6 py-3 border-b border-gray-200 bg-gray-50 text-center text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider w-28">
                            Comprobante
                          </th>
                          <th className="sticky top-0 px-6 py-3 border-b border-gray-200 bg-gray-50 text-center text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider w-28">
                            Acción
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {currentItems.map((walletConcilia, index) => {
                          const currentGrayClass = grayClasses[index % grayClasses.length];
                          
                          return (
                            <tr
                              key={walletConcilia.pago._id.$oid}
                              className={`${currentGrayClass} border-b border-gray-200`}
                            >
                              <td className="px-6 py-3 text-sm leading-5 text-gray-500 text-center truncate">
                                {walletConcilia.pago.referenciaTransferencia}
                              </td>

                              <td className="px-6 py-3 text-sm leading-5 text-center truncate">
                                {walletConcilia.pago.tipoPago}
                              </td>

                              <td className="px-6 py-3 text-sm leading-5 text-gray-500 text-center truncate">
                                {walletConcilia.pago.creditNumber}
                              </td>

                              <td className="px-6 py-3 text-sm leading-5 text-center truncate">
                                {walletConcilia.pago.nombreCliente}
                              </td>

                              <td className="px-6 py-3 text-sm leading-5 text-gray-500 text-center truncate">
                                {walletConcilia.pago.nombreDepositante}
                              </td>

                              <td className="px-6 py-3 text-sm leading-5 text-gray-500 text-center truncate">
                                {walletConcilia.pago.bancoReceptor}
                              </td>

                              <td className="px-6 py-3 text-sm leading-5 text-gray-500 text-center truncate">
                                {walletConcilia.pago.libretaCobro}
                              </td>

                              <td className="px-6 py-3 text-sm leading-5 text-gray-500 text-center truncate">
                                {new Intl.NumberFormat("en-US", {
                                  style: "currency",
                                  currency: "USD"
                                }).format(walletConcilia.pago.amount)}
                              </td>
                              
                              <td className="px-6 py-3 text-sm leading-5 text-gray-500 text-center truncate">
                                {walletConcilia.pago.updated_at ? new Date(walletConcilia.pago.updated_at).toLocaleDateString('es-PA', {
                                  year: 'numeric',
                                  month: '2-digit',
                                  day: '2-digit',
                                  hour: '2-digit',
                                  minute: '2-digit'
                                }) : ''}
                              </td>
                              
                              <td className="px-6 py-3 text-sm leading-5 text-gray-500 text-center truncate">
                                {walletConcilia.pago.motivoRechazo || "No especificado"}
                              </td>
                              
                              <td className="px-6 py-3 text-sm leading-5 text-gray-500 text-center">
                                <button
                                  onClick={() => handleShowDetails(walletConcilia)}
                                  className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                                >
                                  Ver Detalle
                                </button>
                              </td>

                              <td className="px-6 py-3 text-sm leading-5 text-gray-500 text-center">
                                {walletConcilia.pago.attachments && walletConcilia.pago.attachments.map((attachment, index) => (
                                  <div key={index}>
                                    <a className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded" href={attachment} target="_blank" rel="noopener noreferrer">{`Anexo`}</a>
                                  </div>
                                ))}
                              </td>

                              <td className="px-6 py-3 text-sm leading-5 text-gray-500 text-center">
                                <button 
                                  className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded" 
                                  onClick={() => recuperarPago(walletConcilia.pago._id.$oid)}
                                >
                                  Recuperar
                                </button>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                  <div className="px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6">
                    <div className="flex-1 flex justify-between sm:hidden">
                      <button
                        onClick={() => handlePageChange('previous')}
                        disabled={currentPage === 1}
                        className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                      >
                        Anterior
                      </button>
                      <button
                        onClick={() => handlePageChange('next')}
                        disabled={currentPage === Math.ceil(filteredData.length / itemsPerPage)}
                        className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                      >
                        Siguiente
                      </button>
                    </div>
                    <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
                      <div>
                        <p className="text-sm text-gray-700">
                          Mostrando <span className="font-medium">{(currentPage - 1) * itemsPerPage + 1}</span> a{' '}
                          <span className="font-medium">
                            {Math.min(currentPage * itemsPerPage, filteredData.length)}
                          </span>{' '}
                          de <span className="font-medium">{filteredData.length}</span> resultados
                        </p>
                      </div>
                      <div>
                        <nav className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px" aria-label="Pagination">
                          <button
                            onClick={() => setCurrentPage(1)}
                            disabled={currentPage === 1}
                            className="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                          >
                            <span className="sr-only">Primera</span>
                            <span>Primera</span>
                          </button>
                          <button
                            onClick={() => handlePageChange('previous')}
                            disabled={currentPage === 1}
                            className="relative inline-flex items-center px-2 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                          >
                            <span className="sr-only">Anterior</span>
                            <span>Anterior</span>
                          </button>
                          
                          {[...Array(Math.min(5, Math.ceil(filteredData.length / itemsPerPage)))].map((_, i) => {
                            let pageNum;
                            const totalPages = Math.ceil(filteredData.length / itemsPerPage);
                            
                            if (totalPages <= 5) {
                              pageNum = i + 1;
                            } else if (currentPage <= 3) {
                              pageNum = i + 1;
                            } else if (currentPage >= totalPages - 2) {
                              pageNum = totalPages - 4 + i;
                            } else {
                              pageNum = currentPage - 2 + i;
                            }
                            
                            return (
                              <button
                                key={i}
                                onClick={() => setCurrentPage(pageNum)}
                                className={`relative inline-flex items-center px-4 py-2 border ${
                                  currentPage === pageNum
                                    ? 'z-10 bg-indigo-50 border-indigo-500 text-indigo-600'
                                    : 'border-gray-300 bg-white text-gray-500 hover:bg-gray-50'
                                } text-sm font-medium`}
                              >
                                {pageNum}
                              </button>
                            );
                          })}
                          
                          <button
                            onClick={() => handlePageChange('next')}
                            disabled={currentPage === Math.ceil(filteredData.length / itemsPerPage)}
                            className="relative inline-flex items-center px-2 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                          >
                            <span className="sr-only">Siguiente</span>
                            <span>Siguiente</span>
                          </button>
                          <button
                            onClick={() => setCurrentPage(Math.ceil(filteredData.length / itemsPerPage))}
                            disabled={currentPage === Math.ceil(filteredData.length / itemsPerPage)}
                            className="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                          >
                            <span className="sr-only">Última</span>
                            <span>Última</span>
                          </button>
                        </nav>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default WalletPagosRechazados;
