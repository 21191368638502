import React, {useEffect,useState} from "react";
import DatosClientes from './DatosClientes';
import ResumenProductos from './ResumenProductos';
import Seguimiento from "./Seguimiento";
import SeguimientosRows from "./SeguimientosRows";
import PagosRows from "./PagosRows";
import axios from 'axios';
import UltimosPagosSFCore from "../PagoCredito/UltimosPagosSFCore";
import UltimosPagos from "../PagoCredito/UltimosPagos";
import {Bars} from 'react-loader-spinner';
import Facturas from "./Facturas";


const ConsultaCredito = (props) => {

    const [currentUser, setCurrentUser] = useState([]);
    const [client,setClient] = useState([]);
    const [credit,setCredit] = useState([]);
    const [filteredCreditsList,setFilteredCredits] = useState([]);
    const [queriedCreditNumber,setQueriedCreditNumber] = useState([]);
    const [activeCredits,setActiveCredits] = useState([]);
    const [totalOwed,setTotalOwed] = useState([]);
    const [totalBillsOwed,setTotalBillsOwed] = useState([]);
    const [delayRatio,setDelayRatio] = useState([]);
    const [loading, setLoading] = useState([]);
    const [pagos, setPagos] = useState([]);
    const [efectivoMovs, setEfectivoMovs] = useState([]);
    const [avalesData, setAvalesData] = useState([]);
    const [garantiasData, setGarantiasData] = useState([]);
    const [seguimientosData, setSeguimientosData] = useState([]);
    const [montoAPagar, setMontoAPagar] = useState([]);
    const [pagosSFCore, setPagosSFCore] = useState([]);
    const [activeTab, setActiveTab] = useState(1);
    const [ facturas, setFacturas ] = useState([]);

    const handleTabChange = (tab) => {
        setActiveTab(tab);
      };

    useEffect(() => {
        loadConsulta()
    }, [])

    const loadConsulta = async () => {
        setLoading(true);


        const creditoID = parseInt(props.creditoID || props.match?.params?.creditoID);
        const clienteID = parseInt(props.clienteID || props.match?.params?.clienteID);

        try {
            const clientes2Res = await axios.get(`/api/v1/clientes2/${clienteID}`);
            const pagosCreditoRes = await axios.get(`https://api.sumadata.net/pagoscredito?credito=${props.creditoID}`);
            const efectivoMovs = await axios.get(`/api/v1/efectivomovs?cuentaid=${clientes2Res.data.credits[0].CuentaID}`);
            const avalesPorCreditosRes = await axios.get(`/api/v1/avalesporcreditos?creditoid=${creditoID}`);
            const garantiasPorCreditosRes = await axios.get(`/api/v1/garantiasres?creditoid=${creditoID}`);
            const seguimientoRes = await axios.get(`/api/v1/safi/get_all_seguimientos`);
            const montoAPagarRes = await axios.get(`/api/v1/montoapagar?creditoid=${creditoID}`);

            await axios.get(`/api/v1/safi/get_all_pagos.json?credit_number=${creditoID}`).then((res) => {
                setPagosSFCore(res.data);
                setLoading(false);
            });

            setPagos(pagosCreditoRes.data.rows);
            setEfectivoMovs(efectivoMovs.data);
            setAvalesData(avalesPorCreditosRes.data);
            setGarantiasData(garantiasPorCreditosRes.data);
            setSeguimientosData(seguimientoRes);
            setMontoAPagar(montoAPagarRes.data[0].MontoAPagar);
        
            const queriedCredit = clientes2Res.data.credits.filter(item => item.CreditoID === creditoID)

            const filteredCredits = clientes2Res.data.credits.filter(item => item.Estatus === 'V' || item.Estatus === 'B')


            setFacturas(queriedCredit[0].invoices);


            let count = filteredCredits.length;

            //Total owed
            const totalOwed = clientes2Res.data.credits
            .filter(item => item.Estatus === 'V')
            .reduce((a,v) => a = a + v.SaldoCapVigent + v.SaldCapVenNoExi + v.SaldoCapVencido + v.SaldoCapAtrasad + v.SaldoInterOrdin + v.SaldoInterAtras + v.SaldoInterVenc + v.SaldoIntNoConta + v.SaldoInterProvi + v.SaldoMoratorios + v.SaldComFaltPago + v.SaldoIVAComisi + v.SaldoOtrasComis + v.SaldoFECI + v.FECIAdeudado, 0)

            //Total bills
            const totalBillsOwed = clientes2Res.data.credits
            .filter(item2 => item2.Estatus === 'V')
            .reduce((c, f) => c = c + f.MontoCuota, 0)

            //Delay Ratio
            const delayRatio = (clientes2Res.data.credits
            .reduce((g, h) => g = g + h.PromedioDiasAtraso, 0)) / 2

           //Settings States
            setClient(clientes2Res.data.client)
            setCredit(queriedCredit)
            setActiveCredits(count)
            setTotalOwed(totalOwed)
            setTotalBillsOwed(totalBillsOwed)
            setDelayRatio(delayRatio)
            setQueriedCreditNumber(creditoID)
            setFilteredCredits(filteredCredits)

        } catch (error) {
            console.log("Error:", error);
        }   finally {
            setLoading(false);
        }

    }

    return(
    <>
    
    {
        loading ?
        <div className="flex h-screen">
        <div className="m-auto">
            <Bars
                color="#00BFFF"
                height={100}
                width={100}
                timeout={30000} //3 secs                
            />
            </div>
        </div> 
        :  

    <div className="overflow-y-auto">
        <div className="mx-6 mt-6 bg-white shadow overflow-hidden sm:rounded-lg">
            <div className="flex justify-between items-center px-4 py-5 border-b border-gray-200 sm:px-6">
                <h3 className="text-lg leading-6 font-medium text-gray-900">
                    Consulta de Credito (Credito: {queriedCreditNumber})
                </h3>
                <button className="h-9 px-8 font-bold rounded text-red-600 bg-red-300" onClick={props.closeModal}>Cerrar</button>
            </div>

            <div className="px-7 py-5 flex flex-wrap -mx-5 mb-2">
                <span className="pt-2 ml-4 mr-2 text-left text-sm leading-6 font-semibold  text-gray-900">Creditos del Cliente: </span>
                {filteredCreditsList.map((walletCredits, index) => {
                    return (
                        <div key={`${walletCredits.CreditoID}-${index}`}>

                            <button onClick={() => { window.location.href = '/consulta/' + walletCredits.ClienteID + '/' + walletCredits.CreditoID }} className="bg-orange-300 hover:bg-orange-400 text-orange-800 font-bold w-36 py-2 px-4 rounded inline-flex items-center mr-4">
                                <svg className="fill-current w-4 h-4 mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M19.129,18.164l-4.518-4.52c1.152-1.373,1.852-3.143,1.852-5.077c0-4.361-3.535-7.896-7.896-7.896
            c-4.361,0-7.896,3.535-7.896,7.896s3.535,7.896,7.896,7.896c1.934,0,3.705-0.698,5.078-1.853l4.52,4.519
            c0.266,0.268,0.699,0.268,0.965,0C19.396,18.863,19.396,18.431,19.129,18.164z M8.567,15.028c-3.568,0-6.461-2.893-6.461-6.461
            s2.893-6.461,6.461-6.461c3.568,0,6.46,2.893,6.46,6.461S12.135,15.028,8.567,15.028z"/></svg>
                                <span>{walletCredits.CreditoID}</span>
                            </button>
                        </div>
                    )
                })
                }
            </div>

            <div className=" bg-gray-300 px-4 py-5 border-b border-gray-100 sm:px-6">
                <div className="flex items-center">
                    <h3 className="text-left text-xl leading-6 font-small text-gray-900">
                        Generales destacadas: <button className="bg-green-500 hover:bg-green-400 text-black font-bold ml-6 py-2 px-4 rounded inline-flex items-center mr-4">Monto a pagar: {montoAPagar}</button>
                    </h3>
                </div>
            </div>
        </div>

        <div className="sm:hidden">
            <label for="tabs" className="sr-only">Seleccione</label>
            <select id="tabs" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                <option>Datos del Cliente</option>
                <option>Resumen de Producto</option>
                <option>Seguimiento</option>
                <option>Pagos SFCore</option>
                <option>Pagos SAFI</option>
                <option>Historial de Pagos</option>
            </select>
        </div> 
        <ul className="mx-6 mt-6 hidden text-sm font-medium text-center text-gray-500 divide-x divide-gray-200 rounded-lg shadow sm:flex dark:divide-gray-700 dark:text-gray-400">
            <li className="w-full">
                <button
                    className={` ${activeTab === 1 ? 'inline-block w-full p-2 text-white bg-orange-500 rounded-l-lg focus:ring-4 focus:ring-blue-300 active focus:outline-none dark:bg-gray-700 dark:text-white' : 'inline-block w-full p-2 text-gray-900 bg-gray-100 rounded-l-lg focus:ring-4 focus:ring-blue-300 active focus:outline-none dark:bg-gray-700 dark:text-white hover:bg-orange-500 hover:text-white'
                        }`}
                    onClick={() => handleTabChange(1)}
                >
                    Datos del Cliente
                </button>
            </li>
            <li className="w-full">
                <button
                    className={`${activeTab === 6 ? 'inline-block w-full text-white p-2 bg-orange-500 hover:text-white hover:bg-orange-500 focus:ring-4 focus:ring-orange-300 focus:outline-none dark:hover:text-white dark:bg-orange-800 dark:hover:bg-orange-700' : 'inline-block w-full p-2 bg-gray-100 hover:text-white hover:bg-orange-500 focus:ring-4 focus:ring-orange-300 focus:outline-none dark:hover:text-white dark:bg-orange-800 dark:hover:bg-orange-700'
                        }`}
                    onClick={() => handleTabChange(6)}
                >
                    Resumen de Producto
                </button>
            </li>
            <li className="w-full">
                <button
                    className={`${activeTab === 2 ? 'inline-block w-full text-white p-2 bg-orange-500 hover:text-white hover:bg-orange-500 focus:ring-4 focus:ring-orange-300 focus:outline-none dark:hover:text-white dark:bg-orange-800 dark:hover:bg-orange-700' : 'inline-block w-full p-2 bg-gray-100 hover:text-white hover:bg-orange-500 focus:ring-4 focus:ring-orange-300 focus:outline-none dark:hover:text-white dark:bg-orange-800 dark:hover:bg-orange-700'
                        }`}
                    onClick={() => handleTabChange(2)}
                >
                    Seguimiento
                </button>
            </li>
            <li className="w-full">
                <button
                    className={`${activeTab === 4 ? 'inline-block w-full text-white p-2 bg-orange-500 hover:text-white hover:bg-orange-500 focus:ring-4 focus:ring-orange-300 focus:outline-none dark:hover:text-white dark:bg-orange-800 dark:hover:bg-orange-700' : 'inline-block w-full p-2 bg-gray-100 hover:text-white hover:bg-orange-500 focus:ring-4 focus:ring-orange-300 focus:outline-none dark:hover:text-white dark:bg-orange-800 dark:hover:bg-orange-700'
                        }`}
                    onClick={() => handleTabChange(4)}
                >
                    Pagos SFCore
                </button>
            </li>
            <li className="w-full">
                <button
                    className={`${activeTab === 5 ? 'inline-block w-full text-white p-2 bg-orange-500 hover:text-white hover:bg-orange-500 focus:ring-4 focus:ring-orange-300 focus:outline-none dark:hover:text-white dark:bg-orange-800 dark:hover:bg-orange-700' : 'inline-block w-full p-2 bg-gray-100 hover:text-white hover:bg-orange-500 focus:ring-4 focus:ring-orange-300 focus:outline-none dark:hover:text-white dark:bg-orange-800 dark:hover:bg-orange-700'
                        }`}
                    onClick={() => handleTabChange(5)}
                >
                    Pagos SAFI
                </button>
            </li>
            <li className="w-full">
                <button
                    className={`${activeTab === 3 ? 'inline-block w-full text-white p-2 bg-orange-500 hover:text-white hover:bg-orange-500 focus:ring-4 focus:ring-orange-300 focus:outline-none dark:hover:text-white dark:bg-orange-800 dark:hover:bg-orange-700' : 'inline-block w-full p-2 bg-gray-100 hover:text-white hover:bg-orange-500 focus:ring-4 focus:ring-orange-300 focus:outline-none dark:hover:text-white dark:bg-orange-800 dark:hover:bg-orange-700'
                        }`}
                    onClick={() => handleTabChange(3)}
                >
                    Historial de Seguimientos
                </button>
            </li>
            <li className="w-full">
                <button
                    className={`${activeTab === 7 ? 'rounded-r-lg inline-block w-full text-white p-2 bg-orange-500 hover:text-white hover:bg-orange-500 focus:ring-4 focus:ring-orange-300 focus:outline-none dark:hover:text-white dark:bg-orange-800 dark:hover:bg-orange-700' : 'rounded-r-lg inline-block w-full p-2 bg-gray-100 hover:text-white hover:bg-orange-500 focus:ring-4 focus:ring-orange-300 focus:outline-none dark:hover:text-white dark:bg-orange-800 dark:hover:bg-orange-700'
                        }`}
                    onClick={() => handleTabChange(7)}
                >
                    Facturas
                </button>
            </li>
        </ul>

        <div className="mt-5 bg-white shadow-sm rounded-lg">
            <div className="p-4">
                {activeTab === 1 && <DatosClientes clientData={client} creditsCount={activeCredits} totalOwed={totalOwed} totalBillsOwed={totalBillsOwed} delayRatio={delayRatio} avalesData={avalesData} garantiasData={garantiasData} />}
                {activeTab === 2 && <Seguimiento creditsData={credit} clientData={client} />}
                {activeTab === 3 && <SeguimientosRows seguimientosData={seguimientosData} creditsData={credit} />}
                {activeTab === 4 && <UltimosPagosSFCore creditsData={credit} pagosSFCore={pagosSFCore} />}
                {activeTab === 5 && <UltimosPagos creditsData={credit} pagosData={pagos} />}
                {activeTab === 6 && <ResumenProductos creditsData={credit} pagosData={pagos} movimientosEfectivo={efectivoMovs} />}
                {activeTab === 7 && <Facturas creditsData={credit} clientData={client} facturas={facturas} pagosSFCore={pagosSFCore}/>}



            </div>
        </div>
    </div>
    }
    </>       
            

    )

}

export default ConsultaCredito;
