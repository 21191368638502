import React, {useState} from "react";
// import Template from '../Template'

import axios from 'axios';
import ResumenCredito from './ResumenCredito';


const ConsultaEstadoCuenta = (props) => {

    const [credit, setCredit] = useState([]);
    const [creditData, setCreditData] = useState([]);
    const [currentUser, setCurrentUser] = useState([]);
    const [pagos, setPagos] = useState([]);
    const [visual, setVisual] = useState(false);
    const [pagosSFCore, setPagosSFCore] = useState([]);
    const [loading, setLoading] = useState([]);

    
    const loadConsulta = async () => {

        await axios.get(`/api/v1/creditosid/${credit}`).then(res => {

            axios.get(`https://api.sumadata.net/pagoscredito?credito=${credit}`).then((res) => {
                setPagos(res.data);
            });

            setCreditData(res.data.credits);
            setVisual(true);
        });

        await axios.get(`/api/v1/safi/get_all_pagos.json?credit_number=${credit}`).then((res) => {
            setPagosSFCore(res.data);
            setLoading(false);
        });
          

    }

        return (
            <>
            { false ?
                <div className="flex h-screen">
                <div className="m-auto">
                    <Bars
                        color="#00BFFF"
                        height={100}
                        width={100}
                        timeout={20000} //3 secs

                    />
                    </div>
                </div>
              :
                <div className="overflow-y-auto">

                    <div className="mx-6 mt-6 bg-white shadow overflow-hidden sm:rounded-lg">
                        <div className="px-4 py-5 border-b border-gray-200 sm:px-6">
                            <h3 className="text-left text-lg leading-6 font-medium text-gray-900">
                            Inserte el número de Crédito
                            </h3>
                        </div>

                        <div className="px-7 py-5 flex flex-wrap -mx-5 mb-2">

                            <div className="ml-2 mt-2 inline-flex">

                                <input maxLength="14" className="w-full h-10 pl-3 pr-8 text-base placeholder-gray-300 border border-gray-100 bg-gray-100 text-gray-700 rounded focus:shadow-outline " type="text" placeholder="Numero de Crédito" name="clientID" onChange={e => setCredit(e.target.value)} />
                                <button className="relative ml-2 h-10 right-0 flex items-center px-8 font-bold hover:text-white rounded hover:bg-yellow-600 border border-yellow-600 text-yellow-600" onClick={(event => loadConsulta())}>Ver</button>

                            </div>

                        </div>
                    </div>

                     <div className="mx-6 mt-6 bg-white shadow overflow-hidden sm:rounded-lg">
                         { <ResumenCredito creditsData={creditData} pagosData={pagos} /> }
                    </div>

                    {/* <div className="mx-6 mt-6 bg-white shadow overflow-hidden sm:rounded-lg">
                         { <UltimosPagosSFCore creditsData={creditData} pagosSFCore={pagosSFCore}/> } 
                    </div>

                    <div className="mx-6 mt-6 bg-white shadow overflow-hidden sm:rounded-lg">
                         { <UltimosPagos creditsData={creditData} pagosData={pagos} /> } 
                    </div>


                    <div className="mx-6 mt-6 bg-white shadow overflow-hidden sm:rounded-lg">
                         { <ResumenPagoCredito creditsData={creditData} pagosData={pagos}  setMontoPagar={setMontoPagar}/> } 
                    </div>
                    
                    <div className="mx-6 mt-6 bg-white shadow overflow-hidden sm:rounded-lg">
                         { <Pago creditsData={creditData} pagosData={pagos} montoPagar={montoPagar} visual={visual}/> } 
                    </div> */}
                    
                </div>
            }
            </>
        )
    
}

export default ConsultaEstadoCuenta;
