import React, {useState} from "react";
import ResumenPagoCredito from './ResumenPagoCredito';
import UltimosPagos from './UltimosPagos';
import UltimosPagosSFCore from './UltimosPagosSFCore';
import Pago from './Pago';
import {Bars} from 'react-loader-spinner';
import axios from 'axios';

const ConsultaCreditoPago = (props) => {

    const [credit, setCredit] = useState([]);
    const [creditData, setCreditData] = useState([]);
    const [pagos, setPagos] = useState([]);
    const [montoPagar, setMontoPagar] = useState([0]);
    const [visual, setVisual] = useState(false);
    const [pagosSFCore, setPagosSFCore] = useState([]);
    const [loading, setLoading] = useState(false);
    const {currentUser} = props;
    const [montoAPagar, setMontoAPagar] = useState([]);

    const resetSearch = () => {
        // Reset all state variables
        setCredit([]);
        setCreditData([]);
        setPagos([]);
        setMontoPagar([0]);
        setMontoAPagar([]);
        setVisual(false);
        setPagosSFCore([]);
    };
    
    const loadConsulta = async () => {
        setLoading(true);

        await axios.get(`/api/v1/creditosid/${credit}`,
        ).then(res => {
            
            axios.get(`https://api.sumadata.net/pagoscredito?credito=${credit}`).then((res) => {
                setPagos(res.data);
            });

            setVisual(true);
            setCreditData(res.data.credits);
        });


        await axios.get(`/api/v1/safi/get_all_pagos.json?credit_number=${credit}`).then((res) => {
            setPagosSFCore(res.data);
            setLoading(false);
        });

        const montoAPagarRes = await axios.get(`/api/v1/montoapagar?creditoid=${credit}`);
        setMontoAPagar(montoAPagarRes.data[0].MontoAPagar);
    }

    return (
        <>
        {loading ? (
            <div className="flex h-screen">
            <div className="m-auto">
                <Bars
                    color="#00BFFF"
                    height={100}
                    width={100}
                    timeout={20000}
                />
                </div>
            </div>
          ) : (
            <div className="overflow-y-auto">

                <div className="mx-6 mt-6 bg-white shadow overflow-hidden sm:rounded-lg">
                    <div className="px-4 py-5 border-b border-gray-200 sm:px-6">
                        <h3 className="text-left text-lg leading-6 font-medium text-gray-900">
                        Pago de Credito
                        </h3>
                    </div>

                    <div className="px-7 py-5 flex flex-wrap -mx-5 mb-2">

                        <div className="ml-2 mt-2 inline-flex">

                            <input maxLength="14" className="w-full h-10 pl-3 pr-8 text-base placeholder-gray-300 border border-gray-100 bg-gray-100 text-gray-700 rounded focus:shadow-outline " type="text" placeholder="Numero de Crédito" name="clientID" value={credit} onChange={e => setCredit(e.target.value)} />
                            <button className="relative ml-2 h-10 right-0 flex items-center px-8 font-bold hover:text-white rounded hover:bg-yellow-600 border border-yellow-600 text-yellow-600" onClick={(event => loadConsulta())}>Buscar</button>

                        </div>

                    </div>
                </div>

                {visual && (
                <>
                <div className="mx-6 mt-6 bg-gray-300 px-4 py-5 border-b border-gray-100 sm:rounded-lg">
                        <h3 className="ml-3 text-left text-xl leading-6 font-small text-gray-900">
                            <b>Generales destacadas:</b> <button className="bg-green-500 hover:bg-green-400 text-black font-bold ml-6 py-2 px-4 rounded inline-flex items-center mr-4">Monto a pagar: {montoAPagar}</button>

                            <button className="bg-green-500 hover:bg-green-400 text-black font-bold ml-6 py-2 px-4 rounded inline-flex items-center mr-4">Nombre del Cliente: {creditData[0]?.NombreCompleto}</button>

                            
                        </h3>
                    </div>

                <div className="mx-6 mt-6 bg-white shadow overflow-hidden sm:rounded-lg">
                     { <UltimosPagosSFCore creditsData={creditData} pagosSFCore={pagosSFCore}/> } 
                </div>

                <div className="mx-6 mt-6 bg-white shadow overflow-hidden sm:rounded-lg">
                     { <UltimosPagos creditsData={creditData} pagosData={pagos} /> } 
                </div>


                <div className="mx-6 mt-6 bg-white shadow overflow-hidden sm:rounded-lg">
                     { <ResumenPagoCredito creditsData={creditData} pagosData={pagos} setMontoPagar={setMontoPagar} montoAPagar={montoAPagar}/> } 
                </div>
                
                <div className="mx-6 mt-6 bg-white shadow overflow-hidden sm:rounded-lg">
                     { <Pago creditsData={creditData} pagosData={pagos} montoPagar={montoPagar} visual={visual} usuario={currentUser} onReset={resetSearch}/> } 
                </div>
                </>
                )}
                
            </div>
        )}
        </>
    )
    
}

export default ConsultaCreditoPago;
