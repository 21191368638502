import React, {useEffect,useState,useRef} from "react";
import {Bars} from 'react-loader-spinner';
import axios from 'axios';
import Moment from 'moment';
import { useReactToPrint } from "react-to-print";
import Documents from './Documents';
import {MyDropzoneForm} from '../Prospect/MyDropzone';
import FormikControl from "../forms/FormikControl";
import {Formik, Field, FieldArray, Form} from 'formik';
import * as Yup from 'yup';
import DatePicker from 'react-datepicker';
import {isFormValid} from '../Helpers/Helpers';
import { usePaymentContext } from '../Context/PaymentContext';
import DuplicatePaymentWarning from './DuplicatePaymentWarning';

const validationSchema = Yup.object({
  // TipoMotivo: Yup.string()
  //     .required('Requerido'),
  montoAPagar: Yup.string()
      .required('Requerido'),

})

const Pago = (props) => {
  
    const [paymentSelected, setPaymentSelected] = useState([]);
    const [loading, setLoading] = useState(false);
    const [fechaTransferencia, setFechaTransferencia] = useState('');
    const [pagoerror, setPagoError] = useState([]);
    const [bancoEmisor, setBancoEmisor] = useState('');
    const [bancoReceptor, setBancoReceptor] = useState('');
    const [referenciaTransferencia, setReferenciaTransferencia] = useState('');
    const [nombreDepositante, setNombreDepositante] = useState('');
    const [nombreTarjetaHabiente, setNombreTarjetaHabiente] = useState('');
    const [libretaCobro, setLibretaCobro] = useState('');
    const [tipoTarjeta, setTipoTarjeta] = useState('');
    const [ultimosDigitos, setUltimosDigitos] = useState('');
    const [fechaVencimiento, setFechaVencimiento] = useState('');
    const [sucursalEmisora, setSucursalEmisora] = useState('');
    const [tipoPago, setTipoPago] = useState('');
    const [file, setFile] = useState({});
    const [files, setFiles] = useState([]);
    const [numtransaccion, setNumTransaccion] = useState(0);
    const [countdown, setCountdown] = useState(0);
    const [showDuplicateWarning, setShowDuplicateWarning] = useState(false);
    
    const { recordPayment, checkForDuplicate, getLastPayment } = usePaymentContext();
    console.log("Context hooks available:", { recordPayment, checkForDuplicate, getLastPayment });

    const sum =  (props.montoPagar.reduce((result,number)=> parseFloat(result)+parseFloat(number))).toFixed(2);
    const credito = props.creditsData[0]?.CreditoID;
    const cliente = props.creditsData[0]?.NombreCompleto;
    const componentRef = useRef();

    const [montoAPagar, setMontoAPagar] = useState(0);
    const [buttonDisabled, setButtonDisabled] = useState(false);

    const paymentData = {
        customerNumber: props.creditsData[0]?.ClienteID,
        paymentType: 'E',
        creditNumber: props.creditsData[0]?.CreditoID,
        savingsAccountNumber: '',
        amount: montoAPagar,
        cashNumber: '27',
        branchOffice: '1',
        nombreCliente: props.creditsData[0]?.NombreCompleto,
        tipoPago: tipoPago,
        fechaTransferencia: fechaTransferencia,
        bancoEmisor: bancoEmisor,
        bancoReceptor: bancoReceptor,
        referenciaTransferencia: referenciaTransferencia,
        nombreDepositante: nombreDepositante,
        libretaCobro: libretaCobro,
        sucursalEmisora: sucursalEmisora,
        conciliado: "false",
        numTransaccion: numtransaccion,
        usuario: props.usuario
    
      }

    //FUNCTIONS
    const pagarCredito = (event) => {
      if (isFormValid(nombreTarjetaHabiente, nombreDepositante, montoAPagar, fechaTransferencia, referenciaTransferencia, ultimosDigitos /*, other inputs */)) {
        // Check for duplicate payment before proceeding
        const paymentToCheck = {
          creditoID: credito,
          monto: montoAPagar,
          tipoPago: tipoPago
        };
        
        console.log("About to check for duplicate payment:", paymentToCheck);
        const isDuplicate = checkForDuplicate(paymentToCheck);
        console.log("Duplicate check result:", isDuplicate);
        
        if (isDuplicate) {
          // Show duplicate warning instead of proceeding immediately
          console.log("Showing duplicate warning");
          setShowDuplicateWarning(true);
          return;
        }
        
        // If no duplicate detected, proceed with normal flow
        // Disable the button immediately and start countdown
        setButtonDisabled(true);
        setCountdown(5);
        
        // Create a countdown timer
        const countdownInterval = setInterval(() => {
          setCountdown(prevCount => {
            if (prevCount <= 1) {
              clearInterval(countdownInterval);
              // Once countdown reaches 0, proceed with payment
              startPaymentProcess();
              return 0;
            }
            return prevCount - 1;
          });
        }, 1000);
      }
    };

    // Function to handle the actual payment process
    const startPaymentProcess = () => {
      setLoading(true);
    
      const token = document.querySelector('[name=csrf-token]').content;
      axios.defaults.headers.common['X-CSRF-TOKEN'] = token;
    
      // Process payment regardless of payment type
      axios
        .post(`/api/v1/safi/save_pago`, { ...paymentData, ...{ file: file } })
        .then((resp) => {
          // Record successful payment in our context
          recordPayment({
            creditoID: credito,
            monto: montoAPagar,
            tipoPago: tipoPago,
            timestamp: new Date()
          });
          console.log("Payment recorded in context:", {
            creditoID: credito,
            monto: montoAPagar,
            tipoPago: tipoPago
          });
          
          // Check if there are files to upload
          if (files && files.length > 0) {
            let formData = new FormData();
            
            for (var i = 0; i < files.length; i++) {
                let file = files[i];
                formData.append('files[]', file);
            }
            
            axios.post(`/api/v1/safi/${resp.data.result._id.$oid}/upload_pago`, formData, {
              headers: {
                'Content-Type': 'multipart/form-data'
              }
            })
            .then(() => {
              setPaymentSelected('pagoexitoso');
              setLoading(false);
            })
            .catch((error) => {
              console.error('Error uploading files:', error);
              setPagoError(error.message);
              setPaymentSelected('pagoerror');
              setLoading(false);
              setButtonDisabled(false);
              setCountdown(0);
            });
          } else {
            // No files to upload, just set payment successful
            setPaymentSelected('pagoexitoso');
            setLoading(false);
          }
        })
        .catch((error) => {
          console.error('Error:', error);
          setPagoError(error.message);
          setPaymentSelected('pagoerror');
          setLoading(false);
          setButtonDisabled(false);
          setCountdown(0);
        });
    };

    // Handle duplicate warning responses
    const handleCancelDuplicatePayment = () => {
      setShowDuplicateWarning(false);
    };
    
    const handleConfirmDuplicatePayment = () => {
      setShowDuplicateWarning(false);
      
      // Proceed with payment process
      setButtonDisabled(true);
      setCountdown(5);
      
      const countdownInterval = setInterval(() => {
        setCountdown(prevCount => {
          if (prevCount <= 1) {
            clearInterval(countdownInterval);
            startPaymentProcess();
            return 0;
          }
          return prevCount - 1;
        });
      }, 1000);
    };

    const handlePrint = useReactToPrint({
      content: () => componentRef.current,
    });

    const handleReset = () => {
      // Reset component's internal state
      setPaymentSelected([]);
      setLoading(false);
      setFechaTransferencia('');
      setPagoError([]);
      setBancoEmisor('');
      setBancoReceptor('');
      setReferenciaTransferencia('');
      setNombreDepositante('');
      setNombreTarjetaHabiente('');
      setLibretaCobro('');
      setTipoTarjeta('');
      setUltimosDigitos('');
      setFechaVencimiento('');
      setSucursalEmisora('');
      setTipoPago('');
      setFile({});
      setFiles([]);
      setNumTransaccion(0);
      setMontoAPagar(0);
      setButtonDisabled(false);
      setCountdown(0);
      
      // Call parent's reset function if provided
      if (props.onReset) {
        props.onReset();
      }
    };

    const onSubmit = (values) => {
      console.log(values)
    }

    const [paymentParams, setPaymentParams] = useState(
      {
        "creditoID": 0
      }
    )

    // Common fields for all payment types
    const commonFields = (
      <>
        <div className="sm:col-span-1 pl-2">
          <dt className="text-left text-sm leading-5 font-medium text-gray-500">
            Monto a Pagar
          </dt>
          <dd className="text-left min-w-full min-h-full mt-1 text-sm leading-5 text-gray-900">
            <input 
              maxLength="14" 
              className="w-full h-10 pl-3 pr-8 text-base placeholder-gray-300 border border-gray-100 bg-gray-100 text-gray-700 rounded focus:shadow-outline" 
              type="text" 
              placeholder="Monto a Pagar" 
              name="montoPago" 
              onBlur={event => setMontoAPagar(event.target.value)}
            />
          </dd>
        </div>

        <div className="sm:col-span-1 pl-2">
          <dt className="text-left text-sm leading-5 font-medium text-gray-500">
            No. de Crédito
          </dt>
          <dd className="text-left min-w-full min-h-full mt-1 text-sm leading-5 text-gray-900">
            <input 
              maxLength="14" 
              className="w-full h-10 pl-3 pr-8 text-base placeholder-gray-300 border border-gray-100 bg-gray-100 text-gray-700 rounded focus:shadow-outline" 
              type="text" 
              placeholder="Numero de Crédito" 
              name="creditoID" 
              value={credito}
              readOnly
            />
          </dd>
        </div>

        <div className="sm:col-span-1 pl-2">
          <dt className="text-left text-sm leading-5 font-medium text-gray-500">
            Nombre del Cliente
          </dt>
          <dd className="text-left min-w-full min-h-full mt-1 text-sm leading-5 text-gray-900">
            <input 
              maxLength="14" 
              className="w-full h-10 pl-3 pr-8 text-base placeholder-gray-300 border border-gray-100 bg-gray-100 text-gray-700 rounded focus:shadow-outline" 
              type="text" 
              placeholder="Nombre Completo" 
              name="nombreCompleto" 
              value={cliente}
              readOnly
            />
          </dd>
        </div>

        <div className="sm:col-span-1 pl-2">
          <dt className="text-left text-sm leading-5 font-medium text-gray-500">
            Tipo de Pago
          </dt>
          <dd className="text-left min-w-full min-h-full mt-1 text-sm leading-5 text-gray-900">
            <select 
              className="form-input mt-1 form-select block w-full max-h-full min-h-full pl-3 pr-10 py-2 text-base leading-6 border-gray-300 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 sm:text-sm sm:leading-5"
              onChange={e => {
                setTipoPago(e.target.value);
                setPaymentSelected(e.target.value);
              }}
              value={tipoPago}
            >
              <option value="">Seleccionar</option>
              <option value="tdc">Pago por Tarjeta</option>
              <option value="efectivo">Deposito en Efectivo</option>
              <option value="cheque">Cheque</option>
              <option value="ach">ACH</option>
              <option value="multipago">MultiPago</option>
              <option value="puntopago">PuntoPago</option>
              <option value="arp">ARP Banistmo</option>
              <option value="cierre_comercial">Aplicación de cierre - comercial</option>
              <option value="cierre_admin">Aplicacion de cierre - admin</option>
            </select>
          </dd>
        </div>

        <div className="sm:col-span-1 pl-2">
          <dt className="text-left text-sm leading-5 font-medium text-gray-500">
            Fecha {tipoPago === 'transferencia' ? 'Transferencia' : tipoPago === 'tdc' ? 'Pago' : 'Pago'}
          </dt>
          <dd className="text-left min-w-full min-h-full mt-1 text-sm leading-5 text-gray-900">
            <DatePicker
              className="w-full h-10 pl-3 pr-8 text-base placeholder-gray-300 border border-gray-100 bg-gray-100 text-gray-700 rounded focus:shadow-outline"
              dateFormat="MM/dd/yyyy"
              onChange={(date) => setFechaTransferencia(date)}
              selected={fechaTransferencia}
              inputProps={{
                readOnly: true,
              }}
            />
          </dd>
        </div>
      </>
    );

    // Bank transfer specific fields
    const bankTransferFields = (tipoPago === 'efectivo' || tipoPago === 'cheque' || tipoPago === 'ach' || tipoPago === 'cierre_comercial' || tipoPago === 'cierre_admin') && (
      <>
        <div className="sm:col-span-1 pl-2">
          <dt className="text-left text-sm leading-5 font-medium text-gray-500">
            Banco Emisor
          </dt>
          <dd className="text-left min-w-full min-h-full mt-1 text-sm leading-5 text-gray-900">
            <select 
              className="form-input mt-1 form-select block w-full max-h-full min-h-full pl-3 pr-10 py-2 text-base leading-6 border-gray-300 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 sm:text-sm sm:leading-5"
              onChange={e => setBancoEmisor(e.target.value)} 
              value={bancoEmisor}
            >
              <option value="">Seleccionar</option>
              <option value="Sin Banco">Sin Banco</option>
              <option value="ASB Bank Corp.">ASB Bank Corp.</option>
              <option value="Atlas Bank (Panamá), S.A.">Atlas Bank (Panamá), S.A.</option>
              <option value="Austrobank Overseas (Panamá), S.A.">Austrobank Overseas (Panamá), S.A.</option>
              <option value="BAC International Bank, Inc.">BAC International Bank, Inc.</option>
              <option value="Banco Aliado, S.A.">Banco Aliado, S.A.</option>
              <option value="Banco Azteca (Panamá), S.A.">Banco Azteca (Panamá), S.A.</option>
              <option value="Banco Crédito del Perú">Banco Crédito del Perú</option>
              <option value="Banco Davivienda (Panamá), S.A">Banco Davivienda (Panamá), S.A</option>
              <option value="Banco Davivienda Internacional (Panamá), S.A.">Banco Davivienda Internacional (Panamá), S.A.</option>
              <option value="Banco de Bogotá, (Panamá), S.A.">Banco de Bogotá, (Panamá), S.A.</option>
              <option value="Banco de Bogotá, S.A.">Banco de Bogotá, S.A.</option>
              <option value="Banco de Occidente (Panamá), S.A.">Banco de Occidente (Panamá), S.A.</option>
              <option value="Banco Delta, S.A.">Banco Delta, S.A.</option>
              <option value="Banco Ficohsa (Panamá), S.A.">Banco Ficohsa (Panamá), S.A.</option>
              <option value="Banco General, S.A.">Banco General, S.A.</option>
              <option value="Banco Internacional de Costa Rica, S.A.">Banco Internacional de Costa Rica, S.A.</option>
              <option value="Banco Lafise Panamá S.A.">Banco Lafise Panamá S.A.</option>
              <option value="Banco La Hipotecaria, S.A.">Banco La Hipotecaria, S.A.</option>
              <option value="Banco Latinoamericano de Comercio Exterior,S.A.(BLADEX)">Banco Latinoamericano de Comercio Exterior,S.A.(BLADEX)</option>
              <option value="Banco Nacional de Panamá">Banco Nacional de Panamá</option>
              <option value="Banco Pichincha Panamá, S.A">Banco Pichincha Panamá, S.A</option>
              <option value="Banco Prival, S.A.">Banco Prival, S.A.</option>
              <option value="Bancolombia (Panamá), S.A.">Bancolombia (Panamá), S.A.</option>
              <option value="Bancolombia, S.A.">Bancolombia, S.A.</option>
              <option value="Banistmo, S.A.">Banistmo, S.A.</option>
              <option value="Banesco, (Panamá),S.A.">Banesco, (Panamá),S.A.</option>
              <option value="BANISI, S.A.">BANISI, S.A.</option>
              <option value="Bank of China Limited">Bank of China Limited</option>
              <option value="BBP Bank, S.A.">BBP Bank, S.A.</option>
              <option value="BCT Bank International">BCT Bank International</option>
              <option value="BHD International Bank (Panamá), S.A.">BHD International Bank (Panamá), S.A.</option>
              <option value="Bi-Bank, S.A.">Bi-Bank, S.A.</option>
              <option value="BPR BANK, S.A.">BPR BANK, S.A.</option>
              <option value="Canal Bank S.A.">Canal Bank S.A.</option>
              <option value="Caja de Ahorros">Caja de Ahorros</option>
              <option value="Citibank, N.A. Sucursal Panamá">Citibank, N.A. Sucursal Panamá</option>
              <option value="Coopeduc">Coopeduc</option>
              <option value="Credicorp Bank, S.A.">Credicorp Bank, S.A.</option>
              <option value="Global Bank Corporation">Global Bank Corporation</option>
              <option value="GNB Sudameris Bank, S.A.">GNB Sudameris Bank, S.A.</option>
              <option value="Industrial and Commercial Bank of China Limited">Industrial and Commercial Bank of China Limited</option>
              <option value="Inteligo Bank Ltd.">Inteligo Bank Ltd.</option>
              <option value="ITAU (Panamá) S.A.">ITAU (Panamá) S.A.</option>
              <option value="Keb Hana Bank">Keb Hana Bank</option>
              <option value="Mega International Commercial Bank CO.LTD">Mega International Commercial Bank CO.LTD</option>
              <option value="Mercantil Banco, S.A.">Mercantil Banco, S.A.</option>
              <option value="Metrobank, S.A.">Metrobank, S.A.</option>
              <option value="MMG Bank Corporation">MMG Bank Corporation</option>
              <option value="Multibank, Inc.">Multibank, Inc.</option>
              <option value="Pacific Bank, S.A.">Pacific Bank, S.A.</option>
              <option value="Popular Bank Ltd.Inc.">Popular Bank Ltd.Inc.</option>
              <option value="ST.Georges Bank & Company Inc.">ST.Georges Bank & Company Inc.</option>
              <option value="The Bank of Nova Scotia">The Bank of Nova Scotia</option>
              <option value="Towerbank International, Inc.">Towerbank International, Inc.</option>
              <option value="Unibank, S.A">Unibank, S.A</option>
            </select>
          </dd>
        </div>

        <div className="sm:col-span-1 pl-2">
          <dt className="text-left text-sm leading-5 font-medium text-gray-500">
            Banco Receptor
          </dt>
          <dd className="text-left min-w-full min-h-full mt-1 text-sm leading-5 text-gray-900">
            <select 
              className="form-input mt-1 form-select block w-full max-h-full min-h-full pl-3 pr-10 py-2 text-base leading-6 border-gray-300 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 sm:text-sm sm:leading-5"
              onChange={e => setBancoReceptor(e.target.value)} 
              value={bancoReceptor}
            >
              <option value="">Seleccionar</option>
              <option value="Sin Banco">Sin Banco</option>
              <option value="Banco Nacional de Panamá - 10000266755">Banco Nacional de Panamá - 10000266755</option>
              <option value="Banesco, S.A. (2) - 120000090319">Banesco, S.A. (2) - 120000090319</option>
              <option value="Banistmo, S.A. (1) - 114339105">Banistmo, S.A. (1) - 114339105</option>
              <option value="Banistmo, S.A. (1) - 117225764">Banistmo, S.A. (1) - 117225764</option>
            </select>
          </dd>
        </div>

        <div className="sm:col-span-1 pl-2">
          <dt className="text-left text-sm leading-5 font-medium text-gray-500">
            No. Referencia Transferencia
          </dt>
          <dd className="text-left min-w-full min-h-full mt-1 text-sm leading-5 text-gray-900">
            <input 
              maxLength="14" 
              className="w-full h-10 pl-3 pr-8 text-base placeholder-gray-300 border border-gray-100 bg-gray-100 text-gray-700 rounded focus:shadow-outline" 
              type="text" 
              placeholder="00000000000" 
              name="referenciaTransferencia"
              onChange={e => setReferenciaTransferencia(e.target.value)} 
              value={referenciaTransferencia}
            />
          </dd>
        </div>
      </>
    );

    // Card payment specific fields
    const cardPaymentFields = tipoPago === 'tdc' && (
      <>
        <div className="sm:col-span-1 pl-2">
          <dt className="text-left text-sm leading-5 font-medium text-gray-500">
            Tipo de Tarjeta
          </dt>
          <dd className="text-left min-w-full min-h-full mt-1 text-sm leading-5 text-gray-900">
            <select 
              className="form-input mt-1 form-select block w-full max-h-full min-h-full pl-3 pr-10 py-2 text-base leading-6 border-gray-300 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 sm:text-sm sm:leading-5"
              onChange={e => setTipoTarjeta(e.target.value)} 
              value={tipoTarjeta}
            >
              <option value="">Seleccionar</option>
              <option value="clave">Clave</option>
              <option value="visa">Visa</option>
              <option value="master">Master</option>
            </select>
          </dd>
        </div>

        <div className="sm:col-span-1 pl-2">
          <dt className="text-left text-sm leading-5 font-medium text-gray-500">
            4 últimos digitos de la tarjeta
          </dt>
          <dd className="text-left min-w-full min-h-full mt-1 text-sm leading-5 text-gray-900">
            <input 
              maxLength="4" 
              className="w-full h-10 pl-3 pr-8 text-base placeholder-gray-300 border border-gray-100 bg-gray-100 text-gray-700 rounded focus:shadow-outline" 
              type="text" 
              placeholder="4 últimos digitos" 
              name="ultimosDigitos"
              onChange={e => setUltimosDigitos(e.target.value)} 
              value={ultimosDigitos}
            />
          </dd>
        </div>

        <div className="sm:col-span-1 pl-2">
          <dt className="text-left text-sm leading-5 font-medium text-gray-500">
            Fecha de Vencimiento
          </dt>
          <dd className="text-left min-w-full min-h-full mt-1 text-sm leading-5 text-gray-900">
            <input 
              maxLength="5" 
              className="w-full h-10 pl-3 pr-8 text-base placeholder-gray-300 border border-gray-100 bg-gray-100 text-gray-700 rounded focus:shadow-outline" 
              type="text" 
              placeholder="MM/YY" 
              name="fechaVencimiento"
              onChange={e => setFechaVencimiento(e.target.value)} 
              value={fechaVencimiento}
            />
          </dd>
        </div>
      </>
    );

    // Other payment specific fields
    const otherPaymentFields = (tipoPago === 'multipago' || tipoPago === 'puntopago' || tipoPago === 'arp') && (
      <>
        <div className="sm:col-span-1 pl-2">
          <dt className="text-left text-sm leading-5 font-medium text-gray-500">
            Sucursal Emisora
          </dt>
          <dd className="text-left min-w-full min-h-full mt-1 text-sm leading-5 text-gray-900">
            <input 
              maxLength="50" 
              className="w-full h-10 pl-3 pr-8 text-base placeholder-gray-300 border border-gray-100 bg-gray-100 text-gray-700 rounded focus:shadow-outline" 
              type="text" 
              placeholder="Supermercado El Rey Calle 50" 
              name="sucursal"
              onChange={e => setSucursalEmisora(e.target.value)} 
              value={sucursalEmisora}
            />
          </dd>
        </div>

        <div className="sm:col-span-1 pl-2">
          <dt className="text-left text-sm leading-5 font-medium text-gray-500">
            No. Referencia
          </dt>
          <dd className="text-left min-w-full min-h-full mt-1 text-sm leading-5 text-gray-900">
            <input 
              maxLength="14" 
              className="w-full h-10 pl-3 pr-8 text-base placeholder-gray-300 border border-gray-100 bg-gray-100 text-gray-700 rounded focus:shadow-outline" 
              type="text" 
              placeholder="00000000000" 
              name="referenciaTransferencia"
              onChange={e => setReferenciaTransferencia(e.target.value)} 
              value={referenciaTransferencia}
            />
          </dd>
        </div>
      </>
    );

    // Common fields for all payment types
    const commonPaymentFields = (tipoPago === 'efectivo' || tipoPago === 'cheque' || tipoPago === 'ach' || tipoPago === 'cierre_comercial' || tipoPago === 'cierre_admin' || tipoPago === 'tdc' || tipoPago === 'multipago' || tipoPago === 'puntopago' || tipoPago === 'arp') && (
      <>
        <div className="sm:col-span-2 pl-2">
          <dt className="text-left text-sm leading-5 font-medium text-gray-500">
            Nombre y Apellido del {tipoPago === 'tdc' ? 'Tarjetahabiente' : 'Depositante'}
          </dt>
          <dd className="text-left min-w-full min-h-full mt-1 text-sm leading-5 text-gray-900">
            <input 
              className="w-full h-10 pl-3 pr-8 text-base placeholder-gray-300 border border-gray-100 bg-gray-100 text-gray-700 rounded focus:shadow-outline" 
              type="text" 
              placeholder={`Nombre y Apellido del ${tipoPago === 'tdc' ? 'Tarjetahabiente' : 'Depositante'}`}
              onChange={e => tipoPago === 'tdc' ? setNombreTarjetaHabiente(e.target.value) : setNombreDepositante(e.target.value)} 
              value={tipoPago === 'tdc' ? nombreTarjetaHabiente : nombreDepositante}
            />
          </dd>
        </div>

        <div className="sm:col-span-2 pl-2">
          <dt className="text-left text-sm leading-5 font-medium text-gray-500">
            No. recibo de libreta de cobro (Si aplica)
          </dt>
          <dd className="text-left min-w-full min-h-full mt-1 text-sm leading-5 text-gray-900">
            <input 
              maxLength="14" 
              className="w-full h-10 pl-3 pr-8 text-base placeholder-gray-300 border border-gray-100 bg-gray-100 text-gray-700 rounded focus:shadow-outline" 
              type="text" 
              placeholder="000000" 
              name="libretaCobro"
              onChange={e => setLibretaCobro(e.target.value)} 
              value={libretaCobro}
            />
          </dd>
        </div>

        {(tipoPago === 'efectivo' || tipoPago === 'cheque' || tipoPago === 'ach' || tipoPago === 'cierre_comercial' || tipoPago === 'cierre_admin') && (
          <div className="sm:col-span-4 pl-2">    
            <MyDropzoneForm setFiles={setFiles} />
          </div>
        )}

        <div className="sm:col-span-1 pl-2">
          <dd className="text-left min-w-full min-h-full mt-1 text-sm leading-5 text-gray-900">
            <button
              onClick={pagarCredito}
              disabled={buttonDisabled || !isFormValid(nombreTarjetaHabiente, nombreDepositante, montoAPagar, fechaTransferencia, referenciaTransferencia, ultimosDigitos)}
              className={`relative ml-2 h-10 right-0 flex items-center px-8 font-bold rounded ${
                !buttonDisabled && isFormValid(nombreTarjetaHabiente, nombreDepositante, montoAPagar, fechaTransferencia, referenciaTransferencia, ultimosDigitos)
                  ? "hover:text-white hover:bg-yellow-600 border-yellow-600 text-yellow-600"
                  : "border-gray-300 text-gray-300 cursor-not-allowed"
              } border`}
            >
              {buttonDisabled && countdown > 0 ? `Espere (${countdown})...` : "Pagar"}
            </button>
          </dd>
        </div>
      </>
    );

    // Create render part for the warning dialog
    const renderDuplicateWarning = () => {
      if (!showDuplicateWarning) return null;
      
      const lastPayment = getLastPayment();
      return (
        <div className="fixed inset-0 z-50 overflow-y-auto">
          <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div className="fixed inset-0 transition-opacity">
              <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
            </div>
            <DuplicatePaymentWarning 
              payment={lastPayment}
              onCancel={handleCancelDuplicatePayment}
              onConfirm={handleConfirmDuplicatePayment}
            />
          </div>
        </div>
      );
    };

    return (
      <div className={`${props.visual == true ? '' : 'hidden'}`}>
        <div className="px-4 py-5 border-b border-gray-200 sm:px-6">
          <div className="flex items-center">
            <h3 className="text-left text-xl leading-6 font-small text-gray-900">
              Pagar Crédito
            </h3>
            
            {paymentSelected === 'pagoexitoso' && (
              <>
                <button 
                  onClick={handlePrint} 
                  className="relative ml-2 h-10 right-0 flex items-center px-8 font-bold rounded bg-gray-600 border border-gray-600 text-white"
                >
                  Imprimir Recibo
                </button>
                <button 
                  onClick={handleReset} 
                  className="relative ml-2 h-10 right-0 flex items-center px-8 font-bold rounded bg-yellow-600 border border-yellow-600 text-white"
                >
                  Nuevo Pago
                </button>
              </>
            )}
          </div>
        </div>

        {loading ? (
          <div className="flex h-screen">
            <div className="m-auto">
              <Bars
                color="#00BFFF"
                height={100}
                width={100}
                timeout={30000}
              />
            </div>
          </div>
        ) : paymentSelected === 'pagoexitoso' ? (
          <div ref={componentRef} className="mx-6 my-6 px-4 py-5 sm:px-6 border-2 border-gray-300 ">
            <div className="header">
              <dl className="grid grid-cols-4 gap-6  sm:grid-cols-4 grid-rows-1 my-4">
                    <div>
                      <img className="ml-0 mx-auto h-20 w-auto" src="/images/suma-logo.svg" alt="Suma Financiera" />
                    </div>
                    <div >
                      <div>Fecha: {Moment().format('YYYY-MM-DD')}</div>
                      <div>Cliente: {props.creditsData[0]?.NombreCompleto}</div>
                      <div>Agencia: </div>
                    </div>
                    <div></div>
                    <div>
                      <div>No. Transaccion: {numtransaccion}</div>
                      <div>No. Crédito: {props.creditsData[0]?.CreditoID}</div>
                    </div>
              </dl> 
              <dl className="grid grid-cols-4 gap-6  sm:grid-cols-4 grid-rows-1 my-4">
                    <div>
                    </div>
                    <div >
                      <div></div>
                      <div></div>
                      <div></div>
                    </div>
                    <div></div>
                    <div>
                      <div></div>
                      <div></div>
                    </div>
              </dl> 
            </div>
            <div className="mt-8 content-3">
              <dl className="grid grid-cols-2 gap-6  sm:grid-cols-2 text-center">
                    <div >
                      <div>Monto Pagado: B./ {montoAPagar} </div>
                      <div>Pago efectuado por: {nombreDepositante}</div>
                    </div>
                    <div >
                      <div>Tipo de Pago: {tipoPago}</div>
                      <div>Libreta de Cobro: {libretaCobro} </div>
                    </div>
              </dl> 
              <dl className="grid grid-cols-4 gap-6  sm:grid-cols-4 grid-rows-1 my-4">
                    <div>
                    </div>
                    <div >
                      <div></div>
                      <div></div>
                      <div></div>
                    </div>
                    <div></div>
                    <div>
                      <div></div>
                      <div></div>
                    </div>
              </dl> 
            </div>
            <div className="mt-8 footer">
              <div className="header">
                <dl className="grid grid-cols-3 gap-8 text-xs sm:grid-cols-3 grid-rows-1 mt-4">
                      <div>
                        Comprobante emitido por SUMA Financiera S.A.<br /> 
                        R.U.C. 2456915-1-812999 D.V:42
                      </div>
                      <div></div>
                      <div className="text-right">
                        <div>No. Transaccion: {numtransaccion}</div>
                        <div>No. Crédito: {props.creditsData[0]?.CreditoID}</div>
                        <div>Forma de Pago: {tipoPago}</div>
                        <div>Total: B./ {montoAPagar}</div>
                      </div>
                </dl> 
                <dl className="grid grid-cols-3 gap-8 text-xs sm:grid-cols-3 grid-rows-1 mt-4">
                      <div>
                        
                      </div>
                      <div></div>
                      <div className="text-right">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                      </div>
                </dl> 
                <dl className="grid grid-cols-1 gap-8 text-xs sm:grid-cols-1 grid-rows-1 mt-4 text-center">
                  <div>
                    Sede Principal: Torre Kenex Plaza, nivel 700, Oficina 701, Av. Samuel Lewis con calle 59, Obarrio. Bella Vista. Provincia de Panama.<br />
                          República de Panamá, Telf 3083300. www.sumafinanciera.com
                  </div>
              
                </dl>
              </div>
            </div>
          </div>
        ) : paymentSelected === 'pagoerror' ? (
          <div class="rounded-md bg-yellow-50 p-4">
            <div class="flex">
              <div class="flex-shrink-0">
                <svg class="h-5 w-5 text-yellow-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                  <path fill-rule="evenodd" d="M8.485 2.495c.673-1.167 2.357-1.167 3.03 0l6.28 10.875c.673 1.167-.17 2.625-1.516 2.625H3.72c-1.347 0-2.189-1.458-1.515-2.625L8.485 2.495zM10 5a.75.75 0 01.75.75v3.5a.75.75 0 01-1.5 0v-3.5A.75.75 0 0110 5zm0 9a1 1 0 100-2 1 1 0 000 2z" clip-rule="evenodd" />
                </svg>
              </div>
              <div class="ml-3">
                <h3 class="text-sm font-medium text-yellow-800">Mensaje importante</h3>
                <div class="mt-2 text-sm text-yellow-700">
                  <p>{ pagoerror }</p>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <>
            {renderDuplicateWarning()}
            <div className="px-4 py-5 sm:px-6">
              <dl className="grid grid-cols-1 gap-y-4 gap-x-8 sm:grid-cols-4">
                {commonFields}
                {bankTransferFields}
                {cardPaymentFields}
                {otherPaymentFields}
                {commonPaymentFields}
              </dl>
            </div>
          </>
        )}
      </div>
    );
}

export default Pago;
