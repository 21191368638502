import React from 'react';
import Moment from 'moment';

/**
 * Reusable component for displaying and printing payment receipts
 * This maintains the exact same look and feel as the original receipt
 */
const PaymentReceipt = React.forwardRef(({ paymentData }, ref) => {
  // Extract payment data for easier access
  const {
    _id,
    amount,
    creditNumber,
    nombreCliente,
    nombreDepositante,
    tipoPago,
    libretaCobro,
    numTransaccion
  } = paymentData || {};

  return (
    <div ref={ref} className="mx-6 my-6 px-4 py-5 sm:px-6 border-2 border-gray-300">
      <div className="header">
        <dl className="grid grid-cols-4 gap-6 sm:grid-cols-4 grid-rows-1 my-4">
          <div>
            <img className="ml-0 mx-auto h-20 w-auto" src="/images/suma-logo.svg" alt="Suma Financiera" />
          </div>
          <div>
            <div>Fecha: {Moment().format('YYYY-MM-DD')}</div>
            <div>Cliente: {nombreCliente || 'N/A'}</div>
            <div>Agencia: </div>
          </div>
          <div></div>
          <div>
            <div>No. Transaccion: {numTransaccion || (_id && _id.$oid ? _id.$oid.slice(-6) : 'N/A')}</div>
            <div>No. Crédito: {creditNumber || 'N/A'}</div>
          </div>
        </dl>
        <dl className="grid grid-cols-4 gap-6 sm:grid-cols-4 grid-rows-1 my-4">
          <div></div>
          <div>
            <div></div>
            <div></div>
            <div></div>
          </div>
          <div></div>
          <div>
            <div></div>
            <div></div>
          </div>
        </dl>
      </div>
      <div className="mt-8 content-3">
        <dl className="grid grid-cols-2 gap-6 sm:grid-cols-2 text-center">
          <div>
            <div>Monto Pagado: B./ {amount || '0.00'} </div>
            <div>Pago efectuado por: {nombreDepositante || 'N/A'}</div>
          </div>
          <div>
            <div>Tipo de Pago: {tipoPago || 'N/A'}</div>
            <div>Libreta de Cobro: {libretaCobro || 'N/A'} </div>
          </div>
        </dl>
        <dl className="grid grid-cols-4 gap-6 sm:grid-cols-4 grid-rows-1 my-4">
          <div></div>
          <div>
            <div></div>
            <div></div>
            <div></div>
          </div>
          <div></div>
          <div>
            <div></div>
            <div></div>
          </div>
        </dl>
      </div>
      <div className="mt-8 footer">
        <div className="header">
          <dl className="grid grid-cols-3 gap-8 text-xs sm:grid-cols-3 grid-rows-1 mt-4">
            <div>
              Comprobante emitido por SUMA Financiera S.A.<br />
              R.U.C. 2456915-1-812999 D.V:42
            </div>
            <div></div>
            <div className="text-right">
              <div>No. Transaccion: {numTransaccion || (_id && _id.$oid ? _id.$oid.slice(-6) : 'N/A')}</div>
              <div>No. Crédito: {creditNumber || 'N/A'}</div>
              <div>Forma de Pago: {tipoPago || 'N/A'}</div>
              <div>Total: B./ {amount || '0.00'}</div>
            </div>
          </dl>
          <dl className="grid grid-cols-3 gap-8 text-xs sm:grid-cols-3 grid-rows-1 mt-4">
            <div></div>
            <div></div>
            <div className="text-right">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </dl>
          <dl className="grid grid-cols-1 gap-8 text-xs sm:grid-cols-1 grid-rows-1 mt-4 text-center">
            <div>
              Sede Principal: Torre Kenex Plaza, nivel 700, Oficina 701, Av. Samuel Lewis con calle 59, Obarrio. Bella Vista. Provincia de Panama.<br />
              República de Panamá, Telf 3083300. www.sumafinanciera.com
            </div>
          </dl>
        </div>
      </div>
    </div>
  );
});

export default PaymentReceipt; 