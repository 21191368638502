import React, { useEffect, useState, useRef, useMemo } from "react";
import Papa from "papaparse";
import { Bars } from "react-loader-spinner";
import axios from "axios";
import { grayClasses } from "../Design/classes"; // Adjust if needed
import * as XLSX from 'xlsx';

// Optional helper if you want 4-second delay per payment
// function sleep(ms) {
//   return new Promise((resolve) => setTimeout(resolve, ms));
// }

const PagoMasivo = (props) => {
  const [walletData, setWalletData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [checkedState, setCheckedState] = useState([]);
  const [isChecked, setIsChecked] = useState({});
  const [pagoError, setPagoError] = useState("");
  const [filterText, setFilterText] = useState("");
  const [showNotificationModal, setShowNotificationModal] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState('');
  const [notificationType, setNotificationType] = useState('success');
  const [processedTransactions, setProcessedTransactions] = useState(0);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [validationErrors, setValidationErrors] = useState([]);
  const [showValidationErrors, setShowValidationErrors] = useState(false);
  const [enforceDateRestriction, setEnforceDateRestriction] = useState(true); // Default to OFF

  const isMounted = useRef(true);

  // Calculate total amount from walletData
  const totalAmount = useMemo(() => {
    if (!walletData || !walletData.length) return 0;
    return walletData.reduce((sum, row) => sum + (parseFloat(row.amount) || 0), 0);
  }, [walletData]);

  // Calculate total of selected payments
  const selectedAmount = useMemo(() => {
    if (!checkedState.length) return 0;
    return checkedState.reduce((sum, indexStr) => {
      const idx = parseInt(indexStr, 10);
      return sum + (parseFloat(walletData[idx]?.amount) || 0);
    }, 0);
  }, [checkedState, walletData]);

  // Function to generate a template file with headers
  const generateTemplateFile = () => {
    // Define the headers for the template file using the client's requested column names
    const headers = [
      "Tipo de Pago",
      "Banco Receptor",
      "Fecha Pago",
      "No. Referencia Transferencia",
      "Nombre y Apellido del Depositante",
      "Monto Pago",
      "No. Crédito",
      "Nombre del Cliente",
      "ID de Cliente",
      "No. recibo de libreta de cobro"
    ];
    
    console.log('Template headers being generated:', headers);
    
    // Create a worksheet with just the headers
    const ws = XLSX.utils.aoa_to_sheet([headers]);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Plantilla Pagos");
    
    // Generate the file
    XLSX.writeFile(wb, "plantilla_pagos_masivos.xlsx");
  };

  // Map imported column names to internal field names
  const mapColumnNames = (data) => {
    // Debug: Log all available columns from the imported file
    if (data && data.length > 0) {
      console.log('Available columns in imported file:', Object.keys(data[0]));
    }
    
    return data.map(row => {
      // Format the date correctly (assuming it's in DD/MM/YYYY format)
      let formattedDate = row["Fecha Pago"];
      
      // Add debugging to see the original date format from the file
      console.log('Original date from file:', row["Fecha Pago"]);
      // Debug: Check if depositante field exists and its value
      console.log('Depositante field:', row["Nombre y Apellido del Depositante"]);
      
      // Create a new object with mapped field names
      return {
        tipoPago: row["Tipo de Pago"],
        bancoReceptor: row["Banco Receptor"],
        fechaTransferencia: formattedDate, // Keep the original date format
        referenciaTransferencia: row["No. Referencia Transferencia"],
        nombreDepositante: findDepositanteField(row),
        amount: row["Monto Pago"],
        creditNumber: row["No. Crédito"],
        nombreCliente: row["Nombre del Cliente"],
        customerNumber: row["ID de Cliente"],
        libretaCobro: row["No. recibo de libreta de cobro"],
        // Preserve any existing fields that might be present
        ...Object.keys(row).reduce((acc, key) => {
          if (!["Tipo de Pago", "Banco Receptor", "Fecha Pago", "No. Referencia Transferencia", 
               "Nombre y Apellido del Depositante", "Monto Pago", "No. Crédito", "Nombre del Cliente", 
               "ID de Cliente", "No. recibo de libreta de cobro"].includes(key)) {
            acc[key] = row[key];
          }
          return acc;
        }, {})
      };
    });
  };

  // Helper function to find the depositante field regardless of different column name variants
  const findDepositanteField = (row) => {
    // Try all possible variations of the column name
    const possibleNames = [
      "Nombre y Apellido del Depositante",
      "Nombre y Apellido Depositante", 
      "Nombre Depositante",
      "Depositante",
      "Nombre y Apellido"
    ];
    
    for (const name of possibleNames) {
      if (row[name] !== undefined && row[name] !== null) {
        console.log(`Found depositante under column name: "${name}" with value: "${row[name]}"`);
        return row[name];
      }
    }
    
    // If still not found, look for any column containing "depositante" (case insensitive)
    const depositanteKey = Object.keys(row).find(key => 
      key.toLowerCase().includes('depositante') || 
      key.toLowerCase().includes('deposito')
    );
    
    if (depositanteKey) {
      console.log(`Found depositante with fuzzy match: "${depositanteKey}" with value: "${row[depositanteKey]}"`);
      return row[depositanteKey];
    }
    
    console.log('Could not find depositante field in row:', Object.keys(row));
    return ""; // Default if not found
  };

  // 1) File Upload - now handles both CSV and XLSX
  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (!file) return;
    setLoading(true);

    const fileType = file.name.split('.').pop().toLowerCase();
    
    if (fileType === 'csv') {
      // Handle CSV files
      Papa.parse(file, {
        header: true,
        skipEmptyLines: true,
        complete: (results) => {
          if (isMounted.current) {
            // Map column names to internal field names
            const mappedData = mapColumnNames(results.data);
            setWalletData(mappedData);
            setLoading(false);
          }
        },
        error: (err) => {
          console.error("Error parsing CSV:", err);
          setLoading(false);
        },
      });
    } else if (fileType === 'xlsx' || fileType === 'xls') {
      // Handle XLSX files
      const reader = new FileReader();
      reader.onload = (e) => {
        try {
          const data = e.target.result;
          const workbook = XLSX.read(data, { type: 'array' });
          const firstSheet = workbook.Sheets[workbook.SheetNames[0]];
          
          console.log('Sheet names:', workbook.SheetNames);
          console.log('First sheet name:', workbook.SheetNames[0]);
          
          // Add dateNF option to format dates as strings in the format DD/MM/YY
          const jsonData = XLSX.utils.sheet_to_json(firstSheet, { 
            raw: false,
            dateNF: 'DD/MM/YY',
            defval: "" // Set default value for empty cells
          });
          
          console.log('Raw Excel data sample (first row):', jsonData.length > 0 ? jsonData[0] : 'No data');
          console.log('Excel header row has these columns:', jsonData.length > 0 ? Object.keys(jsonData[0]) : 'No data');
          
          if (isMounted.current) {
            // Map column names to internal field names
            const mappedData = mapColumnNames(jsonData);
            setWalletData(mappedData);
            setLoading(false);
          }
        } catch (error) {
          console.error("Error parsing Excel file:", error);
          setLoading(false);
          showTemporaryNotification(
            "Error al procesar el archivo Excel. Por favor, verifique el formato.",
            'error'
          );
        }
      };
      reader.readAsArrayBuffer(file);
    } else {
      setLoading(false);
      showTemporaryNotification(
        "Formato de archivo no soportado. Por favor, utilice archivos CSV o Excel (XLSX).",
        'error'
      );
    }
  };

  // 2) Handle row-level checkbox
  const handleCheck = (event) => {
    const { checked, value, name } = event.target;
    setIsChecked((prev) => ({ ...prev, [name]: checked }));

    if (checked) {
      setCheckedState((prev) => [...prev, value]);
    } else {
      setCheckedState((prev) => {
        const newState = [...prev];
        const idx = newState.indexOf(value);
        if (idx !== -1) {
          newState.splice(idx, 1);
        }
        return newState;
      });
    }
  };

  // 3) "Select All" button
  const selectAllRows = () => {
    // We'll convert each row index to a string
    const allIndexes = walletData.map((_, i) => i.toString());
    setCheckedState(allIndexes);

    // Build isChecked object
    const newIsChecked = {};
    for (let i = 0; i < walletData.length; i++) {
      newIsChecked[`pago${i}`] = true;
    }
    setIsChecked(newIsChecked);
  };

  // Validate transaction dates
  const validateTransactions = (transactions) => {
    const today = new Date();
    today.setHours(0, 0, 0, 0); // Set to beginning of day for comparison
    
    const validTransactions = [];
    const invalidTransactions = [];
    
    transactions.forEach(transaction => {
      // If date restriction is turned OFF, consider all transactions valid
      if (!enforceDateRestriction) {
        validTransactions.push(transaction);
        return;
      }
      
      // Otherwise, check if the transaction date is today
      try {
        const transactionDate = new Date(transaction.fechaTransferencia);
        transactionDate.setHours(0, 0, 0, 0);
        
        // Check if the transaction date is today
        if (transactionDate.getTime() === today.getTime()) {
          validTransactions.push(transaction);
        } else {
          invalidTransactions.push({
            ...transaction,
            errorReason: 'La fecha de transferencia debe ser igual a la fecha actual'
          });
        }
      } catch (error) {
        // If date parsing fails, handle based on enforceDateRestriction
        console.error("Error parsing date:", error);
        invalidTransactions.push({
          ...transaction,
          errorReason: 'Formato de fecha inválido'
        });
      }
    });
    
    return {
      validTransactions,
      invalidTransactions,
      hasInvalidTransactions: invalidTransactions.length > 0
    };
  };

  const showTemporaryNotification = (message, type = 'success', transactions = 0) => {
    setNotificationMessage(message);
    setNotificationType(type);
    setProcessedTransactions(transactions);
    setShowNotificationModal(true);
  };

  const handleConfirmPayments = async () => {
    setShowConfirmationModal(false);
    await processPagos();
  };

  const handleCancelPayments = () => {
    setShowConfirmationModal(false);
  };

  const processPagos = async () => {
    setLoading(true);
    setPagoError("");

    const token = document.querySelector('[name=csrf-token]')?.content;
    if (token) {
      axios.defaults.headers.common["X-CSRF-TOKEN"] = token;
    }

    // Get selected transactions
    const selectedTransactions = checkedState.map(indexStr => {
      const idx = parseInt(indexStr, 10);
      return walletData[idx];
    });

    // Validate transaction dates
    const validation = validateTransactions(selectedTransactions);
    
    if (validation.hasInvalidTransactions) {
      setValidationErrors(validation.invalidTransactions);
      setShowValidationErrors(true);
      setLoading(false);
      return;
    }

    const successIndices = [];
    let successCount = 0;

    try {
      // Loop over valid transactions
      for (const rowObj of validation.validTransactions) {
        const idx = checkedState.findIndex(indexStr => {
          const rowIndex = parseInt(indexStr, 10);
          return walletData[rowIndex] === rowObj;
        });

        if (idx === -1) {
          console.error(`Could not find row index for transaction`);
          continue;
        }

        try {
          // Build payload from rowObj - removed customerNumber
          const createData = {
            amount: rowObj.amount,
            creditNumber: rowObj.creditNumber,
            tipoPago: mapPaymentType(rowObj.tipoPago || 'Depósito en Efectivo'), // Ensure consistent payment type format
            referenciaTransferencia: rowObj.referenciaTransferencia,
            fechaTransferencia: rowObj.fechaTransferencia,
            bancoReceptor: rowObj.bancoReceptor,
            libretaCobro: rowObj.libretaCobro,
            nombreCliente: rowObj.nombreCliente,
            nombreDepositante: rowObj.nombreDepositante || "",
            conciliado: "false",
            esPagoMasivo: "true",
            customerNumber: rowObj.customerNumber || '' // Add customerNumber field
          };

          // Check for missing important fields and log warnings
          if (!createData.nombreDepositante) {
            console.warn('Warning: nombreDepositante is empty for payment: ', rowObj);
          }

          // Check if we need to extract customer number from credit number if not directly available
          if (!createData.customerNumber && rowObj.creditNumber) {
            // In many systems, the customer number is part of the credit number (first segments)
            // This is a common pattern - adjust the extraction logic based on your specific format
            const creditParts = rowObj.creditNumber.split('-');
            if (creditParts.length > 1) {
              createData.customerNumber = creditParts[0]; // Use first part of credit number as customer ID
            }
          }

          // Log the payment data for debugging
          console.log(`Saving payment with type: ${createData.tipoPago}, customer: ${createData.customerNumber}`);
          console.log('Full payment data being sent to API:', createData);

          await axios.post("/api/v1/safi/save_pago", createData);
          successIndices.push(parseInt(checkedState[idx], 10));
          successCount++;
        } catch (error) {
          console.error("Error creating pago:", error);
          setPagoError("Error al crear uno de los pagos.");
        }
      }

      // Remove the successfully created rows
      if (successIndices.length > 0) {
        const successSet = new Set(successIndices);
        const newWalletData = walletData.filter((_, rowIndex) => !successSet.has(rowIndex));
        setWalletData(newWalletData);
        
        showTemporaryNotification(
          `Proceso completado exitosamente. Se procesaron ${successCount} transacciones.`,
          'success',
          successCount
        );
      } else {
        showTemporaryNotification(
          "No se pudo procesar ninguna transacción. Por favor, intente nuevamente.",
          'error',
          0
        );
      }

      // Clear checkboxes
      setCheckedState([]);
      setIsChecked({});
    } catch (error) {
      console.error('Error general:', error);
      showTemporaryNotification(
        "Ocurrió un error durante el proceso. Por favor, intente nuevamente.",
        'error',
        successCount
      );
    } finally {
      setLoading(false);
    }
  };

  const ingresarPagos = () => {
    if (checkedState.length === 0) {
      showTemporaryNotification(
        "Por favor, seleccione al menos un pago para procesar.",
        'error'
      );
      return;
    }

    const selectedPayments = checkedState.map(indexStr => {
      const idx = parseInt(indexStr, 10);
      const payment = walletData[idx];
      return {
        creditNumber: payment.creditNumber,
        nombreCliente: payment.nombreCliente,
        customerNumber: payment.customerNumber || (payment.creditNumber && payment.creditNumber.includes('-') ? payment.creditNumber.split('-')[0] : ""),
        amount: payment.amount,
        tipoPago: payment.tipoPago,
        bancoReceptor: payment.bancoReceptor
      };
    });

    const totalAmount = selectedPayments.reduce((sum, payment) => sum + parseFloat(payment.amount || 0), 0);

    setShowConfirmationModal(true);
  };

  // Filter logic
  const includesFilterText = (text) =>
    String(text).toLowerCase().includes(filterText.toLowerCase());

  const getFilteredData = () => {
    return walletData.filter((row) => {
      return (
        // Remove usuario from filter
        // includesFilterText(row.usuario) ||
        includesFilterText(row.amount) ||
        includesFilterText(row.nombreCliente) ||
        includesFilterText(row.creditNumber) ||
        includesFilterText(row.customerNumber) ||
        includesFilterText(row.tipoPago) ||
        includesFilterText(row.bancoReceptor) ||
        includesFilterText(row.referenciaTransferencia) ||
        includesFilterText(row.libretaCobro) ||
        includesFilterText(row.nombreDepositante)
      );
    });
  };

  // Cleanup on unmount
  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  // Close validation errors modal
  const closeValidationErrors = () => {
    setShowValidationErrors(false);
  };

  // Payment type mapping function to normalize input values
  const mapPaymentType = (inputPaymentType) => {
    // Convert to string and trim to handle potential null/undefined values
    const paymentTypeStr = String(inputPaymentType || '').trim();
    
    // IMPORTANT: These mappings must match the validTransactionTypes array in WalletConciliaciones.js
    // If you add a new payment type here, make sure to add it to the validTransactionTypes array in WalletConciliaciones.js
    const paymentTypeMap = {
      // Map values with accents/special chars to standardized internal values
      'Depósito en Efectivo': 'Depósito en Efectivo',
      'Deposito en Efectivo': 'Depósito en Efectivo',
      'ACH': 'ACH',
      'ach': 'ACH', // Map lowercase to uppercase for consistency
      'Multipago': 'Multipago',
      'multipago': 'Multipago', // Map lowercase to uppercase for consistency
      'PuntoPago': 'PuntoPago',
      'puntopago': 'PuntoPago', // Map lowercase to uppercase for consistency
      'ARP Banistmo': 'ARP Banistmo',
      'arp': 'ARP Banistmo', // Map lowercase to full name
      'Aplicación de cierre - comercial': 'Aplicación de cierre - comercial',
      'Aplicacion de cierre - comercial': 'Aplicación de cierre - comercial',
      'cierre_comercial': 'Aplicación de cierre - comercial', // Map underscore version to dash version
      'Aplicación de cierre - admin': 'Aplicación de cierre - admin',
      'Aplicacion de cierre - admin': 'Aplicación de cierre - admin',
      'cierre_admin': 'Aplicación de cierre - admin' // Map underscore version to dash version
    };
    
    // Return the mapped value or the original if no mapping exists
    return paymentTypeMap[paymentTypeStr] || paymentTypeStr;
  };

  return (
    <>
      {/* Confirmation Modal */}
      {showConfirmationModal && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full z-50">
          <div className="relative top-20 mx-auto p-5 border w-4/5 max-w-2xl shadow-lg rounded-md bg-white">
            <div className="mt-3">
              <div className="flex items-center justify-center flex-col">
                <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-blue-100">
                  <svg className="h-6 w-6 text-blue-600" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"/>
                  </svg>
                </div>
                <h3 className="text-lg leading-6 font-medium text-gray-900 mt-4 text-center">
                  Confirmación de Pagos
                </h3>
                <div className="mt-2 px-7 py-3">
                  <p className="text-sm text-gray-500 mb-4">
                    Va a registrar {checkedState.length} {checkedState.length === 1 ? 'pago' : 'pagos'}.
                    Monto total: {new Intl.NumberFormat("en-US", {
                      style: "currency",
                      currency: "USD"
                    }).format(checkedState.reduce((sum, indexStr) => {
                      const idx = parseInt(indexStr, 10);
                      return sum + parseFloat(walletData[idx]?.amount || 0);
                    }, 0))}
                  </p>
                  <div className="max-h-60 overflow-y-auto">
                    <p className="text-sm font-medium text-gray-900 mb-2">Los pagos son los siguientes:</p>
                    {checkedState.map((indexStr, i) => {
                      const idx = parseInt(indexStr, 10);
                      const payment = walletData[idx];
                      return (
                        <div key={i} className="mb-2 p-2 bg-gray-50 rounded">
                          <p className="text-sm text-gray-600">
                            <span className="font-medium">Cliente:</span> {payment.nombreCliente}<br />
                            <span className="font-medium">ID de Cliente:</span> {payment.customerNumber || (payment.creditNumber && payment.creditNumber.includes('-') ? payment.creditNumber.split('-')[0] : "")}<br />
                            <span className="font-medium">Crédito:</span> {payment.creditNumber}<br />
                            <span className="font-medium">Monto:</span> {new Intl.NumberFormat("en-US", {
                              style: "currency",
                              currency: "USD"
                            }).format(payment.amount)}<br />
                            <span className="font-medium">Tipo de Pago:</span> {payment.tipoPago}<br />
                            <span className="font-medium">Banco Receptor:</span> {payment.bancoReceptor}
                            {payment.nombreDepositante && (
                              <><br /><span className="font-medium">Depositante:</span> {payment.nombreDepositante}</>
                            )}
                          </p>
                        </div>
                      );
                    })}
                  </div>
                </div>
                <div className="flex justify-center gap-4 mt-4">
                  <button
                    onClick={handleCancelPayments}
                    className="px-4 py-2 bg-gray-300 text-gray-700 text-base font-medium rounded-md shadow-sm hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-gray-500"
                  >
                    Cancelar
                  </button>
                  <button
                    onClick={handleConfirmPayments}
                    className="px-4 py-2 bg-blue-600 text-white text-base font-medium rounded-md shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
                  >
                    Confirmar
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Notification Modal */}
      {showNotificationModal && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full z-50 flex items-center justify-center">
          <div className="relative mx-auto p-5 border w-96 shadow-lg rounded-md bg-white">
            <div className="mt-3">
              <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-white">
                {notificationType === 'success' ? (
                  <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
                    <svg className="h-6 w-6 text-green-600" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"/>
                    </svg>
                  </div>
                ) : (
                  <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-red-100">
                    <svg className="h-6 w-6 text-red-600" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"/>
                    </svg>
                  </div>
                )}
              </div>
              <h3 className={`text-lg leading-6 font-medium ${
                notificationType === 'success' ? 'text-green-900' : 'text-red-900'
              } mt-2 text-center`}>
                {notificationType === 'success' ? 'Éxito!' : 'Error'}
              </h3>
              <div className="mt-2 px-7 py-3">
                <p className="text-sm text-gray-500 text-center">
                  {notificationMessage}
                </p>
                {notificationType === 'success' && processedTransactions > 0 && (
                  <p className="text-sm text-gray-500 text-center mt-2">
                    Total de transacciones procesadas: {processedTransactions}
                  </p>
                )}
              </div>
              <div className="flex justify-center mt-4">
                <button
                  onClick={() => setShowNotificationModal(false)}
                  className={`px-4 py-2 ${
                    notificationType === 'success' 
                      ? 'bg-green-600 hover:bg-green-700' 
                      : 'bg-red-600 hover:bg-red-700'
                  } text-white text-base font-medium rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 ${
                    notificationType === 'success' ? 'focus:ring-green-500' : 'focus:ring-red-500'
                  }`}
                >
                  OK
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Validation Errors Modal */}
      {showValidationErrors && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full z-50 flex items-center justify-center">
          <div className="relative mx-auto p-5 border w-11/12 max-w-4xl shadow-lg rounded-md bg-white">
            <div className="mt-3">
              <div className="flex items-center justify-center flex-col">
                <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-red-100">
                  <svg className="h-6 w-6 text-red-600" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"/>
                  </svg>
                </div>
                <h3 className="text-lg leading-6 font-medium text-gray-900 mt-4 text-center">
                  Errores de Validación
                </h3>
                <div className="mt-2 px-7 py-3">
                  <p className="text-sm text-gray-500 mb-4">
                    Se encontraron {validationErrors.length} transacciones con errores de fecha:
                  </p>
                  <div className="max-h-96 overflow-y-auto">
                    <table className="min-w-full divide-y divide-gray-200">
                      <thead className="bg-gray-50">
                        <tr>
                          <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider w-1/8">
                            Crédito
                          </th>
                          <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider w-1/4">
                            Cliente
                          </th>
                          <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider w-1/8">
                            Fecha
                          </th>
                          <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider w-1/2">
                            Error
                          </th>
                        </tr>
                      </thead>
                      <tbody className="bg-white divide-y divide-gray-200">
                        {validationErrors.map((error, index) => (
                          <tr key={index}>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                              {error.creditNumber}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                              {error.nombreCliente}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                              {error.fechaTransferencia}
                            </td>
                            <td className="px-6 py-4 text-sm text-red-500">
                              {error.errorReason}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="flex justify-center mt-4">
                  <button
                    onClick={closeValidationErrors}
                    className="px-4 py-2 bg-red-600 text-white text-base font-medium rounded-md shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                  >
                    Entendido
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {loading ? (
        <div className="flex h-screen">
          <div className="m-auto">
            <Bars color="#00BFFF" height={100} width={100} timeout={30000} />
          </div>
        </div>
      ) : (
        <div className="overflow-y-auto">
          {/* UPLOAD FILE */}
          <div className="p-5 flex justify-between items-center">
            <div className="flex flex-col sm:flex-row gap-x-2 items-baseline">
              <label className="flex flex-col sm:flex-row gap-x-2 items-baseline">
                <span className="text-gray-700 mb-2 sm:mb-0">Agregar Archivo: </span>
                <input
                  type="file"
                  accept=".csv,.xlsx,.xls"
                  onChange={handleFileUpload}
                  className="mb-2 sm:mb-0"
                />
              </label>
              
              {/* Date Restriction Toggle */}
              
            </div>
            
            {walletData.length > 0 && (
              <div className="text-right">
                <div className="mb-2">
                  <p className="text-sm text-gray-600">Total del archivo:</p>
                  <p className="text-lg font-semibold text-gray-800">
                    {new Intl.NumberFormat("en-US", {
                      style: "currency",
                      currency: "USD"
                    }).format(totalAmount)}
                  </p>
                </div>
                <div>
                  <p className="text-sm text-gray-600">Total seleccionado:</p>
                  <p className="text-lg font-semibold text-blue-600">
                    {new Intl.NumberFormat("en-US", {
                      style: "currency",
                      currency: "USD"
                    }).format(selectedAmount)}
                  </p>
                </div>
              </div>
            )}
          </div>

          {/* FILTER */}
          <div className="p-5 sm:flex sm:gap-x-2 sm:justify-between">
            <label className="flex gap-x-2 items-baseline">
              <span className="text-gray-700">Buscar: </span>
              <input
                type="text"
                placeholder="Filtrar resultados"
                value={filterText}
                onChange={(e) => setFilterText(e.target.value)}
                className="rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
              />
            </label>
          </div>

          {/* TABLE */}
          <div className="px-7 py-5 flex flex-wrap -mx-5 mb-2">
            <div className="ml-2 mt-2 w-full mr-2">
              <div className="align-middle inline-block min-w-full shadow overflow-hidden sm:rounded-lg border-b border-gray-200">
                <table className="min-w-full">
                  <thead className="bg-gray-50">
                    <tr>
                      {/* 1) Single row checkbox column */}
                      <th className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase">
                        {/* Empty header for the row-level checkboxes */}
                      </th>
                      {/* 2) ID Transacción / Usuario */}
                      <th className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase">
                        ID Transacción
                      </th>
                      {/* 3) Número de Referencia */}
                      <th className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase">
                        Número de Referencia
                      </th>
                      {/* 4) Fecha de Transferencia */}
                      <th className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase">
                        Fecha de Transferencia
                      </th>
                      {/* 5) Tipo de Pago */}
                      <th className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase">
                        Tipo de Pago
                      </th>
                      {/* 6) No. de Crédito */}
                      <th className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase">
                        No. de Crédito
                      </th>
                      {/* 7) No. de Cliente */}
                      <th className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase">
                        No. de Cliente
                      </th>
                      {/* 8) Nombre de Cliente */}
                      <th className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase">
                        Nombre de Cliente
                      </th>
                      {/* 9) Depositante */}
                      <th className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase">
                        Nombre y Apellido del Depositante
                      </th>
                      {/* 10) Banco Receptor */}
                      <th className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase">
                        Banco Receptor
                      </th>
                      {/* 11) Monto Pagado */}
                      <th className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase">
                        Monto Pagado
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {getFilteredData().map((row, i) => {
                      const currentGrayClass = grayClasses[i % grayClasses.length];

                      // Construct shortened ID if row._id is present
                      const shortenedID = row._id
                        ? String(row._id).slice(-5)
                        : "NoID";

                      return (
                        <tr key={i} className={`${currentGrayClass} border-b border-gray-200`}>
                          {/* 1) Row-level checkbox */}
                          <td className="px-6 py-3 text-center">
                            <input
                              type="checkbox"
                              name={`pago${i}`}
                              value={i}
                              onChange={handleCheck}
                              checked={isChecked[`pago${i}`] || false}
                            />
                          </td>

                          {/* 2) ID Transacción / Usuario */}
                          <td className="px-6 py-3 text-sm text-gray-500 text-center">
                            {`PagoID: ${shortenedID}`}
                          </td>

                          {/* 3) Número de Referencia */}
                          <td className="px-6 py-3 text-sm text-gray-500 text-center">
                            {row.referenciaTransferencia}
                          </td>

                          {/* 4) Fecha de Transferencia */}
                          <td className="px-6 py-3 text-sm text-gray-500 text-center">
                            {row.fechaTransferencia || ""}
                          </td>

                          {/* 5) Tipo de Pago */}
                          <td className="px-6 py-3 text-sm text-gray-500 text-center">
                            {mapPaymentType(row.tipoPago)}
                          </td>

                          {/* 6) No. de Crédito */}
                          <td className="px-6 py-3 text-sm text-gray-500 text-center">
                            {row.creditNumber}
                          </td>
                          
                          {/* 7) No. de Cliente */}
                          <td className="px-6 py-3 text-sm text-gray-500 text-center">
                            {row.customerNumber || (row.creditNumber && row.creditNumber.includes('-') ? row.creditNumber.split('-')[0] : "")}
                          </td>

                          {/* 8) Nombre de Cliente */}
                          <td className="px-6 py-3 text-sm text-gray-500 text-center">
                            {row.nombreCliente}
                          </td>

                          {/* 9) Depositante */}
                          <td className="px-6 py-3 text-sm text-gray-500 text-center">
                            {row.nombreDepositante || "N/A"}
                          </td>

                          {/* 10) Banco Receptor */}
                          <td className="px-6 py-3 text-sm text-gray-500 text-center">
                            {row.bancoReceptor}
                          </td>

                          {/* 11) Monto Pagado */}
                          <td className="px-6 py-3 text-sm text-gray-500 text-center">
                            {row.amount
                              ? new Intl.NumberFormat("en-US", {
                                  style: "currency",
                                  currency: "USD",
                                }).format(row.amount)
                              : "$0.00"}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          {pagoError && (
            <div className="text-red-700 font-semibold ml-5 mb-4">{pagoError}</div>
          )}

          {/* ACTION BUTTONS */}
          <div className="mx-6 mb-6 flex justify-between">
            {/* Template Download button (Left aligned) */}
            <div>
              <a 
                href="https://sumafile.s3.us-east-1.amazonaws.com/pagos_imports.xlsx" 
                target="_blank" 
                rel="noopener noreferrer"
                className="bg-gray-600 hover:bg-gray-700 text-white font-bold py-2 px-6 rounded inline-flex items-center"
              >
                <svg className="fill-current w-4 h-4 mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                  <path d="M13 8V2H7v6H2l8 8 8-8h-5zM0 18h20v2H0v-2z"/>
                </svg>
                <span>Descargar Plantilla</span>
              </a>
            </div>
            
            {/* Right aligned buttons */}
            <div>
              {/* "Select All" button (Green) */}
              <button
                onClick={selectAllRows}
                className="bg-green-600 hover:bg-green-700 text-white font-bold py-2 px-6 rounded mr-4"
              >
                Seleccionar Todos
              </button>

              {/* "Ingresar Pagos" button (Blue) */}
              <button
                onClick={ingresarPagos}
                className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-6 rounded"
              >
                Ingresar Pagos
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default PagoMasivo;
