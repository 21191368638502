import React, { createContext, useState, useContext } from 'react';

// Create the context
const PaymentContext = createContext();

// Create a custom hook to use the context
export const usePaymentContext = () => useContext(PaymentContext);

// Time window in milliseconds to consider a payment as a potential duplicate
const DUPLICATE_WINDOW_MS = 5 * 60 * 1000; // 5 minutes

export const PaymentContextProvider = ({ children }) => {
  // Store only the last payment
  const [lastPayment, setLastPayment] = useState(null);
  
  // Record a new payment
  const recordPayment = (payment) => {
    const timestamp = new Date().getTime();
    setLastPayment({
      ...payment,
      timestamp
    });
  };
  
  // Check if a payment is potentially a duplicate
  const checkForDuplicate = (newPayment) => {
    if (!lastPayment) return false;
    
    const now = new Date().getTime();
    const timeDiff = now - lastPayment.timestamp;
    
    // Only consider payments within the time window
    if (timeDiff > DUPLICATE_WINDOW_MS) return false;
    
    // Check key fields that would identify a duplicate
    const isSameCredit = lastPayment.creditoID === newPayment.creditoID;
    const isSameAmount = parseFloat(lastPayment.monto) === parseFloat(newPayment.monto);
    const isSameType = lastPayment.tipoPago === newPayment.tipoPago;
    
    console.log("Checking for duplicate payment:", { 
      lastPayment, 
      newPayment, 
      isSameCredit, 
      isSameAmount, 
      isSameType 
    });
    
    // Consider it a duplicate if all key fields match
    return isSameCredit && isSameAmount && isSameType;
  };
  
  // Get details of the last payment for display
  const getLastPayment = () => {
    return lastPayment;
  };
  
  // Clear the last payment (if needed)
  const clearLastPayment = () => {
    setLastPayment(null);
  };
  
  return (
    <PaymentContext.Provider
      value={{
        recordPayment,
        checkForDuplicate,
        getLastPayment,
        clearLastPayment
      }}
    >
      {children}
    </PaymentContext.Provider>
  );
}; 