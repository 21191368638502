import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Bars } from 'react-loader-spinner';
import { formatDate } from '../Helpers/Helpers';

const RefinanciamientosTable = () => {
  const [credit, setCredit] = useState('');
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [filterText, setFilterText] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(50);
  const [totalItems, setTotalItems] = useState(0);
  const [visual, setVisual] = useState(false);
  const [sucursales, setSucursales] = useState([]);
  const [selectedSucursal, setSelectedSucursal] = useState('');
  const [nombreCliente, setNombreCliente] = useState('');
  
  // New state for range filters
  const [porcentajeMin, setPorcentajeMin] = useState('');
  const [porcentajeMax, setPorcentajeMax] = useState('');
  const [diasMoraMin, setDiasMoraMin] = useState('');
  const [diasMoraMax, setDiasMoraMax] = useState('');

  // Add new state variables
  const [capitalMin, setCapitalMin] = useState('');
  const [capitalMax, setCapitalMax] = useState('');
  const [desembolsoMin, setDesembolsoMin] = useState('');
  const [desembolsoMax, setDesembolsoMax] = useState('');

  // Add these state variables
  const [sortField, setSortField] = useState('InsertDate'); // Default sort by insert date
  const [sortDirection, setSortDirection] = useState('DESC'); // Default sort direction

  // Load data and sucursales when component mounts
  useEffect(() => {
    setLoading(true);
    
    // Load both data and sucursales in parallel
    Promise.all([
      loadData(),
      loadAllSucursales()
    ]).catch(err => {
      console.error("Failed to load initial data:", err);
      setError("Error loading data. Please try again.");
      setLoading(false);
    });
  }, [currentPage]); // Re-fetch when page changes

  // New function to load all sucursales
  const loadAllSucursales = async () => {
    try {
      const response = await axios.get('/api/v1/refinanciamientos/sucursales.json');
      if (response.data && response.data.sucursales) {
        setSucursales(response.data.sucursales);
      }
    } catch (error) {
      console.error('Error loading sucursales:', error);
      // Don't set error state here to avoid blocking the main data load
    }
  };

  const handleCreditChange = (e) => {
    setCredit(e.target.value);
  };

  const handleSucursalChange = (e) => {
    setSelectedSucursal(e.target.value);
  };

  const handleNombreClienteChange = (e) => {
    setNombreCliente(e.target.value);
  };
  
  // Handlers for range filters
  const handlePorcentajeMinChange = (e) => {
    setPorcentajeMin(e.target.value);
  };
  
  const handlePorcentajeMaxChange = (e) => {
    setPorcentajeMax(e.target.value);
  };
  
  const handleDiasMoraMinChange = (e) => {
    setDiasMoraMin(e.target.value);
  };
  
  const handleDiasMoraMaxChange = (e) => {
    setDiasMoraMax(e.target.value);
  };

  // Add handlers for the new inputs
  const handleCapitalMinChange = (e) => {
    setCapitalMin(e.target.value);
  };

  const handleCapitalMaxChange = (e) => {
    setCapitalMax(e.target.value);
  };

  const handleDesembolsoMinChange = (e) => {
    setDesembolsoMin(e.target.value);
  };

  const handleDesembolsoMaxChange = (e) => {
    setDesembolsoMax(e.target.value);
  };

  // Add a function to handle column header clicks for sorting
  const handleSort = (field) => {
    // If clicking the same field, toggle direction
    if (field === sortField) {
      setSortDirection(sortDirection === 'ASC' ? 'DESC' : 'ASC');
    } else {
      // If clicking a new field, set it as sort field and default to DESC
      setSortField(field);
      setSortDirection('DESC');
    }
    
    // Reset to first page when sorting changes
    setCurrentPage(1);
    
    // Reload data with new sort parameters
    loadData();
  };

  // Update loadData to include sort parameters
  const loadData = async () => {
    setLoading(true);
    setError(null);
    
    try {
      console.log('Fetching refinanciamientos data for credit:', credit || 'all credits', 'page:', currentPage);
      
      // Get data with pagination and sort parameters
      const response = await axios.get(`/api/v1/refinanciamientos.json`, {
        params: {
          creditoid: credit || null,
          sucursal: selectedSucursal || null,
          nombre_cliente: nombreCliente || null,
          porcentaje_min: porcentajeMin && parseFloat(porcentajeMin) > 0 ? porcentajeMin : null,
          porcentaje_max: porcentajeMax && parseFloat(porcentajeMax) > 0 ? porcentajeMax : null,
          dias_mora_min: diasMoraMin && parseFloat(diasMoraMin) > 0 ? diasMoraMin : null,
          dias_mora_max: diasMoraMax && parseFloat(diasMoraMax) > 0 ? diasMoraMax : null,
          capital_min: capitalMin && parseFloat(capitalMin) > 0 ? capitalMin : null,
          capital_max: capitalMax && parseFloat(capitalMax) > 0 ? capitalMax : null,
          desembolso_min: desembolsoMin && parseFloat(desembolsoMin) > 0 ? desembolsoMin : null,
          desembolso_max: desembolsoMax && parseFloat(desembolsoMax) > 0 ? desembolsoMax : null,
          sort_field: sortField,
          sort_direction: sortDirection,
          page: currentPage,
          per_page: itemsPerPage
        }
      });
      
      console.log('API response:', response.data);
      
      if (!response.data || !response.data.items) {
        throw new Error('Invalid API response format');
      }
      
      setData(response.data.items || []);
      setTotalItems(response.data.total_count || 0);
      setVisual(true);
      setLoading(false);
    } catch (error) {
      console.error('Error loading data:', error);
      setError(`Error: ${error.message || 'Failed to load data'}`);
      setLoading(false);
      
      // Set empty data to prevent UI issues
      setData([]);
      setTotalItems(0);
    }
  };

  const handleSearch = () => {
    setCurrentPage(1); // Reset to first page when searching
    loadData().catch(err => {
      console.error("Search failed:", err);
      setError("Error searching. Please try again.");
    });
  };

  const handleReset = () => {
    setCredit('');
    setSelectedSucursal('');
    setNombreCliente('');
    setPorcentajeMin('');
    setPorcentajeMax('');
    setDiasMoraMin('');
    setDiasMoraMax('');
    setCapitalMin('');
    setCapitalMax('');
    setDesembolsoMin('');
    setDesembolsoMax('');
    setFilterText('');
    setCurrentPage(1);
    loadData();
  };

  // Filter data based on search text (client-side filtering)
  const filteredData = data.filter(item => {
    if (!filterText) return true;
    
    const searchText = filterText.toLowerCase();
    return (
      (item.CreditoID?.toString().toLowerCase().includes(searchText)) ||
      (item.ClienteID?.toString().toLowerCase().includes(searchText)) ||
      (item.NombreCompleto?.toLowerCase().includes(searchText)) ||
      (item.Sucursal?.toLowerCase().includes(searchText)) ||
      (item.NombreGestor?.toLowerCase().includes(searchText))
    );
  });

  // Calculate total pages
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  // Format currency
  const formatCurrency = (value) => {
    if (value === null || value === undefined) return 'N/A';
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD"
    }).format(value);
  };

  // Add a new function to handle export
  const handleExport = (format) => {
    // Build query parameters based on current filters
    const queryParams = new URLSearchParams();
    if (credit) queryParams.append('creditoid', credit);
    if (selectedSucursal) queryParams.append('sucursal', selectedSucursal);
    if (nombreCliente) queryParams.append('nombre_cliente', nombreCliente);
    
    if (porcentajeMin && parseFloat(porcentajeMin) > 0) 
      queryParams.append('porcentaje_min', porcentajeMin);
    if (porcentajeMax && parseFloat(porcentajeMax) > 0) 
      queryParams.append('porcentaje_max', porcentajeMax);
    if (diasMoraMin && parseFloat(diasMoraMin) > 0) 
      queryParams.append('dias_mora_min', diasMoraMin);
    if (diasMoraMax && parseFloat(diasMoraMax) > 0) 
      queryParams.append('dias_mora_max', diasMoraMax);
    if (capitalMin && parseFloat(capitalMin) > 0) 
      queryParams.append('capital_min', capitalMin);
    if (capitalMax && parseFloat(capitalMax) > 0) 
      queryParams.append('capital_max', capitalMax);
    if (desembolsoMin && parseFloat(desembolsoMin) > 0) 
      queryParams.append('desembolso_min', desembolsoMin);
    if (desembolsoMax && parseFloat(desembolsoMax) > 0) 
      queryParams.append('desembolso_max', desembolsoMax);
    
    // Add format parameter
    queryParams.append('format', format);
    
    // Create the URL
    const exportUrl = `/api/v1/refinanciamientos/export.${format}?${queryParams.toString()}`;
    
    // Trigger download by opening the URL
    window.open(exportUrl, '_blank');
  };

  return (
    <div className="overflow-y-auto">
      {/* Search Form */}
      <div className="mx-6 mt-6 bg-white shadow overflow-hidden sm:rounded-lg">
        <div className="p-5">
          <h3 className="text-lg font-medium text-gray-900 mb-4">Filtros</h3>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
            <div>
              <label htmlFor="nombreCliente" className="block text-sm font-medium text-gray-700">
                Nombre del Cliente
              </label>
              <input
                type="text"
                id="nombreCliente"
                value={nombreCliente}
                onChange={handleNombreClienteChange}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                placeholder="Buscar por nombre"
              />
            </div>
            
            <div>
              <label htmlFor="credit" className="block text-sm font-medium text-gray-700">
                ID de Crédito
              </label>
              <input
                type="text"
                id="credit"
                value={credit}
                onChange={handleCreditChange}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                placeholder="Ingrese ID de crédito"
              />
            </div>
            
            <div>
              <label htmlFor="sucursal" className="block text-sm font-medium text-gray-700">
                Sucursal
              </label>
              <select
                id="sucursal"
                value={selectedSucursal}
                onChange={handleSucursalChange}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
              >
                <option value="">Todas las sucursales</option>
                {sucursales.map((sucursal, index) => (
                  <option key={index} value={sucursal}>
                    {sucursal}
                  </option>
                ))}
              </select>
            </div>
            
            {/* Porcentaje Recorrido Range Filter */}
            <div>
              <label className="block text-sm font-medium text-gray-700">
                % Recorrido
              </label>
              <div className="grid grid-cols-2 gap-2">
                <div>
                  <input
                    type="number"
                    min="0"
                    max="100"
                    value={porcentajeMin}
                    onChange={handlePorcentajeMinChange}
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                    placeholder="Mínimo"
                  />
                </div>
                <div>
                  <input
                    type="number"
                    min="0"
                    max="100"
                    value={porcentajeMax}
                    onChange={handlePorcentajeMaxChange}
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                    placeholder="Máximo"
                  />
                </div>
              </div>
            </div>
            
            {/* Promedio Días Mora Range Filter */}
            <div>
              <label className="block text-sm font-medium text-gray-700">
                Promedio Días Mora
              </label>
              <div className="grid grid-cols-2 gap-2">
                <div>
                  <input
                    type="number"
                    min="0"
                    value={diasMoraMin}
                    onChange={handleDiasMoraMinChange}
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                    placeholder="Mínimo"
                  />
                </div>
                <div>
                  <input
                    type="number"
                    min="0"
                    value={diasMoraMax}
                    onChange={handleDiasMoraMaxChange}
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                    placeholder="Máximo"
                  />
                </div>
              </div>
            </div>
            
            {/* Capital Actual Range Filter */}
            <div>
              <label className="block text-sm font-medium text-gray-700">
                Capital Actual
              </label>
              <div className="grid grid-cols-2 gap-2">
                <div>
                  <input
                    type="number"
                    min="0"
                    value={capitalMin}
                    onChange={handleCapitalMinChange}
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                    placeholder="Mínimo"
                  />
                </div>
                <div>
                  <input
                    type="number"
                    min="0"
                    value={capitalMax}
                    onChange={handleCapitalMaxChange}
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                    placeholder="Máximo"
                  />
                </div>
              </div>
            </div>
            
            {/* Monto Desembolso Range Filter */}
            <div>
              <label className="block text-sm font-medium text-gray-700">
                Monto Desembolso
              </label>
              <div className="grid grid-cols-2 gap-2">
                <div>
                  <input
                    type="number"
                    min="0"
                    value={desembolsoMin}
                    onChange={handleDesembolsoMinChange}
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                    placeholder="Mínimo"
                  />
                </div>
                <div>
                  <input
                    type="number"
                    min="0"
                    value={desembolsoMax}
                    onChange={handleDesembolsoMaxChange}
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                    placeholder="Máximo"
                  />
                </div>
              </div>
            </div>
            
            <div className="flex items-end space-x-2">
              <button
                onClick={handleSearch}
                disabled={loading}
                className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                {loading ? 'Cargando...' : 'Buscar'}
              </button>
              
              <button
                onClick={handleReset}
                disabled={loading}
                className="inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md shadow-sm text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                Limpiar
              </button>
            </div>
          </div>
        </div>
      </div>
      
      {/* Error message */}
      {error && (
        <div className="mx-6 mt-6 bg-red-50 border-l-4 border-red-400 p-4">
          <div className="flex">
            <div className="flex-shrink-0">
              <svg className="h-5 w-5 text-red-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clipRule="evenodd" />
              </svg>
            </div>
            <div className="ml-3">
              <p className="text-sm text-red-700">{error}</p>
            </div>
          </div>
        </div>
      )}
      
      {/* Results Table */}
      {loading ? (
        <div className="flex h-64">
          <div className="m-auto">
            <Bars color="#00BFFF" height={100} width={100} timeout={20000} />
          </div>
        </div>
      ) : visual && !error && (
        <div className="mx-6 mt-6 bg-white shadow overflow-hidden sm:rounded-lg">
          {/* Table Header */}
          <div className="px-4 py-5 border-b border-gray-200 sm:px-6 flex justify-between items-center">
            <h3 className="text-left text-lg leading-6 font-medium text-gray-900">
              Resultados ({totalItems} registros encontrados)
            </h3>
            
            <div>
              <input
                type="text"
                placeholder="Filtrar resultados"
                value={filterText}
                onChange={(e) => setFilterText(e.target.value)}
                className="rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
              />
            </div>
          </div>

          {/* Table with horizontal scroll */}
          <div className="px-4 py-5 sm:px-6">
            <div className="overflow-x-auto" style={{ maxWidth: '100%' }}>
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Crédito ID
                    </th>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Cliente
                    </th>
                    <th 
                      scope="col" 
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer hover:bg-gray-100"
                      onClick={() => handleSort('CapitalActual')}
                    >
                      Capital Actual
                      {sortField === 'CapitalActual' && (
                        <span className="ml-1">
                          {sortDirection === 'ASC' ? '↑' : '↓'}
                        </span>
                      )}
                    </th>
                    <th 
                      scope="col" 
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer hover:bg-gray-100"
                      onClick={() => handleSort('MontoDesembolso')}
                    >
                      Monto Desembolso
                      {sortField === 'MontoDesembolso' && (
                        <span className="ml-1">
                          {sortDirection === 'ASC' ? '↑' : '↓'}
                        </span>
                      )}
                    </th>
                    <th 
                      scope="col" 
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer hover:bg-gray-100"
                      onClick={() => handleSort('PorcentajeRecorrido')}
                    >
                      % Recorrido
                      {sortField === 'PorcentajeRecorrido' && (
                        <span className="ml-1">
                          {sortDirection === 'ASC' ? '↑' : '↓'}
                        </span>
                      )}
                    </th>
                    <th 
                      scope="col" 
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer hover:bg-gray-100"
                      onClick={() => handleSort('PromedioDiasMora')}
                    >
                      Promedio Días Mora
                      {sortField === 'PromedioDiasMora' && (
                        <span className="ml-1">
                          {sortDirection === 'ASC' ? '↑' : '↓'}
                        </span>
                      )}
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap">
                      Cliente ID
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap">
                      Nombre Cliente
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap">
                      Sucursal
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap">
                      Solicitud ID
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap">
                      Garantía
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap">
                      Actividad
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap">
                      Gestor
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap">
                      Estatus
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap">
                      Fecha
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {filteredData.length > 0 ? (
                    filteredData.map((item, index) => (
                      <tr key={index} className={index % 2 === 0 ? 'bg-white' : 'bg-gray-50'}>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          {item.CreditoID}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          {item.NombreCompleto}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          {formatCurrency(item.CapitalActual)}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          {formatCurrency(item.MontoDesembolso)}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          {item.PorcentajeRecorrido ? `${item.PorcentajeRecorrido.toFixed(2)}%` : 'N/A'}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          {item.PromedioDiasMora ? item.PromedioDiasMora.toFixed(1) : 'N/A'}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          {item.ClienteID}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          {item.NombreCompleto}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          {item.Sucursal}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          {item.SolicitudID}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          {item.Garantia}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          {item.ActividadDescripcion}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          {item.NombreGestor}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${
                            item.EstatusCredito === 'VIGENTE' ? 'bg-green-100 text-green-800' : 
                            item.EstatusCredito === 'VENCIDO' ? 'bg-red-100 text-red-800' : 
                            'bg-gray-100 text-gray-800'
                          }`}>
                            {item.EstatusCredito}
                          </span>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          {formatDate(item.InsertDate)}
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="14" className="px-6 py-4 text-center text-sm text-gray-500">
                        No hay datos disponibles
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>

          {/* Export buttons */}
          {data.length > 0 && (
            <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
              <span className="mr-2 text-sm text-gray-700">Exportar:</span>
              <button
                onClick={() => handleExport('csv')}
                className="inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 mr-2"
              >
                CSV
              </button>
              <button
                onClick={() => handleExport('xls')}
                className="inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                Excel
              </button>
            </div>
          )}

          {/* Pagination */}
          {totalPages > 1 && (
            <div className="px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6">
              <div className="flex-1 flex justify-between sm:hidden">
                <button
                  onClick={() => setCurrentPage(page => Math.max(page - 1, 1))}
                  disabled={currentPage === 1 || loading}
                  className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                >
                  Anterior
                </button>
                <button
                  onClick={() => setCurrentPage(page => Math.min(page + 1, totalPages))}
                  disabled={currentPage === totalPages || loading}
                  className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                >
                  Siguiente
                </button>
              </div>
              <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
                <div>
                  <p className="text-sm text-gray-700">
                    Mostrando <span className="font-medium">{(currentPage - 1) * itemsPerPage + 1}</span> a{' '}
                    <span className="font-medium">
                      {Math.min(currentPage * itemsPerPage, totalItems)}
                    </span>{' '}
                    de <span className="font-medium">{totalItems}</span> resultados
                  </p>
                </div>
                <div>
                  <nav className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px" aria-label="Pagination">
                    <button
                      onClick={() => setCurrentPage(1)}
                      disabled={currentPage === 1 || loading}
                      className="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                    >
                      <span className="sr-only">Primera</span>
                      <span>Primera</span>
                    </button>
                    <button
                      onClick={() => setCurrentPage(page => Math.max(page - 1, 1))}
                      disabled={currentPage === 1 || loading}
                      className="relative inline-flex items-center px-2 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                    >
                      <span className="sr-only">Anterior</span>
                      <span>Anterior</span>
                    </button>
                    {/* Page numbers - limit to 5 pages for better UI */}
                    {[...Array(Math.min(5, totalPages)).keys()].map(number => {
                      // Calculate which page numbers to show
                      let pageNum;
                      if (totalPages <= 5) {
                        pageNum = number + 1;
                      } else if (currentPage <= 3) {
                        pageNum = number + 1;
                      } else if (currentPage >= totalPages - 2) {
                        pageNum = totalPages - 4 + number;
                      } else {
                        pageNum = currentPage - 2 + number;
                      }
                      
                      return (
                        <button
                          key={number}
                          onClick={() => setCurrentPage(pageNum)}
                          disabled={loading}
                          className={`relative inline-flex items-center px-4 py-2 border ${
                            currentPage === pageNum
                              ? 'bg-indigo-50 border-indigo-500 text-indigo-600'
                              : 'border-gray-300 bg-white text-gray-500 hover:bg-gray-50'
                          } text-sm font-medium`}
                        >
                          {loading && currentPage === pageNum ? '...' : pageNum}
                        </button>
                      );
                    })}
                    <button
                      onClick={() => setCurrentPage(page => Math.min(page + 1, totalPages))}
                      disabled={currentPage === totalPages || loading}
                      className="relative inline-flex items-center px-2 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                    >
                      <span className="sr-only">Siguiente</span>
                      <span>Siguiente</span>
                    </button>
                    <button
                      onClick={() => setCurrentPage(totalPages)}
                      disabled={currentPage === totalPages || loading}
                      className="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                    >
                      <span className="sr-only">Última</span>
                      <span>Última</span>
                    </button>
                  </nav>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default RefinanciamientosTable; 