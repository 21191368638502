import React from 'react';
import RefinanciamientosTable from './RefinanciamientosTable';

const Refinanciamientos = () => {
  return (
    <div className="container mx-auto px-4 sm:px-6 lg:px-8 py-8">
      <h1 className="text-2xl font-bold mb-6">Refinanciamientos</h1>
      <RefinanciamientosTable />
    </div>
  );
};

export default Refinanciamientos; 