import React, {useEffect,useState,useMemo, useRef} from "react";
import Template from "../Template";
import axios from "axios";
import {Bars} from 'react-loader-spinner';
import Table, { NameCell, ProductCell, AmountCell, EstatusCell, OfficeCell, AvatarCell, SelectColumnFilter, StatusPill } from '../Table/Table';
import { orangeClasses, grayClasses } from "../Design/classes";
import { formatDate } from "../Helpers/Helpers";



const WalletConciliaciones = (props) => {

  //const [currentUser, setCurrentUser] = useState([]);
  const [walletData,setWalletData] = useState([]);
  const [loading, setLoading] = useState([]);
  const [checkedState, setCheckedState] = useState([]);
  const [isChecked, setIsChecked] = useState({});
  const [monto, setMonto] = useState([0]);
  const [nombreBanco, setNombreBanco] = useState("");
  const [numeroReferencia, setNumeroReferencia] = useState([]);
  const [detalleNotas, setDetalleNotas] = useState([]);
  const [numtransaccion, setNumTransaccion] = useState(0);
  const [pagoerror, setPagoError] = useState([]);
  const [paymentSelected, setPaymentSelected] = useState([]);
  const [potentialDuplicates, setPotentialDuplicates] = useState({});
  const {currentUser} = props;
  const [filterText, setFilterText] = useState('');
  const [showOnlyDuplicates, setShowOnlyDuplicates] = useState(false);
  const [showRejectionModal, setShowRejectionModal] = useState(false);
  const [rejectionPaymentId, setRejectionPaymentId] = useState(null);
  const [rejectionNote, setRejectionNote] = useState('');
  const [rejectionType, setRejectionType] = useState('');
  const [selectedPaymentId, setSelectedPaymentId] = useState(null);
  const [selectedPaymentType, setSelectedPaymentType] = useState('');
  const [selectedBank, setSelectedBank] = useState('');
  const [showConciliationModal, setShowConciliationModal] = useState(false);
  const [conciliationDetails, setConciliationDetails] = useState(null);
  const [showNotification, setShowNotification] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState('');
  const [notificationType, setNotificationType] = useState('success'); // 'success' or 'error'
  const [showNotificationModal, setShowNotificationModal] = useState(false);
  const [showDuplicates, setShowDuplicates] = useState(false);
  const [showPagosMasivos, setShowPagosMasivos] = useState(false);
  const [failedTransactionsDetails, setFailedTransactionsDetails] = useState([]);
  const [creditoData, setCreditoData] = useState(null);
  const [switchCredit, setSwitchCredit] = useState(false);
  const [pagosSFCore, setPagosSFCore] = useState(null);

  const includesFilterText = (text) => {
    return String(text).toLowerCase().includes(filterText.toLowerCase());
  };

  const sum = checkedState.length > 0 
    ? checkedState.reduce((result, item) => {
        const amount = parseFloat(item.split(',')[0]);
        return result + (isNaN(amount) ? 0 : amount);
      }, 0).toFixed(2) 
    : "0.00";

  const handleCheck = (event) => {
    const { name, checked, value } = event.target;
    
    setIsChecked(prev => ({...prev, [name]: checked}));
    
    if (checked) {
      setCheckedState(prev => [...prev, value]);
    } else {
      setCheckedState(prev => prev.filter(item => {
        const itemId = item.split(',')[1];
        const valueId = value.split(',')[1];
        return itemId !== valueId;
      }));
    }
  };

  const handleInput = (event) => {
    setMonto(event.target.value ? parseFloat(event.target.value) : 0);
  }

  const getFilteredData = () => {
    if (!walletData.pagos) return [];
    
    const filtered = walletData.pagos
      .filter(walletConcilia => {
        // Only include non-conciliated and non-rejected payments
        if (walletConcilia.pago.conciliado !== 'false' || walletConcilia.pago.rechazado === 'true') {
          return false;
        }

        // Filter by payment type if selected
        if (selectedPaymentType && walletConcilia.pago.tipoPago !== selectedPaymentType) {
          return false;
        }

        // Filter by selected bank if any
        if (selectedBank && walletConcilia.pago.bancoReceptor !== selectedBank) {
          return false;
        }

        // Filter for Pagos Masivos if checkbox is selected
        if (showPagosMasivos && walletConcilia.pago.esPagoMasivo !== 'true') {
          return false;
        }

        // Then apply duplicate filter if enabled
        if (showOnlyDuplicates && !potentialDuplicates[walletConcilia.pago._id.$oid]) {
          return false;
        }

        // Finally apply text search if any
        if (filterText) {
          let shortenedID = String(walletConcilia.pago._id.$oid).slice(-5);
          let usuario = walletConcilia.pago?.usuario?.NombreCompleto ?? "Sin Usuario";
          const idText = 'PagoID: ' + shortenedID + ' / Usuario: ' + usuario;
          const numRef = walletConcilia.pago.referenciaTransferencia;
          const fechaTrans = walletConcilia.pago.fechaTransferencia;
          const tipoPago = walletConcilia.pago.tipoPago;
          const credito = walletConcilia.pago.creditNumber;
          const nombreCliente = walletConcilia.pago.nombreCliente;
          const depositante = walletConcilia.pago.depositante;
          const bancoReceptor = walletConcilia.pago.bancoReceptor;
          const noLibreCobro = walletConcilia.pago.noLibreCobro;
          const montoPagado = walletConcilia.pago.amount;

          return (
            includesFilterText(depositante) ||
            includesFilterText(idText) ||
            includesFilterText(numRef) ||
            includesFilterText(fechaTrans) ||
            includesFilterText(tipoPago) ||
            includesFilterText(credito) ||
            includesFilterText(nombreCliente) ||
            includesFilterText(bancoReceptor) ||
            includesFilterText(noLibreCobro) ||
            includesFilterText(montoPagado)
          );
        }

        return true;
      });

    // Debug date values before sorting
    console.log("Dates before sorting:", filtered.map(item => ({
      id: item.pago._id.$oid,
      date: item.pago.created_at,
      parsed: new Date(item.pago.created_at)
    })));

    // Sort the filtered results by created_at
    return filtered.sort((a, b) => {
      // Parse the dates from created_at
      const dateA = new Date(a.pago.created_at);
      const dateB = new Date(b.pago.created_at);
      
      // Debug the date comparison
      console.log('Comparing dates:', {
        dateAStr: a.pago.created_at,
        dateBStr: b.pago.created_at,
        dateA: dateA,
        dateB: dateB,
        comparison: dateB - dateA
      });
      
      // Handle invalid dates
      if (isNaN(dateA.getTime()) && isNaN(dateB.getTime())) return 0;
      if (isNaN(dateA.getTime())) return 1;
      if (isNaN(dateB.getTime())) return -1;
      
      // Sort in descending order (most recent first)
      return dateB.getTime() - dateA.getTime();
    });
  };

  // Get unique payment types for the select options
  const getUniquePaymentTypes = () => {
    if (!walletData.pagos) return [];
    const types = new Set(walletData.pagos.map(item => item.pago.tipoPago));
    return Array.from(types).filter(Boolean).sort();
  };

  // Get unique bank names for the select options
  const getUniqueBanks = () => {
    if (!walletData.pagos) return [];
    const banks = new Set(walletData.pagos.map(item => item.pago.bancoReceptor));
    return Array.from(banks).filter(Boolean).sort();
  };

  // Function to detect potential duplicate payments
  const detectDuplicatePayments = (payments) => {
    if (!payments || !payments.length) return {};
    
    // Filter payments to only include those that are not conciliated and not rejected
    const activePayments = payments.filter(item => 
      item.pago.conciliado === 'false' && item.pago.rechazado !== 'true'
    );
    
    console.log(`Total payments: ${payments.length}, Active payments: ${activePayments.length}`);
    
    // Create maps to group payments by different criteria
    const paymentGroups = {};
    const referenceGroups = {};
    const duplicates = {};
    
    // Helper function to normalize strings for comparison
    const normalizeString = (str) => {
      return String(str || '')
        .toLowerCase()
        .trim()
        .replace(/\s+/g, ' '); // Replace multiple spaces with a single space
    };
    
    // Helper function to normalize amounts
    const normalizeAmount = (amount) => {
      return parseFloat(amount).toFixed(2);
    };
    
    activePayments.forEach(item => {
      const pago = item.pago;
      
      // Normalize values for comparison
      const normalizedAmount = normalizeAmount(pago.amount);
      const normalizedClientName = normalizeString(pago.nombreCliente);
      const normalizedCreditNumber = normalizeString(pago.creditNumber);
      const normalizedReference = normalizeString(pago.referenciaTransferencia);
      
      // Create keys for different types of duplicates
      const amountClientKey = `${normalizedAmount}-${normalizedClientName}-${normalizedCreditNumber}`;
      const referenceKey = normalizedReference;
      
      // Skip empty reference numbers
      if (referenceKey && referenceKey !== '') {
        if (!referenceGroups[referenceKey]) {
          referenceGroups[referenceKey] = [];
        }
        referenceGroups[referenceKey].push({
          id: pago._id.$oid,
          amount: pago.amount,
          clientName: pago.nombreCliente,
          creditNumber: pago.creditNumber,
          date: pago.created_at,
          referencia: pago.referenciaTransferencia
        });
      }
      
      if (!paymentGroups[amountClientKey]) {
        paymentGroups[amountClientKey] = [];
      }
      
      paymentGroups[amountClientKey].push({
        id: pago._id.$oid,
        amount: pago.amount,
        clientName: pago.nombreCliente,
        creditNumber: pago.creditNumber,
        date: pago.created_at,
        referencia: pago.referenciaTransferencia
      });
    });
    
    // Check for duplicates based on amount, client, and credit number
    Object.keys(paymentGroups).forEach(key => {
      const group = paymentGroups[key];
      if (group.length > 1) {
        group.forEach(payment => {
          duplicates[payment.id] = {
            reason: `Coincide con ${group.length - 1} pago(s) con mismo monto, cliente y número de crédito`,
            group: group.filter(p => p.id !== payment.id)
          };
        });
      }
    });
    
    // Check for duplicates based on reference number
    Object.keys(referenceGroups).forEach(key => {
      const group = referenceGroups[key];
      if (group.length > 1) {
        group.forEach(payment => {
          // If payment is already marked as duplicate, add reference number reason
          if (duplicates[payment.id]) {
            duplicates[payment.id].reason += `\nTambién: Número de referencia duplicado (${key})`;
          } else {
            duplicates[payment.id] = {
              reason: `Número de referencia duplicado (${key})`,
              group: group.filter(p => p.id !== payment.id)
            };
          }
        });
      }
    });
    
    console.log("Duplicate detection details:", {
      totalPayments: payments.length,
      activePayments: activePayments.length,
      uniqueKeys: Object.keys(paymentGroups).length,
      uniqueReferences: Object.keys(referenceGroups).length,
      duplicatesFound: Object.keys(duplicates).length
    });
    
    return duplicates;
  };

  useEffect(() => {
    setLoading(true);
    loadConsulta();

    return () => {
      isMounted.current = false;
    };

    
  }, [])

  const loadConsulta = async () => {
    // For conciliation, we still need all payments but with a limit
    await axios.get(`/api/v1/safi/get_all_pagos.json`).then((res) => {
      if(isMounted.current){
        // Sort the data by created_at before setting it
        const sortedData = {
          ...res.data,
          pagos: res.data.pagos.sort((a, b) => {
            // Parse the dates from created_at
            const dateA = new Date(a.pago.created_at).getTime();
            const dateB = new Date(b.pago.created_at).getTime();
            
            // Handle invalid dates
            if (isNaN(dateA) && isNaN(dateB)) return 0;
            if (isNaN(dateA)) return 1;
            if (isNaN(dateB)) return -1;
            
            // Sort in descending order (most recent first)
            return dateB - dateA;
          })
        };
        setWalletData(sortedData);
        
        // Detect potential duplicates when data is loaded
        if (sortedData && sortedData.pagos) {
          const duplicates = detectDuplicatePayments(sortedData.pagos);
          setPotentialDuplicates(duplicates);
          console.log("Potential duplicates detected:", Object.keys(duplicates).length);
        }
        
        setLoading(false);
      }
    });
  };

  const isMounted = useRef(true);

  const cancelPago = (cotizaID, rejection) => {
    setLoading(true);
    const token = document.querySelector('[name=csrf-token]').content;
    axios.defaults.headers.common['X-CSRF-TOKEN'] = token;
    axios.post(`/api/v1/safi/update_pago`, {
      ...{cotizaID}, 
      ...{
        rechazado: 'true',
        motivoRechazo: rejection.note,
        tipoRechazo: rejection.type
      }
    })
      .then(resp => {
        loadConsulta();
        showTemporaryNotification(
          "Pago rechazado exitosamente",
          'success'
        );
      })
      .catch(data => {
        console.log('error', data);
        showTemporaryNotification(
          "Error al rechazar el pago",
          'error'
        );
      });
  };

  const handleRejectionClick = (paymentId) => {
    setRejectionPaymentId(paymentId);
    setRejectionNote('');
    setRejectionType('');
    setShowRejectionModal(true);
  };

  const handleConfirmRejection = () => {
    if (!rejectionType) {
      showTemporaryNotification(
        "Por favor seleccione un motivo de rechazo",
        'error'
      );
      return;
    }
    
    if (!rejectionNote.trim()) {
      showTemporaryNotification(
        "Por favor agregue una nota detallada del rechazo",
        'error'
      );
      return;
    }

    cancelPago(rejectionPaymentId, { type: rejectionType, note: rejectionNote });
    setShowRejectionModal(false);
    setRejectionPaymentId(null);
    setRejectionNote('');
    setRejectionType('');
  };

  const handleCancelRejection = () => {
    setShowRejectionModal(false);
    setRejectionPaymentId(null);
    setRejectionNote('');
    setRejectionType('');
  };

  const rejectionReasons = [
    "Error - no. crédito, nombre de depositante o beneficiario",
    "Error - referencia, cuenta o nombre del banco receptor",
    "Error - valor del pago",
    "Error - soporte",
    "Duplicado",
    "Estatus - Castigado o Pagado",
    "Recuperación pendiente"
  ];

  const handleConciliationClick = () => {
    if (parseFloat(sum) !== parseFloat(monto) || !nombreBanco || nombreBanco === "") {
      alert("Por favor, asegúrese de que el monto coincida y seleccione un banco antes de conciliar.");
      return;
    }

    // Get selected transactions details
    const selectedTransactions = walletData.pagos
      .filter(item => isChecked[`monto${item.pago._id.$oid}`])
      .map(item => ({
        referencia: item.pago.referenciaTransferencia,
        cliente: item.pago.nombreCliente,
        monto: item.pago.amount,
        credito: item.pago.creditNumber
      }));

    setConciliationDetails({
      transactions: selectedTransactions,
      totalAmount: sum,
      bank: nombreBanco,
      notes: detalleNotas
    });
    setShowConciliationModal(true);
  };

  const handleConfirmConciliation = () => {
    setShowConciliationModal(false);
    updateConciliacion();
  };

  const handleCancelConciliation = () => {
    setShowConciliationModal(false);
  };

  const resetStates = () => {
    setCheckedState([]);
    setIsChecked({});
    setMonto(0);
    setNombreBanco('');
    setDetalleNotas('');
    setShowConciliationModal(false);
    
    // Reset all input fields
    const montoInput = document.querySelector('input[name="montoConciliar"]');
    const notasInput = document.querySelector('input[name="detalleNotas"]');
    const bancoSelect = document.querySelector('select[name="nombreBanco"]');
    
    if (montoInput) montoInput.value = '';
    if (notasInput) notasInput.value = '';
    if (bancoSelect) bancoSelect.value = '';
  };

  const showTemporaryNotification = (message, type = 'success', successTransactions = [], failedDetails = []) => {
    setNotificationMessage(message);
    setNotificationType(type);
    setFailedTransactionsDetails(failedDetails);
    setShowNotificationModal(true);
  };

  const processTransaction = async (pago, validTransactions) => {
    if (!pago) return;

    try {
      const separatedObject = pago.split(',');
      
      const paymentAmount = separatedObject[0];
      const cotizaID = separatedObject[1];
      const paymentCreditNumber = separatedObject[2];
      const transactionType = separatedObject[3];
      const paymentCustomerNumber = separatedObject[5];
      const clientName = separatedObject[6];
      const referencia = separatedObject[7];
      const bancoReceptor = separatedObject[8];
      
      const validTransactionTypes = [
        'transferencia',
        'tdc',
        'multipago',
        'puntopago',
        'arp',
        'Western Union',
        'efectivo',
        'cheque',
        'ach',
        'cierre_comercial',
        'cierre_admin',
        // Adding alternate formats used in PagoMasivo (mass payments) while preserving original formats
        // for backward compatibility with single payments
        'Depósito en Efectivo',
        'ACH',
        'Multipago',
        'PuntoPago',
        'ARP Banistmo',
        'Aplicación de cierre - comercial',
        'Aplicación de cierre - admin'
      ];
      
      console.log('Transaction type check:', transactionType, 'Valid?', validTransactionTypes.includes(transactionType));
      
      if (validTransactionTypes.includes(transactionType)) {
        const paymentData = {
          customerNumber: paymentCustomerNumber,
          paymentType: 'E',
          creditNumber: paymentCreditNumber,
          savingsAccountNumber: '',
          amount: paymentAmount,
          cashNumber: '27',
          branchOffice: '1',
          bancoReceptor: bancoReceptor,
        };

        const resp = await axios.post(`/api/v1/safi/pago`, paymentData);
        console.log('Payment response:', resp.data);

        if (resp.data.codigoRespuesta === '000000') {
          validTransactions.push(pago);
          await axios.post(`/api/v1/safi/update_pago`, {
            cotizaID,
            conciliado: 'true',
            numTransaccion: resp.data.folio,
          });
        } else {
          throw new Error(resp.data.mensajeRespuesta || 'Error en la respuesta del servidor');
        }
      } else {
        validTransactions.push(pago);
        await axios.post(`/api/v1/safi/update_pago`, {
          cotizaID,
          conciliado: 'true',
        });
      }
    } catch (error) {
      console.error('Error processing transaction:', error);
      setPagoError(error.message);
      setPaymentSelected('pagoerror');
      throw error; // Re-throw to be handled by the caller
    }
  };

  const updateConciliacion = async (cotizaID, event) => {
    if (parseFloat(sum) !== parseFloat(monto) || !nombreBanco || nombreBanco === "") {
      showTemporaryNotification("Por favor, asegúrese de que el monto coincida y seleccione un banco antes de conciliar.", "error");
      return;
    }
    
    setLoading(true);
    
    try {
      const myObject = {
        transactions: checkedState,
        nombreBanco: nombreBanco,
        numeroReferencia: numeroReferencia,
        detalleNotas: detalleNotas,
        usuario: currentUser,
      };
    
      const token = document.querySelector('[name=csrf-token]').content;
      axios.defaults.headers.common['X-CSRF-TOKEN'] = token;
    
      const validTransactions = [];
      const failedTransactions = [];
      const failedDetails = [];
    
      // Process each transaction independently
      for (const pago of myObject.transactions) {
        try {
          await processTransaction(pago, validTransactions);
        } catch (error) {
          console.error('Error processing transaction:', error);
          failedTransactions.push(pago);
          
          // Extract transaction details for display
          const separatedObject = pago.split(',');
          failedDetails.push({
            amount: separatedObject[0],
            id: separatedObject[1],
            creditNumber: separatedObject[2],
            tipoPago: separatedObject[3],
            customerNumber: separatedObject[5],
            nombreCliente: separatedObject[6],
            referencia: separatedObject[7],
            bancoReceptor: separatedObject[8],
            error: error.message || 'Error desconocido'
          });
          
          // Continue with next transaction instead of stopping
          continue;
        }
      }
    
      if (validTransactions.length > 0) {
        // Calculate total amount from successful transactions
        const successfulTotal = validTransactions.reduce((total, transaction) => {
          const amount = parseFloat(transaction.split(',')[0]);
          return total + (isNaN(amount) ? 0 : amount);
        }, 0);

        await axios.post(`/api/v1/safi/save_conciliacion`, {
          ...myObject,
          transactions: validTransactions,
          montoAConciliar: successfulTotal.toFixed(2),
        });
        
        resetStates();
        
        // Store failed transaction details for display
        setFailedTransactionsDetails(failedDetails);
        
        // Show summary notification
        if (failedTransactions.length > 0) {
          showTemporaryNotification(
            `Conciliación parcial: ${validTransactions.length} transacciones exitosas por $${successfulTotal.toFixed(2)}, ${failedTransactions.length} fallidas.`,
            'warning',
            validTransactions,
            failedDetails
          );
        } else {
          showTemporaryNotification("¡Conciliación completada exitosamente!");
        }
        
        await loadConsulta();
      } else {
        showTemporaryNotification(
          "No se pudo procesar ninguna transacción. Por favor, intente nuevamente.",
          "error"
        );
      }
    } catch (error) {
      console.error('error', error);
      showTemporaryNotification(
        "Ocurrió un error durante la conciliación. Por favor, intente nuevamente.",
        "error"
      );
    } finally {
      setLoading(false);
    }
  };
  

    return (
      <Template currentUser={currentUser}  location={props.location}>
            { loading ?
                <div className="flex h-screen">
                <div className="m-auto">
                    <Bars
                        color="#00BFFF"
                        height={100}
                        width={100}
                        timeout={30000} //3 secs                
                    />
                    </div>
                </div>            
              :    
              <div className="overflow-y-auto relative min-h-screen pb-32">
                {/* Rejection Modal */}
                {showRejectionModal && (
                  <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full z-50">
                    <div className="relative top-20 mx-auto p-5 border w-96 shadow-lg rounded-md bg-white">
                      <div className="mt-3">
                        <div className="flex items-center justify-center flex-col">
                          <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-red-100">
                            <svg className="h-6 w-6 text-red-600" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"/>
                            </svg>
                          </div>
                          <h3 className="text-lg leading-6 font-medium text-gray-900 mt-4">
                            Rechazar Pago
                          </h3>
                          <div className="mt-4 px-7 py-3 w-full">
                            <div className="mb-4">
                              <label className="block text-sm font-medium text-gray-700 mb-2">
                                Motivo del Rechazo
                              </label>
                              <select
                                value={rejectionType}
                                onChange={(e) => setRejectionType(e.target.value)}
                                className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                              >
                                <option value="">Seleccione un motivo</option>
                                {rejectionReasons.map((reason, index) => (
                                  <option key={index} value={reason}>
                                    {reason}
                                  </option>
                                ))}
                              </select>
                            </div>
                            <div>
                              <label className="block text-sm font-medium text-gray-700 mb-2">
                                Nota Detallada
                              </label>
                              <textarea
                                value={rejectionNote}
                                onChange={(e) => setRejectionNote(e.target.value)}
                                placeholder="Ingrese los detalles del rechazo"
                                className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                                rows="3"
                              />
                            </div>
                          </div>
                          <div className="flex justify-center gap-4 mt-4">
                            <button
                              onClick={handleCancelRejection}
                              className="px-4 py-2 bg-gray-300 text-gray-700 text-base font-medium rounded-md shadow-sm hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-gray-500"
                            >
                              Cancelar
                            </button>
                            <button
                              onClick={handleConfirmRejection}
                              className="px-4 py-2 bg-red-600 text-white text-base font-medium rounded-md shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500"
                            >
                              Rechazar
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {/* Conciliation Confirmation Modal */}
                {showConciliationModal && conciliationDetails && (
                  <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full z-50 flex items-center justify-center">
                    <div className="relative mx-auto p-5 border w-[800px] shadow-lg rounded-md bg-white">
                      <div className="mt-3">
                        <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-yellow-100">
                          <svg className="h-6 w-6 text-yellow-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
                          </svg>
                        </div>
                        <h3 className="text-lg leading-6 font-medium text-gray-900 mt-2 text-center">
                          Confirmar Conciliación
                        </h3>
                        <div className="mt-4 px-7 py-3">
                          <div className="mb-4">
                            <p className="text-sm text-gray-500">
                              <strong>Banco:</strong> {conciliationDetails.bank}
                            </p>
                            <p className="text-sm text-gray-500">
                              <strong>Monto Total:</strong> {new Intl.NumberFormat("en-US", {
                                style: "currency",
                                currency: "USD"
                              }).format(conciliationDetails.totalAmount)}
                            </p>
                            {conciliationDetails.notes && (
                              <p className="text-sm text-gray-500">
                                <strong>Notas:</strong> {conciliationDetails.notes}
                              </p>
                            )}
                          </div>
                          <div className="mt-4">
                            <h4 className="text-sm font-medium text-gray-900 mb-2">Transacciones a conciliar:</h4>
                            <div className="max-h-60 overflow-y-auto">
                              <table className="min-w-full divide-y divide-gray-200">
                                <thead className="bg-gray-50">
                                  <tr>
                                    <th className="px-3 py-2 text-xs font-medium text-gray-500 uppercase tracking-wider">Referencia</th>
                                    <th className="px-3 py-2 text-xs font-medium text-gray-500 uppercase tracking-wider">Cliente</th>
                                    <th className="px-3 py-2 text-xs font-medium text-gray-500 uppercase tracking-wider">Crédito</th>
                                    <th className="px-3 py-2 text-xs font-medium text-gray-500 uppercase tracking-wider">Monto</th>
                                  </tr>
                                </thead>
                                <tbody className="bg-white divide-y divide-gray-200">
                                  {conciliationDetails.transactions.map((transaction, idx) => (
                                    <tr key={idx} className={idx % 2 === 0 ? 'bg-white' : 'bg-gray-50'}>
                                      <td className="px-3 py-2 text-sm text-gray-500">{transaction.referencia}</td>
                                      <td className="px-3 py-2 text-sm text-gray-500">{transaction.cliente}</td>
                                      <td className="px-3 py-2 text-sm text-gray-500">{transaction.credito}</td>
                                      <td className="px-3 py-2 text-sm text-gray-500">
                                        {new Intl.NumberFormat("en-US", {
                                          style: "currency",
                                          currency: "USD"
                                        }).format(transaction.monto)}
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                        <div className="flex justify-center gap-4 mt-5">
                          <button 
                            className="px-4 py-2 bg-yellow-600 text-white text-base font-medium rounded-md shadow-sm hover:bg-yellow-700 focus:outline-none focus:ring-2 focus:ring-yellow-500"
                            onClick={handleConfirmConciliation}
                          >
                            Confirmar Conciliación
                          </button>
                          <button 
                            className="px-4 py-2 bg-gray-200 text-gray-800 text-base font-medium rounded-md shadow-sm hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-300"
                            onClick={handleCancelConciliation}
                          >
                            Cancelar
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {/* Replace the notification modal with this enhanced version */}
                {showNotificationModal && (
                  <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full z-50 flex items-center justify-center">
                    <div className="relative mx-auto p-5 border w-[800px] shadow-lg rounded-md bg-white">
                      <div className="mt-3">
                        <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-white">
                          {notificationType === 'success' ? (
                            <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
                              <svg className="h-6 w-6 text-green-600" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"/>
                              </svg>
                            </div>
                          ) : notificationType === 'warning' ? (
                            <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-yellow-100">
                              <svg className="h-6 w-6 text-yellow-600" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
                              </svg>
                            </div>
                          ) : (
                            <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-red-100">
                              <svg className="h-6 w-6 text-red-600" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"/>
                              </svg>
                            </div>
                          )}
                        </div>
                        <h3 className={`text-lg leading-6 font-medium ${
                          notificationType === 'success' ? 'text-green-900' : 
                          notificationType === 'warning' ? 'text-yellow-900' : 'text-red-900'
                        } mt-2 text-center`}>
                          {notificationType === 'success' ? 'Éxito!' : 
                           notificationType === 'warning' ? 'Conciliación Parcial' : 'Error'}
                        </h3>
                        <div className="mt-2 px-7 py-3">
                          <p className="text-sm text-gray-700 text-center mb-4">
                            {notificationMessage}
                          </p>
                          
                          {/* Show failed transactions if any */}
                          {failedTransactionsDetails.length > 0 && (
                            <div className="mt-4">
                              <div className={`p-3 mb-4 rounded-md bg-red-50 border border-red-200`}>
                                <h4 className="text-sm font-medium text-red-800 mb-2">
                                  Transacciones Fallidas ({failedTransactionsDetails.length}):
                                </h4>
                                <div className="max-h-60 overflow-y-auto">
                                  <table className="min-w-full divide-y divide-red-200">
                                    <thead className="bg-red-50">
                                      <tr>
                                        <th className="px-3 py-2 text-xs font-medium text-red-700 uppercase tracking-wider">Cliente</th>
                                        <th className="px-3 py-2 text-xs font-medium text-red-700 uppercase tracking-wider">Crédito</th>
                                        <th className="px-3 py-2 text-xs font-medium text-red-700 uppercase tracking-wider">Monto</th>
                                        <th className="px-3 py-2 text-xs font-medium text-red-700 uppercase tracking-wider">Error</th>
                                      </tr>
                                    </thead>
                                    <tbody className="bg-white divide-y divide-red-100">
                                      {failedTransactionsDetails.map((transaction, idx) => (
                                        <tr key={idx} className={idx % 2 === 0 ? 'bg-white' : 'bg-red-50'}>
                                          <td className="px-3 py-2 text-sm text-red-700">{transaction.nombreCliente}</td>
                                          <td className="px-3 py-2 text-sm text-red-700">{transaction.creditNumber}</td>
                                          <td className="px-3 py-2 text-sm text-red-700">
                                            {new Intl.NumberFormat("en-US", {
                                              style: "currency",
                                              currency: "USD"
                                            }).format(transaction.amount)}
                                          </td>
                                          <td className="px-3 py-2 text-sm text-red-700">{transaction.error}</td>
                                        </tr>
                                      ))}
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                        <div className="flex justify-center mt-4">
                          <button
                            onClick={() => setShowNotificationModal(false)}
                            className={`px-4 py-2 ${
                              notificationType === 'success' 
                                ? 'bg-green-600 hover:bg-green-700' 
                                : notificationType === 'warning'
                                ? 'bg-yellow-600 hover:bg-yellow-700'
                                : 'bg-red-600 hover:bg-red-700'
                            } text-white text-base font-medium rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 ${
                              notificationType === 'success' ? 'focus:ring-green-500' : 
                              notificationType === 'warning' ? 'focus:ring-yellow-500' : 'focus:ring-red-500'
                            }`}
                          >
                            OK
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {/*Inner Container Start*/}
                <div className="mx-6 mt-6 bg-white shadow overflow-hidden sm:rounded-lg">
                  <div className="px-4 py-5 border-b border-gray-200 sm:px-6">
                    <h3 className="text-left text-lg leading-6 font-medium text-gray-900">
                      Conciliacion de Pagos
                    </h3>
                    {Object.keys(potentialDuplicates).length > 0 && (
                      <div className="mt-2 p-2 bg-red-50 border border-red-200 rounded-md">
                        <p className="text-sm text-red-800">
                          <span className="font-bold">⚠️ Atención:</span> Se han detectado {Object.keys(potentialDuplicates).length} posibles pagos duplicados (resaltados en rojo).
                        </p>
                      </div>
                    )}
                  </div>

                  <div className="p-5 flex flex-col sm:flex-row sm:gap-x-4 sm:justify-between">
                    <div className="flex flex-wrap gap-4 items-center mb-4 sm:mb-0">
                      <label className="flex gap-x-2 items-baseline">
                        <span className="text-gray-700 whitespace-nowrap">Buscar: </span>
                        <input
                          type="text"
                          placeholder="Filtrar resultados"
                          value={filterText}
                          onChange={(e) => setFilterText(e.target.value)}
                          className="rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                        />
                      </label>

                      <label className="flex gap-x-2 items-baseline">
                        <span className="text-gray-700 whitespace-nowrap">Tipo de Pago: </span>
                        <select
                          value={selectedPaymentType}
                          onChange={(e) => setSelectedPaymentType(e.target.value)}
                          className="rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                        >
                          <option value="">Todos</option>
                          {getUniquePaymentTypes().map(type => (
                            <option key={type} value={type}>{type}</option>
                          ))}
                        </select>
                      </label>
                      
                      <label className="flex gap-x-2 items-baseline">
                        <span className="text-gray-700 whitespace-nowrap">Banco Receptor: </span>
                        <select
                          value={selectedBank}
                          onChange={(e) => setSelectedBank(e.target.value)}
                          className="rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                        >
                          <option value="">Todos</option>
                          {getUniqueBanks().map(bank => (
                            <option key={bank} value={bank}>{bank}</option>
                          ))}
                        </select>
                      </label>
                      
                      {Object.keys(potentialDuplicates).length > 0 && (
                        <label className="flex items-center gap-x-2">
                          <input
                            type="checkbox"
                            checked={showOnlyDuplicates}
                            onChange={(e) => setShowOnlyDuplicates(e.target.checked)}
                            className="rounded border-gray-300 text-indigo-600 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                          />
                          <span className="text-sm text-gray-700 whitespace-nowrap">Mostrar solo duplicados</span>
                        </label>
                      )}

                      <label className="flex gap-x-2 items-baseline ml-4">
                        <input
                          type="checkbox"
                          checked={showPagosMasivos}
                          onChange={(e) => setShowPagosMasivos(e.target.checked)}
                          className="rounded border-gray-300 text-blue-600 shadow-sm focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
                        />
                        <span className="text-gray-700">Pagos Masivos</span>
                      </label>
                    </div>
                  </div>

                  <div className="px-4 py-5 sm:px-6">
                    <div className="overflow-x-auto">
                      <div className="inline-block min-w-full align-middle">
                        <div className="overflow-hidden border-b border-gray-200 shadow sm:rounded-lg">
                          <table className="min-w-full divide-y divide-gray-200">
                            <thead className="bg-gray-50">
                              <tr>
                                <th className="sticky top-0 px-6 py-3 border-b border-gray-200 bg-gray-50 text-center text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                                  ID Transacción / Usuario
                                </th>
                                <th className="sticky top-0 px-6 py-3 border-b border-gray-200 bg-gray-50 text-center text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                                  Número de Referencia
                                </th>
                                <th className="sticky top-0 px-6 py-3 border-b border-gray-200 bg-gray-50 text-center text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                                  Fecha de Transferencia
                                </th>
                                <th className="sticky top-0 px-6 py-3 border-b border-gray-200 bg-gray-50 text-center text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                                  Tipo de Pago
                                </th>
                                <th className="sticky top-0 px-6 py-3 border-b border-gray-200 bg-gray-50 text-center text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                                  No. de Crédito
                                </th>
                                <th className="sticky top-0 px-6 py-3 border-b border-gray-200 bg-gray-50 text-center text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                                  Nombre de Cliente
                                </th>

                                <th className="sticky top-0 px-6 py-3 border-b border-gray-200 bg-gray-50 text-center text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                                  Depositante
                                </th>
                                <th className="sticky top-0 px-6 py-3 border-b border-gray-200 bg-gray-50 text-center text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                                  Banco Receptor
                                </th>
                                <th className="sticky top-0 px-6 py-3 border-b border-gray-200 bg-gray-50 text-center text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                                  No. Libre de Cobro
                                </th>
                                <th className="sticky top-0 px-6 py-3 border-b border-gray-200 bg-gray-50 text-center text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                                  Monto Pagado
                                </th>
                                <th className="sticky top-0 px-6 py-3 border-b border-gray-200 bg-gray-50 text-center text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                                Comprobante
                                </th>
                                <th className="sticky top-0 px-6 py-3 border-b border-gray-200 bg-gray-50 text-center text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                                  Seleccione
                                </th>
                                <th className="sticky top-0 px-6 py-3 border-b border-gray-200 bg-gray-50 text-center text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                                  Origen
                                </th>
                       
                              </tr>
                            </thead>
                            <tbody className="bg-white divide-y divide-gray-200">
                              {
                                getFilteredData().map((walletConcilia, index) => {

                                 let shortenedID = String(walletConcilia.pago._id.$oid).slice(-5);
                                 let usuario = walletConcilia.pago?.usuario?.NombreCompleto ?? "Sin Usuario";

                                 const currentGrayClass = grayClasses[index % grayClasses.length];
                                 
                                 if(walletConcilia.pago.conciliado === 'false' && walletConcilia.pago.rechazado != 'true'){

                                  return(
                                    
                                        <tr
                                        key={index}
                                        className={`${currentGrayClass} border-b border-gray-200 ${potentialDuplicates[walletConcilia.pago._id.$oid] ? 'bg-red-50' : ''}`}
                                        >
                                        <td className="px-6 py-3 text-sm leading-5 text-gray-500 text-center">
                                        {'PagoID: ' + shortenedID + ' / Usuario: ' + usuario}
                                        {potentialDuplicates[walletConcilia.pago._id.$oid] && (
                                          <div className="mt-1 text-xs text-red-600 font-semibold relative group">
                                            <span>⚠️ Posible duplicado</span>
                                            <div className="absolute left-0 top-full mt-2 w-64 bg-white shadow-lg rounded-md border border-gray-200 p-3 z-10 hidden group-hover:block">
                                              <p className="text-xs text-gray-700 mb-2">
                                                {potentialDuplicates[walletConcilia.pago._id.$oid].reason}
                                              </p>
                                              <ul className="text-xs text-gray-600">
                                                {potentialDuplicates[walletConcilia.pago._id.$oid].group.map((item, i) => (
                                                  <li key={i} className="mb-1 pb-1 border-b border-gray-100">
                                                    ID: {item.id.slice(-5)}<br/>
                                                    Fecha: {formatDate(item.date)}
                                                  </li>
                                                ))}
                                              </ul>
                                            </div>
                                          </div>
                                        )}
                                        </td>
                                         
                                        <td className={"px-6 py-3 text-sm leading-5  text-center "}>
                                        {walletConcilia.pago.referenciaTransferencia}
                                        </td>

                                        <td className={"px-6 py-3 text-sm leading-5  text-center "}>
                                        {console.log('Transaction date values:', {
                                          fechaTransferencia: walletConcilia.pago.fechaTransferencia,
                                          created_at: walletConcilia.pago.created_at,
                                          pago: walletConcilia.pago
                                        })}
                                        {walletConcilia.pago.fechaTransferencia ? formatDate(walletConcilia.pago.fechaTransferencia) : formatDate(walletConcilia.pago.created_at)}
                                        </td>

                                        <td className={"px-6 py-3 text-sm leading-5  text-center "}>
                                        {walletConcilia.pago.tipoPago}
                                        </td>

                                        <td className="px-6 py-3  text-sm leading-5 text-gray-500 text-center">
                                        {walletConcilia.pago.creditNumber}
                                        </td>

                                        <td className={"px-6 py-3 text-sm leading-5  text-center "}>
                                        {walletConcilia.pago.nombreCliente}
                                        </td>

                                        <td className="px-6 py-3  text-sm leading-5 text-gray-500 text-center">
                                        {walletConcilia.pago.nombreDepositante}
                                        

                                        </td>

                                        <td className="px-6 py-3  text-sm leading-5 text-gray-500 text-center">
                                        {walletConcilia.pago.bancoReceptor}

                                        </td>

                                        <td className="px-6 py-3  text-sm leading-5 text-gray-500 text-center">
                                        {walletConcilia.pago.libretaCobro}

                                        </td>

                                        <td className="px-6 py-3  text-sm leading-5 text-gray-500 text-center">
                                        {new Intl.NumberFormat("en-US", {
                                          style: "currency",
                                          currency: "USD"
                                        }).format(walletConcilia.pago.amount)}




                                        </td>
                                        
                                        <td className="px-6 py-3  text-sm leading-5 text-gray-500 text-center">
                                        {walletConcilia.pago.attachments.map((attachment, index) => {
                                          return(
                                            <div key={index}>
                                              <a className="inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-medium rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:text-gray-800 active:bg-gray-50 transition ease-in-out duration-150" href={attachment} target="_blank">{`Anexo`}</a>
                                            </div>
                                          )
                                        })}
                                        </td>

                                        <td className="px-6 py-3  text-sm leading-5 text-gray-500 text-center">
                                        
                                        <input
                                          key={walletConcilia.pago._id.$oid}
                                          name={`monto${walletConcilia.pago._id.$oid}`}
                                          type="checkbox"
                                          value={
                                            walletConcilia.pago.amount +
                                            "," +
                                            walletConcilia.pago._id.$oid +
                                            "," +
                                            walletConcilia.pago.creditNumber +
                                            "," +
                                            walletConcilia.pago.tipoPago +
                                            "," +
                                            monto +
                                            "," +
                                            walletConcilia.pago.customerNumber +
                                            "," +
                                            walletConcilia.pago.nombreCliente +
                                            "," +
                                            walletConcilia.pago.referenciaTransferencia +
                                            "," +
                                            walletConcilia.pago.bancoReceptor
                                          }
                                          onChange={(event) => handleCheck(event)}
                                          checked={isChecked[`monto${walletConcilia.pago._id.$oid}`] || false}
                                        />


                                        </td>

                                        <td className="px-6 py-3 text-sm leading-5 text-gray-500 text-center">
                                          {walletConcilia.pago.esPagoMasivo === 'true' ? 'Pago Masivo' : 'Canal Regular'}
                                        </td>
{/* 
                                        <td className="px-6 py-3 text-sm leading-5 text-gray-500 text-center">
                                          {walletConcilia.pago.amount
                                            ? new Intl.NumberFormat("en-US", {
                                                style: "currency",
                                                currency: "USD",
                                              }).format(walletConcilia.pago.amount)
                                            : "$0.00"}
                                        </td> */}

                                        <td className="max-w-xs px-6 py-3 text-xs leading-5 text-gray-500 text-center">

                                          <button className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded" onClick={() => handleRejectionClick(walletConcilia.pago._id.$oid)}>Rechazar</button>
                                        </td>

                                        

                                        


                                      </tr>

                                  )
                                  }
                                  

                                  })
                                
                                  }

                                  
                            </tbody>

                            <tfoot>

                              <tr>

                              <td className={"px-6 py-3 text-sm leading-5  text-center "}>



                              </td>
                              <td className={"px-6 py-3 text-sm leading-5  text-center "}>



                              </td>
                              <td className={"px-6 py-3 text-sm leading-5  text-center "}>



                              </td>
                              <td className={"px-6 py-3 text-sm leading-5  text-center "}>



                              </td>
                              <td className={"px-6 py-3 text-sm leading-5  text-center "}>



                              </td>
                              <td className={"px-6 py-3 text-sm leading-5  text-center "}>



                              </td>
                              <td className={"px-6 py-3 text-sm leading-5  text-center "}>



                              </td>
                              <td className={"px-6 py-3 text-sm leading-5  text-center "}>



                              </td>
                              <td className={"px-6 py-3 text-sm leading-5  text-center "}>



                              </td>
                              <td className={"px-6 py-3 text-sm leading-5  text-center "}>



                              </td>
                              <td className={"px-6 py-3 text-sm leading-5  text-center "}>



                              </td>
                              <td className={"px-6 py-3 text-sm leading-5  text-center "}>



                              </td>
                              <td className={"px-6 py-3 text-sm leading-5  text-center  "}>

                              <button className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded inline-flex items-center">
                                $
                                <span> {sum}</span>
                              </button>

                              

                              </td>
                              <td className={"px-6 py-3 text-sm leading-5  text-center "}>



                              </td>

                                
                              </tr>

                            </tfoot>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/*Inner Container End*/}

              </div>
              
              
            }
            <div className="fixed bottom-0 left-0 right-0 bg-white shadow-lg">
              <div className="mx-6 bg-white sm:rounded-t-lg">
                <div className="sm:col-span-2 p-5">
                    <dt className="text-left text-lg leading-5 font-medium text-gray-500">
                        Monto a conciliar (${sum})
                    </dt>
                    <dd className="flex text-left min-w-full min-h-full mt-1 text-sm leading-5 text-gray-900 ">
                        <input maxLength="14" className="mr-2 w-full h-10 pl-3 pr-8 text-base placeholder-gray-300 border border-gray-100 bg-gray-100 text-gray-700 rounded focus:shadow-outline" type="text" placeholder="Monto en USD" name="montoConciliar" onChange={handleInput}/>

                        <select 
                          name="nombreBanco" 
                          className={`mr-2 w-full h-10 pl-3 pr-8 text-base placeholder-gray-300 border ${nombreBanco === "" ? 'border-red-300' : 'border-gray-100'} bg-gray-100 text-gray-700 rounded focus:shadow-outline`} 
                          onChange={event => setNombreBanco(event.target.value)}
                          required
                        >
                            <option value="">Seleccionar Banco (Requerido)</option>
                            <option value="TESTBanco Nacional de Panamá - 10000266755">Banco Nacional de Panamá - 10000266755</option>
                            <option value="Banesco, S.A. (2) - 120000090319">Banesco, S.A. (2) - 120000090319</option>
                            <option value="Banistmo, S.A. (1) - 114339105">Banistmo, S.A. (1) - 114339105</option>
                            <option value="Banistmo, S.A. (1) - 117225764">Banistmo, S.A. (1) - 117225764</option>
                        </select>

                        <input className="mr-2 w-full h-10 pl-3 pr-8 text-base placeholder-gray-300 border border-gray-100 bg-gray-100 text-gray-700 rounded focus:shadow-outline " type="text" placeholder="Notas" name="detalleNotas" onChange={event => setDetalleNotas(event.target.value)}/>

                        <button 
                          onClick={handleConciliationClick} 
                          className={
                            parseFloat(sum) === parseFloat(monto) && nombreBanco && nombreBanco !== "" 
                              ? 'relative ml-2 bg-yellow-600 h-10 right-0 flex items-center px-8 font-bold rounded border border-yellow-600 text-white' 
                              : 'relative ml-2 h-10 right-0 flex items-center px-8 font-bold bg-cool-gray-600 rounded hover:bg-gray-600 border border-gray-600 text-gray-400'
                          } 
                          disabled={parseFloat(sum) === parseFloat(monto) && nombreBanco && nombreBanco !== "" ? false : true}
                        >
                          Conciliar
                        </button>
                    </dd>
                </div>
            </div>
          </div>
      </Template>
    );

}

export default WalletConciliaciones;
