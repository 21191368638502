import React, { useEffect, useState } from "react";
import axios from "axios";
import { Bars } from 'react-loader-spinner';
import { orangeClasses, grayClasses } from "../Design/classes";
import { formatDate } from "../Helpers/Helpers";
import NumberFormat from 'react-number-format';
import { v4 as uuid_v4 } from "uuid";
import { useReactToPrint } from "react-to-print";
import Moment from 'moment';

// Debug helper function to analyze transaction structure
const analyzeTransactionStructure = (transaction) => {
  if (typeof transaction !== 'string') return;
  
  const parts = transaction.split(',');
  console.log('==== Transaction Structure Analysis ====');
  console.log('Full transaction string:', transaction);
  console.log('Total parts:', parts.length);
  
  // Log all parts with their index
  parts.forEach((part, index) => {
    console.log(`Index ${index}: ${part}`);
  });
  
  // Try to identify which field might be the nombreDepositante
  // Look for values that look like names (containing spaces, not looking like numbers, etc.)
  parts.forEach((part, index) => {
    if (part && 
        part.includes(' ') && 
        isNaN(Number(part)) && 
        !part.match(/^\d{2}\/\d{2}\/\d{4}$/) && // Not a date format
        index !== 6) { // Not the cliente name which we know is at index 6
      console.log(`Potential nombreDepositante found at index ${index}: ${part}`);
    }
  });
  
  console.log('======================================');
};

const WalletConciliados = (props) => {

  const [walletData, setWalletData] = useState([]);
  const [loading, setLoading] = useState(true);
  const { currentUser } = props;
  const [filterText, setFilterText] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const [expandedRows, setExpandedRows] = useState({});
  const [paymentAttachments, setPaymentAttachments] = useState({});
  const [paymentDetails, setPaymentDetails] = useState({});
  
  // New filter states
  const [selectedBanco, setSelectedBanco] = useState('');
  const [selectedFechaRango, setSelectedFechaRango] = useState('');
  const [selectedMontoRango, setSelectedMontoRango] = useState('');
  
  // Filter options
  const [bancos, setBancos] = useState([]);
  const fechaRangos = ['Hoy', 'Última Semana', 'Último Mes', 'Último Año', 'Todo'];
  const montoRangos = ['0-1000', '1000-5000', '5000-10000', '10000+', 'Todo'];

  const includesFilterText = (text) => {
    return String(text).toLowerCase().includes(filterText.toLowerCase());
  };

  useEffect(() => {
    setLoading(true);
    loadConsulta();
  }, []);
  
  // Populate filter options when data loads
  useEffect(() => {
    if (walletData.length > 0) {
      // Get unique values for banco filter
      const uniqueBancos = [...new Set(
        walletData
          .filter(item => item.conciliaciones.nombreBanco)
          .map(item => item.conciliaciones.nombreBanco)
      )];
      
      setBancos(uniqueBancos.sort());
    }
  }, [walletData]);

  // Update to auto-expand rows with matching transactions when searching
  useEffect(() => {
    if (filterText) {
      // Find all conciliation rows that have matching transactions
      const matchingRows = {};
      walletData.forEach(walletConcilia => {
        const rowId = walletConcilia.conciliaciones._id?.$oid;
        if (!rowId) return;
        
        // Check if this row has matching transactions
        const hasMatchingTransaction = walletConcilia.conciliaciones.transactions && 
          walletConcilia.conciliaciones.transactions.some((tran) => {
            if (tran !== 0 && typeof tran === 'string') {
              const separatedObject = tran.split(',');
              
              // Search in all transaction fields
              const amount = separatedObject[0] || '';
              const pagoID = separatedObject[1] || '';
              const creditNumber = separatedObject[2] || '';
              const paymentType = separatedObject[3] || '';
              const nombreCliente = separatedObject[6] || '';
              const reference = separatedObject[7] || '';
              
              // Create search text
              const transactionText = 
                amount + ' ' + 
                pagoID + ' ' + 
                creditNumber + ' ' + 
                paymentType + ' ' + 
                nombreCliente + ' ' + 
                reference;
              
              return String(transactionText).toLowerCase().includes(filterText.toLowerCase());
            }
            return false;
          });
        
        if (hasMatchingTransaction) {
          matchingRows[rowId] = true;
          
          // Fetch payment details for all transactions in this row
          if (walletConcilia.conciliaciones.transactions) {
            walletConcilia.conciliaciones.transactions.forEach(tran => {
              if (tran !== 0 && typeof tran === 'string') {
                const separatedObject = tran.split(',');
                const pagoID = separatedObject[1];
                if (pagoID) {
                  fetchPaymentDetails(pagoID);
                }
              }
            });
          }
        }
      });
      
      // Auto-expand matching rows
      if (Object.keys(matchingRows).length > 0) {
        setExpandedRows(prevState => ({
          ...prevState,
          ...matchingRows
        }));
      }
    }
  }, [filterText, walletData]);

  const loadConsulta = async () => {
    try {
      console.log('Loading conciliaciones...');
      const res = await axios.get(`/api/v1/safi/get_all_conciliaciones.json`);
      console.log('Conciliaciones loaded:', res.data);
      
      // Log the structure of the first conciliation to understand the data format
      if (res.data.conciliaciones && res.data.conciliaciones.length > 0) {
        console.log('First conciliation structure:', res.data.conciliaciones[0]);
        
        // Check transactions data
        const firstTransactions = res.data.conciliaciones[0].conciliaciones.transactions;
        if (firstTransactions && firstTransactions.length > 0) {
          console.log('First transaction example:', firstTransactions[0]);
          if (typeof firstTransactions[0] === 'string') {
            // Use our analysis helper
            analyzeTransactionStructure(firstTransactions[0]);
          }
        }
      }
      
      let sortedData = res.data.conciliaciones.sort((a, b) => new Date(b.conciliaciones.created_at) - new Date(a.conciliaciones.created_at));
      setWalletData(sortedData);
      setLoading(false);
    } catch (error) {
      console.error('Error loading conciliaciones:', error);
      setLoading(false);
    }
  };

  const fetchPaymentAttachments = async (pagoID) => {
    try {
      console.log('Fetching attachments for payment ID:', pagoID);
      const response = await axios.get(`/api/v1/safi/get_pago_details?id=${pagoID}`);
      console.log('Response from server:', response.data);
      
      if (response.data.attachments) {
        console.log('Attachments found:', response.data.attachments);
        setPaymentAttachments(prev => ({
          ...prev,
          [pagoID]: response.data.attachments
        }));
      } else {
        console.log('No attachments found for payment ID:', pagoID);
      }
    } catch (error) {
      console.error('Error fetching attachments:', error);
      console.error('Error details:', error.response?.data);
    }
  };

  // New function to fetch payment details
  const fetchPaymentDetails = async (pagoID) => {
    try {
      // Fetch the full payment details from the server
      const response = await axios.get(`/api/v1/safi/get_pago?id=${pagoID}`);
      console.log('Payment details:', response.data);
      
      if (response.data && response.data.pago) {
        setPaymentDetails(prev => ({
          ...prev,
          [pagoID]: response.data.pago
        }));
      }
    } catch (error) {
      console.error('Error fetching payment details:', error);
    }
  };

  const toggleRowExpansion = (rowId) => {
    setExpandedRows(prev => {
      const newState = {
        ...prev,
        [rowId]: !prev[rowId]
      };
      
      // If we're expanding the row, fetch payment details for all transactions
      if (newState[rowId]) {
        const conciliation = walletData.find(item => 
          item.conciliaciones._id && item.conciliaciones._id.$oid === rowId
        );
        
        if (conciliation && conciliation.conciliaciones.transactions) {
          conciliation.conciliaciones.transactions.forEach(tran => {
            if (tran !== 0 && typeof tran === 'string') {
              const separatedObject = tran.split(',');
              const pagoID = separatedObject[1];
              if (pagoID) {
                fetchPaymentDetails(pagoID);
              }
            }
          });
        }
      }
      
      return newState;
    });
  };

  // Reset all filters
  const handleReset = () => {
    setFilterText('');
    setSelectedBanco('');
    setSelectedFechaRango('');
    setSelectedMontoRango('');
    setCurrentPage(1);
  };

  const filteredData = walletData.filter(walletConcilia => {
    let shortenedID = walletConcilia.conciliaciones._id && walletConcilia.conciliaciones._id.$oid ? String(walletConcilia.conciliaciones._id.$oid).slice(-5) : '';
    let usuario = walletConcilia.conciliaciones.usuario?.NombreCompleto ?? "Sin Usuario";
    const idText = 'PagoID: ' + shortenedID + ' / Usuario: ' + usuario;
    const bancoText = walletConcilia.conciliaciones.nombreBanco;
    const montoText = walletConcilia.conciliaciones.montoAConciliar;
    const fechaText = walletConcilia.conciliaciones.created_at;
    const notasText = walletConcilia.conciliaciones.detalleNotas;
    
    // Search in transactions data
    const hasMatchingTransaction = walletConcilia.conciliaciones.transactions && 
      walletConcilia.conciliaciones.transactions.some((tran) => {
        if (tran !== 0 && typeof tran === 'string') {
          const separatedObject = tran.split(',');
          
          // Search in all transaction fields
          const amount = separatedObject[0] || '';
          const pagoID = separatedObject[1] || '';
          const creditNumber = separatedObject[2] || '';
          const paymentType = separatedObject[3] || '';
          const nombreCliente = separatedObject[6] || '';
          const reference = separatedObject[7] || '';
          
          // Also search in payment details if available
          const paymentDetail = paymentDetails[pagoID] || {};
          const depositante = paymentDetail.nombreDepositante || '';
          const clienteID = paymentDetail.customerNumber || '';
          
          // Combine all searchable transaction fields
          const transactionText = 
            amount + ' ' + 
            pagoID + ' ' + 
            creditNumber + ' ' + 
            paymentType + ' ' + 
            nombreCliente + ' ' + 
            reference + ' ' + 
            depositante + ' ' + 
            clienteID;
          
          return includesFilterText(transactionText);
        }
        return false;
      });
    
    // Text filter for main conciliation data
    const mainDataMatches = 
      includesFilterText(idText) ||
      includesFilterText(bancoText) ||
      includesFilterText(montoText) ||
      includesFilterText(fechaText) ||
      includesFilterText(notasText);
    
    // Combined text filter (main data OR transaction data)
    const textFilter = mainDataMatches || hasMatchingTransaction;
      
    // Banco filter
    const bancoFilter = !selectedBanco || walletConcilia.conciliaciones.nombreBanco === selectedBanco;
    
    // Fecha filter
    let fechaFilter = true;
    if (selectedFechaRango) {
      const fechaConciliacion = new Date(walletConcilia.conciliaciones.created_at);
      const today = new Date();
      const oneDay = 24 * 60 * 60 * 1000;
      const oneWeek = 7 * oneDay;
      const oneMonth = 30 * oneDay;
      const oneYear = 365 * oneDay;
      
      switch (selectedFechaRango) {
        case 'Hoy':
          fechaFilter = (today - fechaConciliacion) < oneDay;
          break;
        case 'Última Semana':
          fechaFilter = (today - fechaConciliacion) < oneWeek;
          break;
        case 'Último Mes':
          fechaFilter = (today - fechaConciliacion) < oneMonth;
          break;
        case 'Último Año':
          fechaFilter = (today - fechaConciliacion) < oneYear;
          break;
        case 'Todo':
        default:
          fechaFilter = true;
      }
    }
    
    // Monto filter
    let montoFilter = true;
    if (selectedMontoRango && selectedMontoRango !== 'Todo') {
      const monto = parseFloat(walletConcilia.conciliaciones.montoAConciliar);
      const [min, max] = selectedMontoRango.split('-');
      
      if (max === '+') {
        montoFilter = monto >= parseFloat(min);
      } else {
        montoFilter = monto >= parseFloat(min) && monto <= parseFloat(max);
      }
    }
    
    return textFilter && bancoFilter && fechaFilter && montoFilter;
  });

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  const handlePageChange = (direction) => {
    if (direction === 'next' && currentPage < Math.ceil(filteredData.length / itemsPerPage)) {
      setCurrentPage(currentPage + 1);
    } else if (direction === 'previous' && currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const formatDate = (dateString) => {
    return Moment(dateString).format('DD/MM/YYYY');
  };

  return (
    <>
      {loading ?
        <div className="flex h-screen">
          <div className="m-auto">
            <Bars
              color="#00BFFF"
              height={100}
              width={100}
              timeout={20000}
            />
          </div>
        </div>
       :
        <div className="overflow-y-auto">
          <div className="mx-6 mt-6 bg-white shadow overflow-hidden sm:rounded-lg">
            <div className="px-4 py-5 border-b border-gray-200 sm:px-6">
              <h3 className="text-left text-lg leading-6 font-medium text-gray-900">
                Transacciones Conciliadas
              </h3>
            </div>

            {/* Filter Section */}
            <div className="p-5 flex flex-wrap gap-4 items-center">
              <label className="flex gap-x-2 items-baseline">
                <span className="text-gray-700">Buscar: </span>
                <input
                  type="text"
                  placeholder="Buscar por cliente, crédito, referencia, monto, etc."
                  value={filterText}
                  onChange={(e) => setFilterText(e.target.value)}
                  className="rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                />
              </label>

              <label className="flex gap-x-2 items-baseline">
                <span className="text-gray-700">Banco: </span>
                <select
                  value={selectedBanco}
                  onChange={(e) => {
                    setSelectedBanco(e.target.value);
                    setCurrentPage(1);
                  }}
                  className="rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                >
                  <option value="">Todos</option>
                  {bancos.map((banco, index) => (
                    <option key={index} value={banco}>{banco}</option>
                  ))}
                </select>
              </label>
              
              <label className="flex gap-x-2 items-baseline">
                <span className="text-gray-700">Fecha: </span>
                <select
                  value={selectedFechaRango}
                  onChange={(e) => {
                    setSelectedFechaRango(e.target.value);
                    setCurrentPage(1);
                  }}
                  className="rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                >
                  <option value="">Todos</option>
                  {fechaRangos.map((rango, index) => (
                    <option key={index} value={rango}>{rango}</option>
                  ))}
                </select>
              </label>
              
              <label className="flex gap-x-2 items-baseline">
                <span className="text-gray-700">Monto: </span>
                <select
                  value={selectedMontoRango}
                  onChange={(e) => {
                    setSelectedMontoRango(e.target.value);
                    setCurrentPage(1);
                  }}
                  className="rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                >
                  <option value="">Todos</option>
                  {montoRangos.map((rango, index) => (
                    <option key={index} value={rango}>{rango}</option>
                  ))}
                </select>
              </label>

              <button
                onClick={handleReset}
                className="ml-auto px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                Limpiar Filtros
              </button>
            </div>

            <div className="px-7 py-5 flex flex-wrap -mx-5 mb-2">
              <div className="ml-2 mt-2 w-full mr-2 overflow-hidden">
                <div className="shadow sm:rounded-lg border-b border-gray-200 overflow-hidden">
                  <div className="overflow-x-auto" style={{ maxWidth: '100%', overflowY: 'visible' }}>
                    <table className="min-w-full table-fixed" style={{ width: 'max-content', minWidth: '100%' }}>
                      <thead>
                        <tr>
                          <th className="sticky top-0 px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider w-60">
                            ID Conciliacion / Usuario
                          </th>
                          <th className="sticky top-0 px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider w-40">
                            Banco
                          </th>
                          <th className="sticky top-0 px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider w-40">
                            Transacciones
                          </th>
                          <th className="sticky top-0 px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider w-40">
                            Monto Conciliado
                          </th>
                          <th className="sticky top-0 px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider w-40">
                            Fecha Conciliacion
                          </th>
                          <th className="sticky top-0 px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider w-60">
                            Notas
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {currentItems?.map((walletConcilia, index) => {
                          let shortenedID = walletConcilia.conciliaciones._id && walletConcilia.conciliaciones._id.$oid ? String(walletConcilia.conciliaciones._id.$oid).slice(-5) : '';
                          let usuario = walletConcilia.conciliaciones.usuario?.NombreCompleto ?? "Sin Usuario";
                          const currentGrayClass = grayClasses[index % grayClasses.length];
                          const rowId = walletConcilia.conciliaciones._id?.$oid || index;
                          const isExpanded = expandedRows[rowId] || false;
                          const transactionsCount = walletConcilia.conciliaciones.transactions?.filter(t => t !== 0 && typeof t === 'string').length || 0;

                          return(
                            <React.Fragment key={index}>
                              <tr
                                className={`${currentGrayClass} border-b border-gray-200 cursor-pointer hover:bg-gray-100`}
                                onClick={() => toggleRowExpansion(rowId)}
                              >
                                <td className="px-6 py-2 whitespace-nowrap text-sm text-gray-500 truncate">
                                  {'PagoID: ' + shortenedID + ' / Usuario: ' + usuario}
                                </td>

                                <td className="px-6 py-2 whitespace-nowrap text-sm text-gray-500 truncate">
                                  {walletConcilia.conciliaciones.nombreBanco}
                                </td>

                                <td className="px-6 py-2 whitespace-nowrap text-sm text-gray-500">
                                  <div className="flex items-center">
                                    <span className="font-medium text-indigo-600">
                                      {transactionsCount} {transactionsCount === 1 ? 'transacción' : 'transacciones'}
                                    </span>
                                    <svg 
                                      className={`ml-2 h-4 w-4 text-gray-500 transform transition-transform ${isExpanded ? 'rotate-180' : ''}`} 
                                      xmlns="http://www.w3.org/2000/svg" 
                                      viewBox="0 0 20 20" 
                                      fill="currentColor"
                                    >
                                      <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd" />
                                    </svg>
                                  </div>
                                </td>
                                
                                <td className="px-6 py-2 whitespace-nowrap text-sm text-gray-500 truncate">
                                  {new Intl.NumberFormat("en-US", {
                                    style: "currency",
                                    currency: "USD"
                                  }).format(walletConcilia.conciliaciones.montoAConciliar)}
                                </td>

                                <td className="px-6 py-2 whitespace-nowrap text-sm text-gray-500 truncate">
                                  {formatDate(walletConcilia.conciliaciones.created_at)}
                                </td>
                                
                                <td className="px-6 py-2 whitespace-nowrap text-sm text-gray-500 truncate">
                                  {walletConcilia.conciliaciones.detalleNotas}
                                </td>


                              </tr>
                              
                              {isExpanded && (
                                <tr>
                                  <td colSpan="7" className="px-6 py-4 bg-white">
                                    <div>
                                      <h4 className="text-sm font-medium text-gray-900 mb-4">Detalle de Transacciones</h4>
                                      <div className="overflow-hidden">
                                        <table className="min-w-full divide-y divide-gray-200">
                                          <thead>
                                            <tr>
                                              <th className="px-6 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                Referencia
                                              </th>
                                              <th className="px-6 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                Cliente
                                              </th>
                                              <th className="px-6 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                Crédito
                                              </th>
                                              <th className="px-6 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                Monto
                                              </th>
                                              <th className="px-6 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                Depositante
                                              </th>
                                              <th className="px-6 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                Anexo
                                              </th>
                                            </tr>
                                          </thead>
                                          <tbody className="bg-white divide-y divide-gray-200">
                                            {walletConcilia.conciliaciones.transactions && walletConcilia.conciliaciones.transactions.map((tran, idx) => {
                                              if (tran !== 0 && typeof tran === 'string') {
                                                const separatedObject = tran.split(',');
                                                
                                                // Log the full transaction string and separated object for debugging
                                                console.log('Full transaction string:', tran);
                                                console.log('Separated object:', separatedObject);
                                                
                                                var pagoID = separatedObject[1];
                                                var creditoID = separatedObject[2];
                                                var montoPago = separatedObject[0];
                                                var tipoPago = separatedObject[3] ?? '';
                                                
                                                // Get additional details from the payment details if available
                                                const paymentDetail = paymentDetails[pagoID];
                                                console.log('Payment detail for:', pagoID, paymentDetail);
                                                
                                                var nombreCliente = paymentDetail?.nombreCliente ?? '';
                                                var numeroReferencia = paymentDetail?.referenciaTransferencia ?? '';
                                                var nombreDepositante = paymentDetail?.nombreDepositante ?? 'N/A';
                                                
                                                // Check if this transaction matches the search query
                                                const transactionSearchText = 
                                                  montoPago + ' ' + 
                                                  pagoID + ' ' + 
                                                  creditoID + ' ' + 
                                                  tipoPago + ' ' + 
                                                  nombreCliente + ' ' + 
                                                  numeroReferencia + ' ' + 
                                                  nombreDepositante;
                                                
                                                const isHighlighted = filterText && 
                                                  transactionSearchText.toLowerCase().includes(filterText.toLowerCase());
                                                
                                                return (
                                                  <tr 
                                                    key={idx} 
                                                    className={`hover:bg-gray-50 ${isHighlighted ? 'bg-yellow-50' : ''}`}
                                                  >
                                                    <td className="px-6 py-2 whitespace-nowrap text-sm text-gray-500 truncate">
                                                      {numeroReferencia || 'N/A'}
                                                    </td>
                                                    <td className="px-6 py-2 whitespace-nowrap text-sm text-gray-500 truncate">
                                                      {nombreCliente || 'N/A'}
                                                    </td>
                                                    <td className="px-6 py-2 whitespace-nowrap text-sm text-gray-500 truncate">
                                                      {creditoID || 'N/A'}
                                                    </td>
                                                    <td className="px-6 py-2 whitespace-nowrap text-sm text-gray-500 truncate">
                                                      {new Intl.NumberFormat("en-US", {
                                                        style: "currency",
                                                        currency: "USD"
                                                      }).format(montoPago)}
                                                    </td>
                                                    <td className="px-6 py-2 whitespace-nowrap text-sm text-gray-500 truncate">
                                                      {nombreDepositante || 'N/A'}
                                                    </td>
                                                    <td className="px-6 py-2 whitespace-nowrap text-sm text-gray-500">
                                                      {paymentAttachments[pagoID] ? (
                                                        paymentAttachments[pagoID].length > 0 ? (
                                                          paymentAttachments[pagoID].map((url, attachIdx) => (
                                                            <a
                                                              key={attachIdx}
                                                              href={url}
                                                              target="_blank"
                                                              rel="noopener noreferrer"
                                                              className="text-indigo-600 hover:text-indigo-900"
                                                              onClick={(e) => e.stopPropagation()}
                                                            >
                                                              Anexo
                                                            </a>
                                                          ))
                                                        ) : (
                                                          <span className="text-gray-500">Sin Anexo</span>
                                                        )
                                                      ) : (
                                                        <button
                                                          onClick={(e) => {
                                                            e.stopPropagation();
                                                            fetchPaymentAttachments(pagoID);
                                                          }}
                                                          className="text-indigo-600 hover:text-indigo-900"
                                                        >
                                                          Ver Anexos
                                                        </button>
                                                      )}
                                                    </td>
                                                  </tr>
                                                );
                                              }
                                              return null;
                                            })}
                                          </tbody>
                                        </table>
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                              )}
                            </React.Fragment>
                          )
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>

            <div className="px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6">
              <div className="flex-1 flex sm:items-center sm:justify-between">
                <div>
                  <p className="text-sm text-gray-700">
                    Mostrando <span className="font-medium">{(currentPage - 1) * itemsPerPage + 1}</span> a{' '}
                    <span className="font-medium">
                      {Math.min(currentPage * itemsPerPage, filteredData.length)}
                    </span>{' '}
                    de <span className="font-medium">{filteredData.length}</span> resultados
                  </p>
                </div>
                <div>
                  <nav className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px" aria-label="Pagination">
                    <button
                      onClick={() => setCurrentPage(1)}
                      disabled={currentPage === 1}
                      className="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                    >
                      <span className="sr-only">Primera</span>
                      <span>Primera</span>
                    </button>
                    <button
                      onClick={() => handlePageChange('previous')}
                      disabled={currentPage === 1}
                      className="relative inline-flex items-center px-2 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                    >
                      <span className="sr-only">Anterior</span>
                      <span>Anterior</span>
                    </button>
                    
                    {[...Array(Math.min(5, Math.ceil(filteredData.length / itemsPerPage)))].map((_, i) => {
                      let pageNum;
                      const totalPages = Math.ceil(filteredData.length / itemsPerPage);
                      
                      if (totalPages <= 5) {
                        pageNum = i + 1;
                      } else if (currentPage <= 3) {
                        pageNum = i + 1;
                      } else if (currentPage >= totalPages - 2) {
                        pageNum = totalPages - 4 + i;
                      } else {
                        pageNum = currentPage - 2 + i;
                      }
                      
                      return (
                        <button
                          key={i}
                          onClick={() => setCurrentPage(pageNum)}
                          className={`relative inline-flex items-center px-4 py-2 border ${
                            currentPage === pageNum
                              ? 'z-10 bg-indigo-50 border-indigo-500 text-indigo-600'
                              : 'border-gray-300 bg-white text-gray-500 hover:bg-gray-50'
                          } text-sm font-medium`}
                        >
                          {pageNum}
                        </button>
                      );
                    })}
                    
                    <button
                      onClick={() => handlePageChange('next')}
                      disabled={currentPage === Math.ceil(filteredData.length / itemsPerPage)}
                      className="relative inline-flex items-center px-2 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                    >
                      <span className="sr-only">Siguiente</span>
                      <span>Siguiente</span>
                    </button>
                    <button
                      onClick={() => setCurrentPage(Math.ceil(filteredData.length / itemsPerPage))}
                      disabled={currentPage === Math.ceil(filteredData.length / itemsPerPage)}
                      className="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                    >
                      <span className="sr-only">Última</span>
                      <span>Última</span>
                    </button>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
      }
    </>
  );
}

export default WalletConciliados;
