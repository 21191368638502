export const formatDate = (dateString) => {
    if (!dateString) return '';
  
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
  
    return `${month}/${day}/${year}`;
  };

  export const isFormValid = (nombreTarjetaHabiente, nombreDepositante, montoAPagar, fechaTransferencia, referenciaTransferencia, ultimosDigitos /*, other inputs */) => {
    return (
      montoAPagar &&
      (nombreDepositante || nombreTarjetaHabiente) &&
      fechaTransferencia &&
      (referenciaTransferencia.trim().length > 0 || ultimosDigitos.trim().length > 0) 
      // Add more input validation conditions as needed
    );
  };

  export function formatNumber(value, decimalPlaces = 2) {
    return parseFloat(value)
      .toFixed(decimalPlaces)
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };
  
  export const getStyle = (DiasAtraso) => {
    let backgroundColor, color;
    if (DiasAtraso <= 30) {
      backgroundColor = 'lightgreen';
      color = 'green';
    } else if (DiasAtraso <= 60) {
      backgroundColor = 'yellow';
      color = 'goldenrod';
    } else {
      backgroundColor = 'pink';
      color = 'red';
    }
    return {backgroundColor, color, padding: '10px', borderRadius: '5px'};
  };

  export const formatCurrency = (value) => {
    if (value === null || value === undefined) return '';
    
    return new Intl.NumberFormat('es-PA', {
      style: 'currency',
      currency: 'PAB',
      minimumFractionDigits: 2
    }).format(value);
  };