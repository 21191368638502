import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import { Bars } from 'react-loader-spinner';
import { formatDate } from '../Helpers/Helpers';
import PaymentReceiptModal from '../PagoCredito/PaymentReceiptModal';

const WalletPagos = () => {
  const [walletData, setWalletData] = useState({pagos: []});
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState('');
  const [currentTablePage, setCurrentTablePage] = useState(0);
  const itemsPerPage = 20; // Change according to your needs
  
  // Receipt printing states
  const [selectedPayment, setSelectedPayment] = useState(null);
  const [showReceiptModal, setShowReceiptModal] = useState(false);
  const [loadingReceipt, setLoadingReceipt] = useState(false);
  
  // Filter states
  const [selectedTipoPago, setSelectedTipoPago] = useState('');
  const [selectedBancoReceptor, setSelectedBancoReceptor] = useState('');
  const [selectedBancoEmisor, setSelectedBancoEmisor] = useState('');

  // Lists for filter options
  const [tiposPago, setTiposPago] = useState([]);
  const [bancosReceptores, setBancosReceptores] = useState([]);
  const [bancosEmisores, setBancosEmisores] = useState([]);

  const isMounted = useRef(true);

  useEffect(() => {
    loadConsulta();
  }, []);

  // Populate filter options when data loads
  useEffect(() => {
    if (walletData.pagos && walletData.pagos.length > 0) {
      // Get unique values for each filter
      const uniqueTiposPago = [...new Set(walletData.pagos
        .filter(item => item.pago.tipoPago)
        .map(item => item.pago.tipoPago))];
      
      const uniqueBancosReceptores = [...new Set(walletData.pagos
        .filter(item => item.pago.bancoReceptor)
        .map(item => item.pago.bancoReceptor))];

      const uniqueBancosEmisores = [...new Set(walletData.pagos
        .filter(item => item.pago.bancoEmisor)
        .map(item => item.pago.bancoEmisor))];

      setTiposPago(uniqueTiposPago.sort());
      setBancosReceptores(uniqueBancosReceptores.sort());
      setBancosEmisores(uniqueBancosEmisores.sort());
    }
  }, [walletData]);

  const loadConsulta = async () => {
    try {
      // For the payments view, we need all payments without filtering by credit number
      const res = await axios.get(`/api/v1/safi/get_all_pagos.json`);
      if (isMounted.current) {
        const sortedData = [...res.data.pagos].sort((a, b) => new Date(b.pago.created_at) - new Date(a.pago.created_at));
        setWalletData({...res.data, pagos: sortedData});
        setLoading(false);
      }
    } catch (error) {
      console.error('Error fetching payments:', error);
      alert('Error al cargar los pagos. Por favor intente nuevamente más tarde.');
      setLoading(false);
    }
  };

  // Fetch payment details for receipt printing
  const fetchPaymentForPrinting = async (pagoID) => {
    setLoadingReceipt(true);
    try {
      const response = await axios.get(`/api/v1/safi/get_pago?id=${pagoID}`);
      if (response.data && response.data.pago) {
        setSelectedPayment(response.data.pago);
        setShowReceiptModal(true);
      }
    } catch (error) {
      console.error('Error fetching payment details:', error);
      alert('Error al cargar los detalles del pago. Por favor intente nuevamente.');
    } finally {
      setLoadingReceipt(false);
    }
  };

  // Export to Excel
  const handleExportExcel = () => {
    const queryParams = {
      format: 'xlsx',
      tipo_pago: selectedTipoPago || null,
      banco_receptor: selectedBancoReceptor || null,
      banco_emisor: selectedBancoEmisor || null,
      search: search || null
    };
    
    // Create the URL with query parameters
    const queryString = new URLSearchParams(queryParams).toString();
    const exportUrl = `/api/v1/safi/export_pagos?${queryString}`;
    
    // Redirect to the export URL
    window.location.href = exportUrl;
  };

  // Export to CSV
  const handleExportCSV = () => {
    const queryParams = {
      format: 'csv',
      tipo_pago: selectedTipoPago || null,
      banco_receptor: selectedBancoReceptor || null,
      banco_emisor: selectedBancoEmisor || null,
      search: search || null
    };
    
    // Create the URL with query parameters
    const queryString = new URLSearchParams(queryParams).toString();
    const exportUrl = `/api/v1/safi/export_pagos?${queryString}`;
    
    console.log('CSV Export URL:', exportUrl);
    console.log('CSV Export Params:', queryParams);
    
    // Use fetch to make the request and handle the response
    fetch(exportUrl, {
      method: 'GET',
      headers: {
        'Accept': 'text/csv'
      }
    })
    .then(response => response.blob())
    .then(blob => {
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `historico_pagos_${new Date().toISOString().split('T')[0]}.csv`;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    })
    .catch(error => {
      console.error('Error downloading CSV:', error);
      alert('Error al descargar el archivo CSV. Por favor intente nuevamente.');
    });
  };

  // Reset all filters
  const handleReset = () => {
    setSearch('');
    setSelectedTipoPago('');
    setSelectedBancoReceptor('');
    setSelectedBancoEmisor('');
    setCurrentTablePage(0);
  };

  // Apply all filters
  const filteredData = walletData.pagos
    .filter((data) => {
      // Text search filter
      const textFilter = 
        (data.pago.nombreCliente && data.pago.nombreCliente.toLowerCase().includes(search.toLowerCase())) ||
        (data.pago.creditNumber && data.pago.creditNumber.toString().toLowerCase().includes(search.toLowerCase())) ||
        (data.pago.nombreDepositante && data.pago.nombreDepositante.toLowerCase().includes(search.toLowerCase()));

      // Dropdown filters
      const tipoFilter = !selectedTipoPago || data.pago.tipoPago === selectedTipoPago;
      const bancoReceptorFilter = !selectedBancoReceptor || data.pago.bancoReceptor === selectedBancoReceptor;
      const bancoEmisorFilter = !selectedBancoEmisor || data.pago.bancoEmisor === selectedBancoEmisor;

      return textFilter && tipoFilter && bancoReceptorFilter && bancoEmisorFilter;
    });

  const totalPages = Math.ceil(filteredData.length / itemsPerPage);

  const handleSearch = (event) => {
    setSearch(event.target.value);
    setCurrentTablePage(0); // Reset to first page when searching
  };

  const tableData = filteredData.slice(
    currentTablePage * itemsPerPage,
    (currentTablePage + 1) * itemsPerPage
  );

  const anterior = () => {
    if (currentTablePage > 0) setCurrentTablePage(currentTablePage - 1);
  };

  const siguiente = () => {
    if ((currentTablePage + 1) * itemsPerPage < filteredData.length)
      setCurrentTablePage(currentTablePage + 1);
  };

  return (
    <>      
      {loading ? 
        <div className="flex h-screen">
          <div className="m-auto">
            <Bars
              color="#00BFFF"
              height={100}
              width={100}
              timeout={20000}
            />
          </div>
        </div>
       :
        <div className="overflow-y-auto">
          <div className="mx-6 mt-6 bg-white shadow overflow-hidden sm:rounded-lg">
            <div className="px-4 py-5 border-b border-gray-200 sm:px-6">
              <h3 className="text-left text-lg leading-6 font-medium text-gray-900">
                Historico de Pagos
              </h3>
            </div>

            {/* Filter Section */}
            <div className="p-5 flex flex-wrap gap-4 items-center">
              <label className="flex gap-x-2 items-baseline">
                <span className="text-gray-700">Buscar: </span>
                <input
                  type="text" 
                  value={search} 
                  onChange={handleSearch} 
                  placeholder="CreditoID / Nombre Cliente / Nombre Depositante" 
                  className="rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                />
              </label>

              <label className="flex gap-x-2 items-baseline">
                <span className="text-gray-700">Tipo de Pago: </span>
                <select
                  value={selectedTipoPago}
                  onChange={(e) => {
                    setSelectedTipoPago(e.target.value);
                    setCurrentTablePage(0); // Reset to first page when filtering
                  }}
                  className="rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                >
                  <option value="">Todos</option>
                  {tiposPago.map((tipo, index) => (
                    <option key={index} value={tipo}>{tipo}</option>
                  ))}
                </select>
              </label>

              <label className="flex gap-x-2 items-baseline">
                <span className="text-gray-700">Banco Emisor: </span>
                <select
                  value={selectedBancoEmisor}
                  onChange={(e) => {
                    setSelectedBancoEmisor(e.target.value);
                    setCurrentTablePage(0); // Reset to first page when filtering
                  }}
                  className="rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                >
                  <option value="">Todos</option>
                  {bancosEmisores.map((banco, index) => (
                    <option key={index} value={banco}>{banco}</option>
                  ))}
                </select>
              </label>

              <label className="flex gap-x-2 items-baseline">
                <span className="text-gray-700">Banco Receptor: </span>
                <select
                  value={selectedBancoReceptor}
                  onChange={(e) => {
                    setSelectedBancoReceptor(e.target.value);
                    setCurrentTablePage(0); // Reset to first page when filtering
                  }}
                  className="rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                >
                  <option value="">Todos</option>
                  {bancosReceptores.map((banco, index) => (
                    <option key={index} value={banco}>{banco}</option>
                  ))}
                </select>
              </label>

              <button
                onClick={handleReset}
                className="ml-auto px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                Limpiar Filtros
              </button>
            </div>

            <div className="px-7 py-5 flex flex-wrap -mx-5 mb-2">
              <div className="ml-2 mt-2 w-full mr-2 overflow-hidden">
                <div className="shadow sm:rounded-lg border-b border-gray-200 overflow-hidden">
                  <div className="overflow-x-auto" style={{ maxWidth: '100%', overflowY: 'visible' }}>
                    <table className="min-w-full table-fixed" style={{ width: 'max-content', minWidth: '100%' }}>
                      <thead>
                        <tr>
                          <th className="sticky top-0 px-6 py-3 border-b border-gray-200 bg-gray-50 text-center text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider w-28">
                            Acciones
                          </th>
                          <th className="sticky top-0 px-6 py-3 border-b border-gray-200 bg-gray-50 text-center text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider w-40">
                            ID de Pago
                          </th>
                          <th className="sticky top-0 px-6 py-3 border-b border-gray-200 bg-gray-50 text-center text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider w-32">
                            ID Cliente
                          </th>
                          <th className="sticky top-0 px-6 py-3 border-b border-gray-200 bg-gray-50 text-center text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider w-40">
                            Número de Referencia
                          </th>
                          <th className="sticky top-0 px-6 py-3 border-b border-gray-200 bg-gray-50 text-center text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider w-36">
                            Fecha de Creación
                          </th>
                          <th className="sticky top-0 px-6 py-3 border-b border-gray-200 bg-gray-50 text-center text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider w-36">
                            Fecha de Pago
                          </th>
                          <th className="sticky top-0 px-6 py-3 border-b border-gray-200 bg-gray-50 text-center text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider w-40">
                            Fecha de Actualización
                          </th>
                          <th className="sticky top-0 px-6 py-3 border-b border-gray-200 bg-gray-50 text-center text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider w-32">
                            Tipo de Pago
                          </th>
                          <th className="sticky top-0 px-6 py-3 border-b border-gray-200 bg-gray-50 text-center text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider w-32">
                            No. de Crédito
                          </th>
                          <th className="sticky top-0 px-6 py-3 border-b border-gray-200 bg-gray-50 text-center text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider w-44">
                            Nombre de Cliente
                          </th>
                          <th className="sticky top-0 px-6 py-3 border-b border-gray-200 bg-gray-50 text-center text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider w-40">
                            Depositante
                          </th>
                          <th className="sticky top-0 px-6 py-3 border-b border-gray-200 bg-gray-50 text-center text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider w-32">
                            Banco Emisor
                          </th>
                          <th className="sticky top-0 px-6 py-3 border-b border-gray-200 bg-gray-50 text-center text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider w-32">
                            Banco Receptor
                          </th>
                          <th className="sticky top-0 px-6 py-3 border-b border-gray-200 bg-gray-50 text-center text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider w-36">
                            No. Libreta de Cobro
                          </th>
                          <th className="sticky top-0 px-6 py-3 border-b border-gray-200 bg-gray-50 text-center text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider w-32">
                            Monto Pagado
                          </th>
                          <th className="sticky top-0 px-6 py-3 border-b border-gray-200 bg-gray-50 text-center text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider w-28">
                            Estatus
                          </th>
                          <th className="sticky top-0 px-6 py-3 border-b border-gray-200 bg-gray-50 text-center text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider w-40">
                            Motivo Rechazo
                          </th>
                          <th className="sticky top-0 px-6 py-3 border-b border-gray-200 bg-gray-50 text-center text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider w-32">
                            Usuario
                          </th>
                          <th className="sticky top-0 px-6 py-3 border-b border-gray-200 bg-gray-50 text-center text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider w-28">
                            Comprobante
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {tableData.map((walletConcilia, index) => {
                          return (
                            <tr
                              key={walletConcilia.pago._id.$oid}
                              className="bg-green border-b border-gray-200"
                            >
                              <td className="px-6 py-3 text-sm leading-5 text-gray-500 text-center">
                                <button
                                  onClick={(e) => {
                                    e.preventDefault();
                                    fetchPaymentForPrinting(walletConcilia.pago._id.$oid);
                                  }}
                                  disabled={loadingReceipt}
                                  className="bg-gray-600 hover:bg-gray-700 text-white text-xs font-bold py-1 px-2 rounded"
                                >
                                  {loadingReceipt ? 'Cargando...' : 'Imprimir Recibo'}
                                </button>
                              </td>
                              <td className="px-6 py-3 text-sm leading-5 text-gray-500 text-center truncate">
                                {walletConcilia.pago._id.$oid}
                              </td>
                              <td className="px-6 py-3 text-sm leading-5 text-gray-500 text-center truncate">
                                {walletConcilia.pago.customerNumber || 'N/A'}
                              </td>
                              <td className="px-6 py-3 text-sm leading-5 text-gray-500 text-center truncate">
                                {walletConcilia.pago.referenciaTransferencia}
                              </td>
                              <td className="px-6 py-3 text-sm leading-5 text-gray-500 text-center truncate">
                                {formatDate(walletConcilia.pago.created_at)}
                              </td>
                              <td className={"px-6 py-3 text-sm leading-5 text-center truncate"}>
                                {walletConcilia.pago.fechaTransferencia}
                              </td>
                              <td className={"px-6 py-3 text-sm leading-5 text-center truncate"}>
                                {formatDate(walletConcilia.pago.updated_at)}
                              </td>
                              <td className={"px-6 py-3 text-sm leading-5 text-center truncate"}>
                                {walletConcilia.pago.tipoPago}
                              </td>
                              <td className="px-6 py-3 text-sm leading-5 text-gray-500 text-center truncate">
                                {walletConcilia.pago.creditNumber}
                              </td>
                              <td className={"px-6 py-3 text-sm leading-5 text-center truncate"}>
                                {walletConcilia.pago.nombreCliente}
                              </td>
                              <td className="px-6 py-3 text-sm leading-5 text-gray-500 text-center truncate">
                                {walletConcilia.pago.nombreDepositante}
                              </td>
                              <td className="px-6 py-3 text-sm leading-5 text-gray-500 text-center truncate">
                                {walletConcilia.pago.bancoEmisor || 'N/A'}
                              </td>
                              <td className="px-6 py-3 text-sm leading-5 text-gray-500 text-center truncate">
                                {walletConcilia.pago.bancoReceptor}
                              </td>
                              <td className="px-6 py-3 text-sm leading-5 text-gray-500 text-center truncate">
                                {walletConcilia.pago.libretaCobro}
                              </td>
                              <td className="px-6 py-3 text-sm leading-5 text-gray-500 text-center truncate">
                                {new Intl.NumberFormat("en-US", {
                                  style: "currency",
                                  currency: "USD"
                                }).format(walletConcilia.pago.amount)}
                              </td>
                              <td className="px-6 py-3 text-sm leading-5 text-center truncate">
                                <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${
                                  walletConcilia.pago.conciliado === 'true' 
                                    ? 'bg-green-100 text-green-800' 
                                    : walletConcilia.pago.rechazado === 'true' 
                                      ? 'bg-red-100 text-red-800' 
                                      : 'bg-yellow-100 text-yellow-800'
                                }`}>
                                  {walletConcilia.pago.conciliado === 'true' 
                                    ? 'Conciliado' 
                                    : walletConcilia.pago.rechazado === 'true' 
                                      ? 'Rechazado' 
                                      : 'Pendiente'}
                                </span>
                              </td>
                              <td className="px-6 py-3 text-sm leading-5 text-gray-500 text-center truncate">
                                {walletConcilia.pago.motivoRechazo || 'N/A'}
                              </td>
                              <td className="px-6 py-3 text-sm leading-5 text-gray-500 text-center truncate">
                                {walletConcilia.pago.usuario 
                                  ? walletConcilia.pago.usuario.NombreCompleto 
                                  : 'Sin Usuario'}
                              </td>
                              <td className="px-6 py-3 text-sm leading-5 text-gray-500 text-center truncate">
                                <div className="flex items-center justify-center space-x-2">
                                  {walletConcilia.pago.attachments && walletConcilia.pago.attachments.length > 0 && walletConcilia.pago.attachments.map((attachment, index) => (
                                    <div key={index}>
                                      <a 
                                        className="bg-red-500 hover:bg-red-700 text-white text-xs font-bold py-1 px-2 rounded" 
                                        href={attachment} 
                                        target="_blank"
                                        rel="noopener noreferrer"
                                      >
                                        Anexo
                                      </a>
                                    </div>
                                  ))}
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
                
                {/* Export Buttons */}
                {filteredData.length > 0 && (
                  <div className="bg-gray-100 px-4 py-3 flex items-center justify-end">
                    <span className="text-sm text-gray-700">Exportar: </span>
                    <button
                      onClick={handleExportCSV}
                      className="ml-2 inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    >
                      CSV
                    </button>
                    <button
                      onClick={handleExportExcel}
                      className="ml-2 inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    >
                      Excel
                    </button>
                  </div>
                )}

                <div className="px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6">
                  <div className="flex-1 flex sm:items-center sm:justify-between">
                    <div>
                      <p className="text-sm text-gray-700">
                        Mostrando <span className="font-medium">{currentTablePage * itemsPerPage + 1}</span> a{' '}
                        <span className="font-medium">
                          {Math.min((currentTablePage + 1) * itemsPerPage, filteredData.length)}
                        </span>{' '}
                        de <span className="font-medium">{filteredData.length}</span> resultados
                      </p>
                    </div>
                    <div>
                      <nav className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px" aria-label="Pagination">
                        <button
                          onClick={() => setCurrentTablePage(0)}
                          disabled={currentTablePage === 0}
                          className="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                        >
                          <span className="sr-only">Primera</span>
                          <span>Primera</span>
                        </button>
                        <button
                          onClick={anterior}
                          disabled={currentTablePage === 0}
                          className="relative inline-flex items-center px-2 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                        >
                          <span className="sr-only">Anterior</span>
                          <span>Anterior</span>
                        </button>
                        
                        {[...Array(Math.min(5, totalPages))].map((_, i) => {
                          let pageNum;
                          
                          if (totalPages <= 5) {
                            pageNum = i;
                          } else if (currentTablePage <= 2) {
                            pageNum = i;
                          } else if (currentTablePage >= totalPages - 3) {
                            pageNum = totalPages - 5 + i;
                          } else {
                            pageNum = currentTablePage - 2 + i;
                          }
                          
                          return (
                            <button
                              key={i}
                              onClick={() => setCurrentTablePage(pageNum)}
                              className={`relative inline-flex items-center px-4 py-2 border ${
                                currentTablePage === pageNum
                                  ? 'z-10 bg-indigo-50 border-indigo-500 text-indigo-600'
                                  : 'border-gray-300 bg-white text-gray-500 hover:bg-gray-50'
                              } text-sm font-medium`}
                            >
                              {pageNum + 1}
                            </button>
                          );
                        })}
                        
                        <button
                          onClick={siguiente}
                          disabled={currentTablePage + 1 >= totalPages}
                          className="relative inline-flex items-center px-2 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                        >
                          <span className="sr-only">Siguiente</span>
                          <span>Siguiente</span>
                        </button>
                        <button
                          onClick={() => setCurrentTablePage(totalPages - 1)}
                          disabled={currentTablePage + 1 >= totalPages}
                          className="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                        >
                          <span className="sr-only">Última</span>
                          <span>Última</span>
                        </button>
                      </nav>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      }
      
      {/* Receipt Modal */}
      {showReceiptModal && selectedPayment && (
        <PaymentReceiptModal
          paymentData={selectedPayment}
          onClose={() => setShowReceiptModal(false)}
        />
      )}
    </>
  );
};

export default WalletPagos;
